<template>
  <div class="exam-main">
    <div class="task-header" id="task-header1" >
<!--      <task-info-->
<!--        :projectInfo = "projectInfo"-->
<!--        :batchInfo = "batchInfo"-->
<!--        :stepInfo = "stepInfo"-->
<!--        :taskInfo = "taskInfo"-->
<!--        :dataId="curId" />-->
<!--      <div class="task-btn-group">-->
<!--        <task-progress-->
<!--            :total="taskList.length"-->
<!--            :current="curDataIndex"-->
<!--            :timeout="taskTimeout" />-->
<!--        <Button type="primary" size="small" @click.native="temporaryStorage">{{sec}}s后暂存</Button>-->
<!--        <Tooltip max-width="170" content="暂存：每分钟自动存储所有作业结果，可手动暂存">-->
<!--          <Icon type="ios-help-circle-outline" :size="16" />-->
<!--        </Tooltip>-->
<!--        <Button type="warning" size="small" @click.native="exit">{{$t('tool_quit')}}</Button>-->
<!--        <Button type="primary" size="small" @click.native="submit" :loading="loading">{{$t('tool_submit')}}-->
<!--        </Button>-->
<!--      </div>-->
      <div class="task-header-l">
        <div class="task-header-l-tit">{{projectInfo.name}}(ID: {{ projectInfo.id }})</div>
        <div class="task-header-l-info">
          <p>批次ID: {{batchInfo.id}}</p>
          <p>批次名称: {{batchname}}</p>
          <p>执行任务ID: {{ taskInfo.id }}</p>
          <p>作业ID: {{ curId }}</p>
        </div>
        <div class="task-header-l-tip">
          <div>
            <p class="task-header-l-tip-tit">注意事项：</p>
            <Richtxt :isInline="true" :isDisabled="true" :dataObj="{val:projectInfo.attr['description']}"></Richtxt>
          </div>
          <div>
            <p class="task-header-l-tip-tit">任务附件：</p>

            <Button type="primary" v-for="item in projectInfo.attr['attachments']" :key="item.url" @click="checkEnclosure(item.url)">下载</Button>
          </div>
        </div>
      </div>
      <div class="task-header-r">
        <div  class="mb20">
          <Button type="warning" @click.native="exit" class="mr">退回至待完成公共池</Button>
          <Button type="primary" @click.native="submit" :loading="loading">{{$t('tool_submit')}}</Button>
        </div>

        <div>
          <div style="font-size: 16px;font-weight: bold">
            <task-progress
                :total="taskList.length"
                :current="curDataIndex"
                :timeout="taskTimeout" />
          </div>
          <div>每分钟自动存储所有作业结果，可手动<p class="task-header-r-storage" @click="temporaryStorage">暂存</p></div>
        </div>
      </div>
    </div>
    <div class="cont">
<!--      <p class="cont-tit">{{ $t('task_overview') }}</p>-->
      <div class="cont-box" :style="{position:isFixed? 'fixed' : 'absolute', top:isFixed ? '48px' : '0'}">
        <div class="cont-num">
          <div :class="{active : curDataIndex == index,suspend: allData[item.data.id].suspend == 1,invalid: allData[item.data.id].invalid == 1}" v-for="(item,index) in taskList" :key="item.data.id" @click="changeData(index)">第<span>{{index + 1}}</span>题</div>
        </div>
<!--        <div>-->
<!--          <Button style="margin-right: 10px" type="primary" @click="singleOp(1)" :loading="suspendLoading">挂起</Button>-->
<!--          <Button type="error" @click="singleOp(2)" :loading="invalidLoading">无效作业</Button>-->
<!--        </div>-->
      </div>

<!--      <div class="exam-num">-->
<!--        {{ $t('task_section')}} <span>{{curDataIndex + 1}}</span> {{$t('task_topic')}}-->
<!--      </div>-->
      <div class="cont-r">
        <div class="exam-des">
          <div class="exam-des-top">
            <div>
              <span>{{$t('task_work_tasks')}}:</span>
              <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['工作任务'] }}</span>
            </div>
            <div>
              <span>{{$t('task_skill_set')}}:</span>
              <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['技能集'] }}</span>
            </div>
            <div>
              <span>{{ $t('task_first_level') }}:</span>
              <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['技能点（一级知识点）'] }}</span>
            </div>
            <div>
              <span>{{ $t('task_question_type') }}:</span>
              <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['题型'] }}</span>
            </div>
            <div>
              <span>{{$t('task_difficulty_level')}}:</span>
              <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['难度等级'] }}</span>
            </div>
          </div>
          <div class="exam-des-bot">
            <div>
              <span>{{ $t('task_knowledge_points') }}:</span>
              <div>
                <el-select
                    v-model="allData[curId].nodes"
                    filterable
                    remote
                    :remote-method="(query) => remoteMethod(query,'node')"
                    :loading="knowledgePointLoading"
                    style="width:300px;"
                    @change="changeNodes"
                >
                  <el-option
                      v-for="item in knowledgePointList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div>
              <span>标签:</span>
              <div>
                <el-select
                    v-model="allData[curId].labels"
                    multiple
                    filterable
                    remote
                    allow-create
                    default-first-option
                    :remote-method="(query) => remoteMethod(query,'label')"
                    :loading="labelLoading"
                    style="width:300px;"
                    @change="changeLabel"
                >
                  <el-option
                      v-for="item in labelList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div>
              <span>分类:</span>
              <div>
                <el-select
                    v-model="allData[curId].categories"
                    multiple
                    filterable
                    remote
                    :remote-method="(query) => remoteMethod(query,'category')"
                    :loading="categoryLoading"
                    style="width:300px;"
                    @change="changeCategory"
                >
                  <el-option
                      v-for="item in categoryList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div class="exam-cont">
          <div class="item">
            <div class="item-l">
              <p>*{{ $t('task_test_title') }}</p>
            </div>
            <div class="item-r">
              <div class="item-r-input">
                <div class="item-r-input-cont">
                  <div class="left">
                    <Richtxt v-if="!richTxtLoading" :dataObj="allData[curId].title" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].title)"></Richtxt>
                  </div>
<!--                  <div class="right" v-if="allData[curId].title.comment">-->
                  <div class="comment-question" v-if="allData[curId].title.comment && allData[curId].title.comment.length">
                    <Input v-model="allData[curId].title.newComment" border type="textarea" placeholder="请在此输入你的看法..." />
                  </div>
                  <div class="previous-comment" v-if="allData[curId].title.comment && allData[curId].title.comment.length">
                    <div class="previous-comment-tit">以往评论：</div>
                    <div class="previous-comment-cont">
                      <Timeline>
                        <TimelineItem v-for="item in allData[curId].title.comment" :key="item.time">
                          <div class="info">
                            <p>{{ item.name }}</p>
                            <p>{{ item.time }}</p>
                            <Tag color="primary">{{ item.batch }}</Tag>
                          </div>
                          <p class="content color-b">{{ item.comment }}</p>
                        </TimelineItem>
                      </Timeline>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>
          <div class="item" v-for="(item,index) in allData[curId].optionList" :key="index + 'option'">
            <div class="item-l">
              <p>*{{ item.name }}</p>
<!--              <p :class="item.isCorrect ? 'correct' : ''" @click="changeCorrect(item)">{{item.isCorrect ? $t('task_correct_option') : $t('task_set_correct_option')}}</p>-->
<!--              <div>-->
<!--                <Button size="small" type="error" style="margin-top: 10px" v-if="index > 3" @click="deleOption(index)">-->
<!--                  {{ $t('task_delete') }}</Button>-->
<!--              </div>-->
            </div>
            <div class="item-r">
              <div class="item-r-input">
                <div class="item-r-input-cont">
                  <div class="left">
                    <div class="left-top">
                      <div class="data-correct" :class="item.isCorrect ? 'active' : ''" @click="changeCorrect(item)">{{item.isCorrect ? $t('task_correct_option') : $t('task_set_correct_option')}}</div>
                      <div class="data-dele" v-if="index > 3" @click="deleOption(index)">
                        <img src="../../../assets/images/new_icon/delete_icon.png" width="24" height="24">
                      </div>
                    </div>
                    <Richtxt v-if="!richTxtLoading" :dataObj="item" :curId="curId" @saveData="(data)=>saveRichData(data,item)"></Richtxt>
                  </div>

                  <div class="comment-question mt44" v-if="item.comment && item.comment.length">
                    <Input v-model="item.newComment" border type="textarea" placeholder="请在此输入你的看法..." />
                  </div>
                  <div class="previous-comment mt44" v-if="item.comment && item.comment.length">
                    <div class="previous-comment-tit">以往评论：</div>
                    <div class="previous-comment-cont">
                      <Timeline>
                        <TimelineItem v-for="sItem in item.comment" :key="sItem.time">
                          <div class="info">
                            <p>{{ sItem.name }}</p>
                            <p>{{ sItem.time }}</p>
                            <Tag color="primary">{{ sItem.batch }}</Tag>
                          </div>
                          <p class="content color-b">{{ sItem.comment }}</p>
                        </TimelineItem>
                      </Timeline>
                    </div>
                  </div>
                </div>

              </div>
              <div class="item-r-comment">
              </div>
            </div>
          </div>
          <Button type="primary" class="add-btn" @click="addOption" v-if="questionType.indexOf('判断') == -1">{{ $t('task_add_options') }}</Button>
          <div class="item">
            <div class="item-l">
              <p>{{ $t('task_analysis') }}</p>
            </div>
            <div class="item-r">
              <div class="item-r-input">
                <div class="item-r-input-cont">
                  <div class="left">
                    <Richtxt v-if="!richTxtLoading" :dataObj="allData[curId].analysis" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].analysis)"></Richtxt>
                  </div>
                  <div class="comment-question" v-if="allData[curId].analysis.comment && allData[curId].analysis.comment.length">
                    <Input v-model="allData[curId].analysis.newComment" border type="textarea" placeholder="请在此输入你的看法..." />
                  </div>
                  <div class="previous-comment" v-if="allData[curId].analysis.comment && allData[curId].analysis.comment.length">
                    <div class="previous-comment-tit">以往评论：</div>
                    <div class="previous-comment-cont">
                      <Timeline>
                        <TimelineItem v-for="item in allData[curId].analysis.comment" :key="item.time">
                          <div class="info">
                            <p>{{ item.name }}</p>
                            <p>{{ item.time }}</p>
                            <Tag color="primary">{{ item.batch }}</Tag>
                          </div>
                          <p class="content color-b">{{ item.comment }}</p>
                        </TimelineItem>
                      </Timeline>
                    </div>
                  </div>

                </div>

                <div class="btn">
                  <!--               <Button size="small">预览</Button>-->
                </div>
              </div>
              <div class="item-r-comment">
              </div>
            </div>
          </div>
        </div>
        <div class="bot">
          <Button type="primary" class="bot-btn" @click="pre">{{ $t('task_pre_question') }}</Button>
          <Button type="primary" @click="next">{{ $t('task_next_question') }}</Button>
        </div>
      </div>

    </div>
    <Modal
        v-model="timeoutModal"
        :title="$t('task_task_timed_out')"
        :mask-closable="false"
        :closable="false"
    >
      <div class="timeout-text">{{ $t('task_re_claim') }}</div>
      <div slot="footer">
        <Button type="primary" @click="again">{{ $t('task_get_task_again') }}</Button>

        <Button type="primary" @click="backTask">{{ $t('task_return_to_task') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="verificationModal"
        title="数据未填完整"
        @on-ok="ok"
        @on-cancel="cancel"
    >
      <p v-for="item in incompleteData" :key="item">第 <strong style="">{{item + 1}}</strong> 题数据未填完整</p>
    </Modal>
  </div>
</template>

<script>
import Richtxt from '../richtxt.vue';
import TaskProgress from '../progress.vue';
import TaskInfo from '../taskinfo.vue';

import EventBus from '@/utils/eventBus';
import util from "@/utils/tools";


export default {
  name: "produce",
  props: {
    templateInfo: {
      type: Array,
      default: [],
    },
    taskList: {
      type: Array,
      required: true,
    },
    categoryInfo: {
      type: Object,
      required: true,
    },
    serverTime: {
      type: Number,
      required: true,
    },
    taskInfo: {
      type: Object,
      required: true,
    },
    taskTimeout: {
      type: Number,
      required: true,
    },
    taskStat: {
      type: Object,
      required: true,
    },
    stepInfo: {
      type: Object,
      required: true,
    },
    batchInfo: {
      type: Object,
      required: true,
    },
    projectInfo: {
      type: Object,
      required: true,
    },
  },
  data(){
    return{
      knowledgePointValue:[],
      isShow:false, //默认收起tab项
      projectTabList:[
        {
          id:1,
          name:this.$t('task_attention'),
          key:'description'
        },
        {
          id:2,
          name:this.$t('task_attachment'),
          key:'attachments'
        },
        // {
        //   id:3,
        //   name:this.$t('task_feedback'),
        // },
      ],
      curProjectTab:{
        id:1,
        name:this.$t('task_attention'),
        key:'description'
      },
      curDataIndex:0,// 当前数据
      cont:'',
      nameList:[this.$t('task_option_e'),this.$t('task_option_f')],
      // taskTimeout: 600,
      loading: false,
      taskItemInfo: '',
      taskItemInfoMore: {},
      loadingText: this.$t('tool_loading'),
      userStat: {},
      textPlaceHolderReady: false,
      // workedTaskId: {},
      knowledgePoint:[],  //选中的知识点
      knowledgePointList:[],  //知识点列表
      knowledgePointLoading:false,
      questionType:'',
      allData:{},
      curId:'', //当前数据id
      exitLoading:false,
      timeoutModal:false,
      sec:60,
      timer:null,
      storageLoading:false,
      verificationModal:false,  //校验数据弹窗
      incompleteData:[],
      categoryLoading:false,
      categoryList:[],
      labelLoading:false,
      labelList:[],
      suspendLoading:false,
      invalidLoading:false,
      richTxtLoading:false,
      catchData:{},
      batchname:'',
      isFixed:false,
    }
  },
  components:{
    Richtxt,
    'task-progress': TaskProgress,
    'task-info': TaskInfo,
  },
  computed: {
    dataId () {
      return this.taskList[this.curDataIndex].data.id;
    },

  },
  watch:{
    taskList:{
      handler(newV){
        this.init();
      },
      immediate:true
    },
    batchInfo:{
      handler(newV){
        this.batchname = util.base64decode(newV.name);
      },
      immediate:true
    }
  },
  mounted(){
    this.$emit('componentsLoaded');
    this.countDown();

    document.getElementById('cont').addEventListener('scroll',this.getIsFixed)

  },
  beforeDestroy(){
    document.getElementById('cont').removeEventListener('scroll',this.getIsFixed)
  },
  methods:{
    getIsFixed(){
      let scrollTop = document.getElementById('cont').scrollTop;
      let divH = document.getElementById('task-header1').offsetHeight + 80;

      this.isFixed = scrollTop - divH > 0;
    },
    countDown(){  //倒计时
      this.sec = 60;
      this.timer = setInterval(()=>{
        this.sec--;
        if(this.sec == 0){
          this.temporaryStorage();
        }
      },1000)
    },
    temporaryStorage(){ //暂存
      clearInterval(this.timer)
      let result = {};
      let status = false; //判断是否有没填的数据

      for(let name in this.allData){
        let options = this.allData[name].optionList.map((item)=>{
          return {
            "id":item.id,
            "name":item.val,
            "is_correct":item.isCorrect
          }
        })

        let feedback = {};
        this.allData[name].optionList.forEach((item)=>{
          if(item.newComment){
            let commentData = item.comment && item.comment.length ? JSON.parse(JSON.stringify(item.comment)) : [];

            commentData.unshift({
              name:this.$store.state.user.userInfo.nickname,
              time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
              batch:this.stepInfo.name,
              comment:item.newComment,
            })
            feedback[item.id] = JSON.stringify(commentData);
          }else{
            if(item.comment && item.comment.length){
              feedback[item.id] = JSON.stringify(item.comment);
            }
          }
        });
        if(this.allData[name].title.newComment){
          let commentData = this.allData[name].title.comment && this.allData[name].title.comment.length ? JSON.parse(JSON.stringify(this.allData[name].title.comment)) : [];
          commentData.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[name].title.newComment,
          })
          feedback.name = JSON.stringify(commentData);
        }else{
          if(this.allData[name].title.comment && this.allData[name].title.comment.length){
            feedback.name = JSON.stringify(this.allData[name].title.comment);
          }
        }
        if(this.allData[name].analysis.newComment){

          let commentData = this.allData[name].analysis.comment && this.allData[name].analysis.comment.length ? JSON.parse(JSON.stringify(this.allData[name].analysis.comment)) : [];
          commentData.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[name].analysis.newComment,
          })
          feedback.explain = JSON.stringify(commentData);
        }else{
          if(this.allData[name].analysis.comment && this.allData[name].analysis.comment.length){
            feedback.explain = JSON.stringify(this.allData[name].analysis.comment);
          }
        }

        if(!this.allData[name].suspend && !this.allData[name].invalid){
          result[name] = {
            info:{
              "type":"question",
              "id":this.$uuid.v1(),
              "name" : this.allData[name].title.val,
              "options" : options,
              "explain":this.allData[name].analysis.val,
              "nodes":this.allData[name].nodes,
              "categories":this.allData[name].categories,
              "labels":this.allData[name].labels,
            },
            "feedback":feedback,
          }
        }
      }
      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'temporary_storage',
      };

      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      this.storageLoading = true;
      this.api.task.taskExecute(reqData).then((res)=>{
        this.storageLoading = false;
        this.$Message.destroy();
        this.$Message.success({
          content: '暂存成功',
          duration: 1,
        });

        this.sec = 60;
        this.countDown();

      }).catch((err)=>{
        this.storageLoading = false;
      })

    },
    init(){
      this.curDataIndex = 0;
      this.userStat = this.taskStat;
      // this.workedTaskId = {};

      //超时执行的操作
      EventBus.$on('task-timeout', this.setTaskTimeout);
      //判断类型  单选多选 默认四个选项 判断题 默认两个选项  不可添加选项
      this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];
      this.curId = this.taskList[this.curDataIndex].data.id;

      //格式化数据
      this.formate();
    },
    setTaskTimeout () {
      this.timeoutModal = true;
    },
    formate(){
      this.allData = {};
      this.taskList.forEach((item)=>{
        let result = item.dataResult.result;
        let name = item.data.id;
        let options = [];
        let names = [this.$t('task_option_a'),this.$t('task_option_b'),this.$t('task_option_c'),this.$t('task_option_d'),this.$t('task_option_e'),this.$t('task_option_f')];
        console.log(this.questionType,'===========')
        if(result){
          console.log('12121212')
          if(result.info.options.length){
            console.log('22222')
            result.info.options.forEach((sItem,index)=>{
              options.push({
                id:sItem.id,
                name:names[index],
                isCorrect:sItem.is_correct,
                val:sItem.name,
                comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback[sItem.id] : []) : [],
                isCheck:false,
                isRich:false,
                placeholder:this.$t('task_option_placeholder'),
                newComment:''
              })
            })
          }else{
            let len = 0;
            console.log(this.questionType,'===========')
            if(this.questionType.indexOf('判断') != -1){
              len = 2;
            }else{
              len = 4;
            }
            for(let i = 0;i<len;i++){
              options.push({
                id:this.$uuid.v1(),
                name:names[i],
                isCorrect:false,
                val:'',
                comment:'',
                isCheck:false,
                isRich:false,
                placeholder:this.$t('task_option_placeholder'),
                newComment:''
              })
            }
          }
          console.log(options,'optionsoptionsoptionsoptionsoptions')


          let title = {
            val:result.info.name,
            comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback.name : []) : [],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_title_placeholder'),
            newComment:''
          };
          let analysis = {
            val:result.info.explain,
            comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback.explain : []) : [],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder'),
            newComment:''
          };
          this.$set(this.allData,name,{});

          this.$set(this.allData[name],'title',title);
          this.$set(this.allData[name],'analysis',analysis);
          this.$set(this.allData[name],'optionList',options);
          this.$set(this.allData[name],'id',result.info.id);
          this.$set(this.allData[name],'type',result.info.type);
          this.$set(this.allData[name],'verify',-1);  //-1 没状态 0 驳回 1通过 2重置

          if (result.info && result.info.nodes){
            this.$set(this.allData[name],'nodes',result.info.nodes);
          }

          if (result.info && result.info.categories){
            this.$set(this.allData[name],'categories',result.info.categories);
          }

          if (result.info && result.info.labels){
            this.$set(this.allData[name],'labels',result.info.labels);
          }
        }else{
          let len = 0;
          if(this.questionType.indexOf('判断') != -1){
            len = 2;
          }else{
            len = 4;
          }
          for(let i = 0;i<len;i++){
            options.push({
              id:this.$uuid.v1(),
              name:names[i],
              isCorrect:false,
              val:'',
              comment:[],
              isCheck:false,
              isRich:false,
              placeholder:this.$t('task_option_placeholder'),
              newComment:''
            })
          }

          let title = {
            val:'',
            comment:[],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_title_placeholder'),
            newComment:''
          };
          let analysis = {
            val:'',
            comment:[],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder'),
            newComment:''
          };
          console.log(options,'optionsoptions')
          this.$set(this.allData,name,{});
          this.$set(this.allData[name],'nodes',[]);
          this.$set(this.allData[name],'categories',[]);
          this.$set(this.allData[name],'labels',[]);
          this.$set(this.allData[name],'title',title);
          this.$set(this.allData[name],'analysis',analysis);
          this.$set(this.allData[name],'optionList',options);
        }
        if(item.work){
          this.$set(this.allData[name],'is_refused',item.work.is_refused);  //1 被驳回的
        }
      })

      this.catchData = JSON.parse(JSON.stringify(this.allData));
      if(this.taskList[0].dataResult.result.info){
        let nodeIds = this.taskList[0].dataResult.result.info.nodes;
        this.getKnowledgePoint(nodeIds,'ids');

        let categoryIds = this.taskList[0].dataResult.result.info.categories;
        console.log(categoryIds,'categoryIds')
        this.getCategory(categoryIds,'ids');

        let labels = this.taskList[0].dataResult.result.info.labels;
        this.getLabel(labels,'ids');
      }

    },
    addOption(){  //添加选项
      if(this.allData[this.curId].optionList.length < 6){
        this.allData[this.curId].optionList.push({
          id:this.$uuid.v1(),
          name:this.nameList[this.allData[this.curId].optionList.length - 4],
          isCorrect:false,
          val:'',
          placeholder:''
        })
      }else{
        this.$Message.warning(this.$t('task_add_max'));
      }
    },
    deleOption(index){ //删除选项
      this.allData[this.curId].optionList.splice(index,1);
    },
    saveTaskResult (next = true, isDifficult = 0) { //isDifficult 0 提交  1挂起
      // if (this.workedTaskId[this.dataId]) {
      //   return;
      // }
      if (this.loading) {
        return;
      }

      let result = {};
      let status = false; //判断是否有没填的数据
      this.incompleteData = [];

      for(let name in this.allData){
        let len = this.allData[name].optionList.filter((item)=>{
          return !item.val;
        }).length;
        let correctStatus = this.allData[name].optionList.filter((item)=>{
          return item.isCorrect;
        }).length;
        if(!this.allData[name].suspend && !this.allData[name].invalid){
          if(len > 0 || !this.allData[name].title.val || !correctStatus ){
            status = true;
            let i = Object.keys(this.allData).indexOf(name);
            this.incompleteData.push(i);
          }
        }

      }
      if(this.incompleteData.length){
        this.verificationModal = true;
        return;
      }
      for(let name in this.allData){
        let len = this.allData[name].optionList.filter((item)=>{
          return !item.val;
        }).length;
        let correctStatus = this.allData[name].optionList.filter((item)=>{
          return item.isCorrect;
        }).length;
        if(!this.allData[name].suspend && !this.allData[name].invalid){
          if(len > 0 || !this.allData[name].title.val || !correctStatus){
            status = true;
            break;
          }
        }


        //驳回数据如果未更新内容不可以提交
        if(this.allData[name].is_refused == '1'){ //驳回数据
          let isModify = false;
          console.log('111111111',this.allData[name],this.catchData[name])
          if(this.allData[name].title.val != this.catchData[name].title.val || this.allData[name].analysis.val != this.catchData[name].analysis.val ||this.allData[name].nodes != this.catchData[name].nodes || this.allData[name].categories.join(',') != this.catchData[name].categories.join(',') || this.allData[name].labels.join(',') != this.catchData[name].labels.join(',')){
            isModify = true;
          }
          if(this.allData[name].optionList.length != this.catchData[name].optionList.length){
            isModify = true;
          }else{
            this.allData[name].optionList.forEach((item,index)=>{
              if(item.val != this.catchData[name].optionList[index].val || item.isCorrect !=this.catchData[name].optionList[index].isCorrect){
                isModify = true;
              }
            })
          }
          if(!isModify){
            this.$Message.warning('请修改后提交～');
            return;
          }
        }

        let feedback = {};
        this.allData[name].optionList.forEach((item)=>{
          if(item.newComment){
            let commentData = item.comment && item.comment.length ? JSON.parse(JSON.stringify(item.comment)) : [];

            commentData.unshift({
              name:this.$store.state.user.userInfo.nickname,
              time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
              batch:this.stepInfo.name,
              comment:item.newComment,
            })
            feedback[item.id] = JSON.stringify(commentData);
          }else{
            if(item.comment && item.comment.length){
              feedback[item.id] = JSON.stringify(item.comment);
            }
          }
        });
        if(this.allData[name].title.newComment){

          let commentData = this.allData[name].title.comment && this.allData[name].title.comment.length ? JSON.parse(JSON.stringify(this.allData[name].title.comment)) : [];
          commentData.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[name].title.newComment,
          })
          feedback.name = JSON.stringify(commentData);
        }else{
          if(this.allData[name].title.comment && this.allData[name].title.comment.length){
            feedback.name = JSON.stringify(this.allData[name].title.comment);
          }
        }
        if(this.allData[name].analysis.newComment){

          let commentData = this.allData[name].analysis.comment && this.allData[name].analysis.comment.length ? JSON.parse(JSON.stringify(this.allData[name].analysis.comment)) : [];
          commentData.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[name].analysis.newComment,
          })
          feedback.explain = JSON.stringify(commentData);
        }else{
          if(this.allData[name].analysis.comment && this.allData[name].analysis.comment.length){
            feedback.explain = JSON.stringify(this.allData[name].analysis.comment);
          }
        }



        let options = this.allData[name].optionList.map((item)=>{
          return {
            "id":item.id,
            "name":item.val,
            "is_correct":item.isCorrect
          }
        })
        if(!this.allData[name].suspend && !this.allData[name].invalid){
          result[name] = {
            info:{
              "type":"question",
              "id":this.$uuid.v1(),
              "name" : this.allData[name].title.val,
              "options" : options,
              "explain":this.allData[name].analysis.val,
              "nodes":this.allData[name].nodes,
              "categories":this.allData[name].categories,
              "labels":this.allData[name].labels,
            },
            "feedback":feedback,
          }
        }
      }
      // if(status) {
      //   this.$Message.warning(this.$t('task_complete_data'));
      //   return;
      // }
      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'submit',
      };
      if (!reqData.project_id || !reqData.task_id ) {
        return;
      }
      this.loading = true;
      // this.workedTaskId[this.dataId] = true;
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      this.api.task.taskExecute(reqData).then((res)=>{
        this.loading = false;
        this.$Message.destroy();
        this.$Message.success({
          content: this.$t('tool_submit_success'),
          duration: 1,
        });

        //提交后重新领取任务 如果有任务继续做  没有返回上一级
        this.$emit('againGetTask')
      }).catch((err)=>{
        this.loading = false;
      })
    },
    submit () {
      this.saveTaskResult(true, 0);
    },
    exit () {
      this.$Modal.confirm({
        title: this.$t('tool_exit_confirmation'),
        content: this.$t('tool_exit_description', {num: this.taskList.length - this.curDataIndex}),
        loading: true,
        onOk: () => {
          this.clearTask();
        }
      });
    },
    clearTask () {
      EventBus.$emit('needConfirmLeave', false);

      let params = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        data_id:this.$route.query.data_id,
        op: 'clear',
      }
      this.exitLoading = true;
      this.api.task.taskExecute(params).then((res)=>{
        this.exitLoading = false;
        this.$Modal.remove();
        this.$store.commit('removeTag', 'perform-task');


        this.$router.go(-1);
      }).catch((err)=>{
        this.exitLoading = false;
      })
    },
    changeProjectTab(data){
      this.curProjectTab = data;
    },
    changeData(index){
      if(this.allData[this.taskList[index].data.id].suspend == 1 || this.allData[this.taskList[index].data.id].invalid == 1){
        this.$Message.warning(this.$t('task_disabled_operated'));
        return;
      }
      this.richTxtLoading = true;
      this.$nextTick(()=>{
        this.richTxtLoading = false;
      })
      this.curDataIndex = index;
      this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];
      this.curId = this.taskList[this.curDataIndex].data.id;

      let nodeIds = this.allData[this.curId].nodes;
      this.getKnowledgePoint(nodeIds,'ids');

      let categoryIds = this.allData[this.curId].categories;
      this.getCategory(categoryIds,'ids');

      let labels = this.allData[this.curId].labels;
      this.getLabel(labels,'ids');
      //暂存
      this.temporaryStorage();
    },
    next(type){
      if(this.curDataIndex == this.taskList.length -1){
        this.$Message.warning(this.$t('task_last_data'));
      }else{
        if(this.allData[this.taskList[this.curDataIndex+1].data.id].suspend == 1 || this.allData[this.taskList[this.curDataIndex+1].data.id].invalid == 1){

          //找一下后面有没有可以跳转的
          let arr = [];
          for(let i=this.curDataIndex+1;i<this.taskList.length;i++){
            if(!this.allData[this.taskList[i].data.id].suspend || !this.allData[this.taskList[i].data.id].invalid){
              arr.push(this.allData[i]);
            }
          }
          if(arr.length){
            this.curDataIndex += 1;
            this.curId = this.taskList[this.curDataIndex].data.id;
            this.next();
          }else{
            this.$Message.warning(this.$t('task_disabled_operated'));
          }


          return;
        }
        this.richTxtLoading = true;
        this.$nextTick(()=>{
          this.richTxtLoading = false;
        })
        this.curDataIndex += 1;
        this.curId = this.taskList[this.curDataIndex].data.id;
        this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];

        let nodeIds = this.allData[this.curId].nodes;
        this.getKnowledgePoint(nodeIds,'ids');

        let categoryIds = this.allData[this.curId].categories;
        this.getCategory(categoryIds,'ids');

        let labels = this.allData[this.curId].labels;
        this.getLabel(labels,'ids');
        if(!type){
          //暂存
          this.temporaryStorage();
        }
      }
    },
    pre(){
      if(this.curDataIndex == 0){
        this.$Message.warning(this.$t('task_first_data'));
      }else{
        if(this.allData[this.taskList[this.curDataIndex-1].data.id].suspend == 1 || this.allData[this.taskList[this.curDataIndex-1].data.id].invalid == 1){

          console.log('2323232323232')
          //找一下前面有没有可以跳转的
          let arr = [];
          for(let i=0;i<this.curDataIndex-1;i++){
            if(!this.allData[this.taskList[i].data.id].suspend && !this.allData[this.taskList[i].data.id].invalid){
              arr.push(this.allData[this.taskList[i].data.id]);
            }
          }
          if(arr.length){
            this.curDataIndex -= 1;
            this.curId = this.taskList[this.curDataIndex].data.id;
            this.pre();
          }else{
            this.$Message.warning(this.$t('task_disabled_operated'));
          }

          return;
        }

        this.richTxtLoading = true;
        this.$nextTick(()=>{
          this.richTxtLoading = false;
        })
        this.curDataIndex -= 1;
        this.curId = this.taskList[this.curDataIndex].data.id;
        this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];

        let nodeIds = this.allData[this.curId].nodes;
        this.getKnowledgePoint(nodeIds,'ids');

        let categoryIds = this.allData[this.curId].categories;
        this.getCategory(categoryIds,'ids');

        let labels = this.allData[this.curId].labels;
        this.getLabel(labels,'ids');
        //暂存
        this.temporaryStorage();
      }
    },
    remoteMethod(query,type){ //模糊搜索
      if(type == 'node'){
        if (query !== '') {
          this.getKnowledgePoint(query);
        } else {
          this.knowledgePointList = [];
        }
      }else if(type == 'category'){
        if (query !== '') {
          this.getCategory(query);
        } else {
          this.categorytList = [];
        }
      }else if(type == 'label'){
        if (query !== '') {
          this.getLabel(query);
        } else {
          this.labelList = [];
        }
      }

    },
    getKnowledgePoint(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type){
        pramas[type] = data;
      }else{
        pramas.keyword = data;
      }

      this.knowledgePointLoading = true;
      this.api.dataset.datasetNodeList(pramas).then((res)=>{
        this.knowledgePointLoading = false;

        this.knowledgePointList = res.list;
      }).catch((err)=>{
        this.knowledgePointLoading = false;
      })
    },
    getCategory(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.categoryLoading = true;
      this.api.dataset.categoryList(pramas).then((res)=>{
        this.categoryLoading = false;

        this.categoryList = res.list;
      }).catch((err)=>{
        this.categoryLoading = false;
      })
    },
    getLabel(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.labelLoading = true;
      this.api.dataset.labelList(pramas).then((res)=>{
        this.labelLoading = false;

        this.labelList = res.list;
      }).catch((err)=>{
        this.labelLoading = false;
      })
    },
    showTitleRich(){
      this.closeRich();
      this.title.isRich = true;
    },
    showAnalysisRich(){
      this.closeRich();
      this.analysis.isRich = true;
    },
    changeCorrect(data){
      if(!data.isCorrect){
        let correctLen = this.allData[this.curId].optionList.filter((item)=>{
          return item.isCorrect;
        }).length;
        //如果是单选 或者 判断题 只能有一个正确选项  多选不能全选
        if(this.questionType.indexOf('单选') != -1 || this.questionType.indexOf('判断') != -1){
          if(correctLen >= 1){
            this.$Message.warning(this.$t('task_selected_one'));
            return;
          }
        }else{  //多选
          if(correctLen >= this.allData[this.curId].optionList.length - 1){
            this.$Message.warning(this.$t('task_disabled_all'));
            return;
          }
        }
      }
      data.isCorrect = !data.isCorrect;
    },
    saveRichData(curData,data){ //同步富文本数据
      data.val = curData;
    },
    changeNodes(data){
      this.allData[this.curId].nodes = data;
    },
    again(){
      this.$emit('againGetTask')
    },
    backTask(){
      this.$router.go(-1)
    },
    ok(){
      this.curDataIndex = this.incompleteData[0];
      this.curId = this.taskList[this.curDataIndex].data.id;
      this.incompleteData = [];
      this.verificationModal = false;
    },
    cancel(){
      this.incompleteData = [];
      this.verificationModal = false;
    },
    changeCategory(data){ //修改分类
      this.allData[this.curId].categories = data;
    },
    // changeLabel(data){ //修改标签
    //   this.allData[this.curId].labels = data;
    // },
    changeLabel(data){ //修改标签
      if(data.length){
        if(!this.labelList.length){
          //添加
          this.confirmAddLabel(data[data.length - 1]);
        }else{
          //判断是否有id
          let len = this.labelList.filter((item)=>{
            return item.id == data[data.length - 1];
          })
          if(!len.length){ //添加
            this.confirmAddLabel(data[data.length - 1]);
          }else{
            this.allData[this.curId].labels = data;
          }
        }
      }else{
        this.allData[this.curId].labels = data;
      }
      this.allData[this.curId].labels = data;
    },
    confirmAddLabel(name){  //确定
      let params = {
        name:name
      };
      this.addLabelLoading = true;
      this.api.dataset.labelCreate(params).then((res)=>{
        this.addLabelLoading = false;

        let len = this.allData[this.curId].labels.filter((item)=>{
          return item == res.id;
        });
        let labels;
        if(!len.length){
          labels = this.allData[this.curId].labels.concat(res.id + '');
        }
        this.getLabel(labels,'ids',()=>{
          this.allData[this.curId].labels.splice(this.allData[this.curId].labels.length - 1, 1, res.id + '');
        });

        // this.getLabel(this.allData[this.curId].labels,'ids');

        this.addLabelModal = false;
      }).catch((err)=>{
        this.addLabelLoading = false;
      })
    },
    singleOp(type){  //1挂起 2无效
      if(type == 1){
        if (this.suspendLoading) {
          return;
        }
      }else{
        if (this.invalidLoading) {
          return;
        }
      }
      let result = {};
      // let status = false; //判断是否有没填的数据
      this.incompleteData = [];

      // let len = this.allData[this.curId].optionList.filter((item)=>{
      //   return !item.val;
      // }).length;
      // let correctStatus = this.allData[this.curId].optionList.filter((item)=>{
      //   return item.isCorrect;
      // }).length;
      // if(len > 0 || !this.allData[this.curId].title.val || !this.allData[this.curId].analysis.val || !correctStatus || !this.allData[this.curId].nodes.length || !this.allData[this.curId].categories.length){
      //   status = true;
      //   let i = Object.keys(this.allData).indexOf(this.curId);
      //   this.incompleteData.push(i);
      // }
      //
      // if(this.incompleteData.length){
      //   this.verificationModal = true;
      //   return;
      // }

      let options = this.allData[this.curId].optionList.map((item)=>{
        return {
          "id":item.id,
          "name":item.val,
          "is_correct":item.isCorrect
        }
      })
      result[this.curId] = {
        info:{
          "type":"question",
          "id":this.$uuid.v1(),
          "name" : this.allData[this.curId].title.val,
          "options" : options,
          "explain":this.allData[this.curId].analysis.val,
          "nodes":this.allData[this.curId].nodes,
          "categories":this.allData[this.curId].categories,
          "labels":this.allData[this.curId].labels,
        }
      }
      if(type == 1){
        result[this.curId].is_difficult = 1;
      }else{
        result[this.curId].is_invalid = 1;
      }
      // if(status) {
      //   this.$Message.warning(this.$t('task_complete_data'));
      //   return;
      // }
      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'submit',
      };
      if (!reqData.project_id || !reqData.task_id ) {
        return;
      }
      if(type == 1){
        this.suspendLoading = true;
      }else{
        this.invalidLoading = true;
      }
      // this.workedTaskId[this.dataId] = true;
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      this.api.task.taskExecute(reqData).then((res)=>{
        let message = '';
        if(type == 1){
          this.suspendLoading = false;
          message = '挂起成功';
          this.$set(this.allData[this.curId],'suspend',1);
          this.next('suspend'); //执行下一步时不需要暂存
        }else{
          this.invalidLoading = false;
          message = '无效作业操作成功';
          this.$set(this.allData[this.curId],'invalid',1);
          this.next('invalid'); //执行下一步时不需要暂存
        }
        this.$Message.destroy();
        this.$Message.success({
          content: this.$t(message),
          duration: 1,
        });
      }).catch((err)=>{
        if(type == 1){
          this.suspendLoading = false;
        }else{
          this.invalidLoading = false;
        }
      })
    },
    checkEnclosure(data){
      // let suffixArr = data.split('.');
      // let suffix = suffixArr[suffixArr.length -1];
      // if(suffix == 'pdf'){
      //   window.open(data,'_blank')
      // }else{
      //   let fileUrl=encodeURIComponent(data)
      //   //使用Office Web查看器
      //   let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src='+fileUrl
      //   window.open(officeUrl,'_target');
      // }

      util.downloadFile(this,data);
    }
  },
  destroyed(){
    clearInterval(this.timer);
  }
}
</script>

<style scoped lang="scss">

.tabInfo-enter-active {
  animation: testanimation 1s;
}
.tabInfo-leave-active {
  animation: testanimation 1s reverse;
}
@keyframes testanimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
.exam-main{
  margin: 20px;
  .task-header{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    .task-header-l{
      flex: 1;
      width: 0;
      .task-header-l-tit{
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
      }
      .task-header-l-info{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        >p{
          margin-right: 20px;
        }
      }
      .task-header-l-tip{
        //padding: 20px;
        //background-color: #f8f8f9;
        display: flex;
        justify-content: flex-start;
        >div{
          padding: 20px;
          background-color: #f8f8f9;
        }
        >div:first-child{
          margin-right: 10px;
          width: 70%;
        }
        .task-header-l-tip-tit{
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
    .task-header-r{
      width: 300px;
      text-align: right;
      .task-header-r-storage{
        display: inline-block;
        color:#2db7f5;
        cursor: pointer;
      }
    }
  }

  .cont{
    position: relative;
    margin-top: 20px;
    text-align: left;
    display: flex;
    justify-content: flex-start;


    .cont-tit{
      font-weight: bold;
      font-size: 16px;
    }
    .cont-box{
      margin-right: 20px;
      width: 110px;
      position:sticky;
      top:0;
    }
    .cont-r{
      margin-left:130px ;
      flex:1;
      width: 0;
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 4px;
      font-size: 14px;
    }
    .cont-num{
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 4px;

      >div{
        margin: 8px 0;
        font-size: 16px;
        color:#333333;
        cursor: pointer;
        >span{
          display: inline-block;
          width: 36px;
          text-align: center;
        }
      }
      .active{
        color: #2d8cf0;
        font-weight: bold;
      }
      .suspend{
        background: #c5c8ce;
      }
      .invalid{
        background: #ed4014;
      }
    }
    //.exam-num{
    //  margin: 20px 0;
    //  font-size: 22px;
    //  font-weight: bold;
    //  color:#000000;
    //  >span{
    //    color: rgb(30, 130, 242);
    //  }
    //}
    .exam-des{
      font-size: 14px;
      .exam-des-top{
        margin-bottom: 20px;
        padding: 20px 20px 0 20px;
        background: #f8f8f9;
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 70%;
        >div{
          width: 30%;
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
      .exam-des-bot{
        >div{
          margin-bottom: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          >span{
            margin-right: 20px;
            font-weight: bold;
            width: 100px;
            text-align: right;
          }
        }
      }
      //>div{
      //  flex: 1;
      //  >div{
      //    display: flex;
      //    justify-content: flex-start;
      //    align-items: center;
      //    margin-bottom: 10px;
      //
      //    >span:nth-child(1){
      //      padding-right: 10px;
      //      width: 80px;
      //      text-align: right;
      //    }
      //  }
      //}

      .knowledge-point{
        width: 300px;
        border:1px solid #dcdee2;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .no-bor{
          flex: 1;
          border: none;
          min-width: 20px;

          input{
            border:none !important;
            background-color: transparent !important;
          }
        }
        >input:active,>input:hover{
          border:none;
        }
        >input[type=text]:focus{
          outline:none;
        }
      }
    }
    .exam-cont{
      margin-top: 30px;
      .item{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;

        .item-l{
          width: 100px;
          text-align: right;
          font-weight: bold;
        }

        .left-top{
          height: 34px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .data-correct{
            width: 95px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #999999;
            border-radius: 4px;
            font-weight: bold;
            text-align: center;
            line-height: 34px;
            box-sizing: border-box;
            cursor: pointer;
          }
          .active{
            background: #54C99F;
            color: #FFFFFF !important;
            border: none;
          }
          .data-dele{
            cursor: pointer;
          }
        }
        .item-r{
          margin-left: 20px;
          flex: 1;

          .item-r-input{
            min-height: 80px;

            //border:1px solid #dcdee2;
            border-radius: 4px;
            position: relative;

            .item-r-input-cont{
              display: flex;
              justify-content: flex-start;
              min-height: 80px;

              .left{
                flex: 1;
                width: 0;
                //padding: 10px;
              }

              .comment{
                margin: 0 10px;
                height: 300px;
                overflow-y: auto;
              }
            }
            //.btn{
            //  display: flex;
            //  flex-flow: column;
            //  position: absolute;
            //  right: 10px;
            //  top: 10px;
            //}


          }
          .item-r-input.correct{
            border:2px solid #2d8cf0;
          }
          .item-r-comment{
            margin-top: 10px;
            margin-bottom: 10px;
            color:red;
            line-height: 22px;
          }

        }

      }
      .add-btn{
        margin-bottom: 20px;
      }
      .comment-question{
        margin: 0 20px;
      }
      .previous-comment{
        padding:20px 0;
        width: 360px;
        height: 300px;
        background: #f8f8f9;
        font-size: 14px;

        .previous-comment-tit{
          padding:0 20px;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .previous-comment-cont{
          padding-left: 20px;
          height: 240px;
          overflow-y: auto;
          .info{
            //margin-top: -5px;
            display: flex;
            justify-content: flex-start;
            //align-items: center;
            >p:nth-child(2){
              margin: 0 20px;
            }
          }
          .color-b{
            color:#2d8cf0;
          }
        }
      }
    }
    .bot{
      margin-top: 40px;
      margin-bottom: 40px;
      padding-top: 20px;
      border-top: 1px solid #dcdee2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .bot-btn{
        margin-right: 20px;
      }
    }
  }

}
.mr{
  margin-right: 10px;
}
.mb20{
  margin-bottom: 20px;
}
.mt44{
  margin-top: 44px!important;
}
</style>
<style lang="scss">
.no-bor{
  flex: 1;
  border: none;
  min-width: 20px;

  input,
  input:focus,
  input:active,
  input:hover{
    border:none !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}
.comment-question{
  .ivu-input{
    height: 300px !important;
    width: 100% !important;

  }
}
</style>
