<template>
  <div class="exam-main">
    <div class="task-header" >
      <task-info
        :projectInfo = "projectInfo"
        :batchInfo = "batchInfo"
        :stepInfo = "stepInfo"
        :taskInfo = "taskInfo"
        :dataId="curId" />
      <div class="task-btn-group">
        <task-progress
            :total="taskList.length"
            :current="currentTaskIndex"
            :timeout="taskTimeout" />
        <!-- 挂起 -->
<!--        <Button type="warning" size="small" @click.native="setDifficult" >-->
<!--          {{$t('tool_submit_difficult_job')}}-->
<!--        </Button>-->

        <Button type="primary" size="small" @click.native="reject" :loading="rejectLoading">{{ $t('task_reject') }}</Button>
        <Button type="primary" size="small" @click.native="submit" :loading="loading">{{ $t('task_pass') }}</Button>
        <!-- <Button type="warning" size="small" @click.native="reset" :loading="resetLoading">{{ $t('task_reset') }}</Button> -->
        <Button type="warning" size="small" @click.native="exit" :loading="exitLoading">{{$t('tool_quit')}}</Button>
      </div>
    </div>
    <div class="tip">
      <div class="tip-tit">
        <p :class="{last : index == (projectTabList.length - 1),active:curProjectTab.id == item.id }" v-for="(item,index) in projectTabList" :key="item.id" @click="changeProjectTab(item)">{{ item.name }}</p>
        <span style="display: inline-block" @click="isShow = false;" v-if="isShow"><Icon type="ios-arrow-up" size="28" /></span>
        <span style="display: inline-block" @click="isShow = true;" v-else><Icon type="ios-arrow-down" size="28" /></span>

      </div>
      <div :style="{height:isShow ? 'auto' : '0px'}" class="tip-container">
        <div class="tip-cont" v-if="curProjectTab.id == 1">
<!--          {{projectInfo.attr[curProjectTab.key]}}-->
          <Richtxt :isDisabled="true" :dataObj="{val:projectInfo.attr[curProjectTab.key]}"></Richtxt>
        </div>
        <div class="tip-cont" v-if="curProjectTab.id == 2">
          <p v-for="item in projectInfo.attr[curProjectTab.key]" :key="item.url">
            {{item.name}}
          </p>
        </div>
        <div class="tip-cont" v-if="curProjectTab.id == 3">
          {{projectInfo.attr[curProjectTab.key]}}
        </div>
      </div>
    </div>
    <div class="cont">
      <p class="cont-tit">{{ $t('task_overview') }}</p>
      <div class="cont-box">
        <div class="cont-num">
          <span :class="{active:curDataIndex == index,success: allData[item.data.id] && allData[item.data.id].verify == 1,reject:allData[item.data.id] && allData[item.data.id].verify == 0,reset: allData[item.data.id] && allData[item.data.id].verify == 2,suspend:allData[item.data.id] && allData[item.data.id].verify == 3,invalid:allData[item.data.id] && allData[item.data.id].verify == 4}" v-for="(item,index) in taskList" :key="item.data.id" @click="changeData(index)">{{index + 1}}</span>
        </div>
        <div>
          <Button style="margin-right: 10px" type="primary" @click="suspend" :loading="suspendLoading">挂起</Button>
<!--          <Button type="error" @click="invalid" :loading="invalidLoading">无效作业</Button>-->
        </div>

      </div>

      <div class="exam-num">
        {{ $t('task_section') }} <span>{{curDataIndex + 1}}</span> {{ $t('task_topic') }} <span style="color:#000000;" v-if="taskList[curDataIndex].dataResult.result.is_invalid == 1">(无效作业)</span>
      </div>
      <div class="exam-des">
        <div class="l">
<!--          <div>-->
<!--            <span>{{ $t('task_work_tasks') }}:</span>-->
<!--            <span>{{ taskList[curDataIndex].dataResult.data['工作任务'] }}</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>{{ $t('task_skill_set') }}:</span>-->
<!--            <span>{{ taskList[curDataIndex].dataResult.data['技能集'] }}</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>{{ $t('task_first_level') }}:</span>-->
<!--            <span>{{ taskList[curDataIndex].dataResult.data['技能点（一级知识点）'] }}</span>-->
<!--          </div>-->
          <div>
            <span>{{ $t('task_question_type') }}:</span>
            <span>{{ taskList[curDataIndex].dataResult.data.type_name ||taskList[curDataIndex].dataResult.data.type }}</span>
          </div>
          <div>
            <span>{{ $t('task_difficulty_level') }}:</span>
            <span>{{ taskList[curDataIndex].dataResult.data.level_name || taskList[curDataIndex].dataResult.data.level }}</span>
          </div>
          <div>
            <span>{{ $t('task_knowledge_points') }}:<br />({{ $t('task_newly_build') }})</span>
            <div>
              <el-select
                  v-model="allData[curId].nodes"
                  multiple
                  filterable
                  remote
                  disabled
                  :remote-method="(query) => remoteMethod(query)"
                  :loading="knowledgePointLoading"
                  style="width:300px;"
                  @change="changeNodes"
              >
                <el-option
                    v-for="item in knowledgePointList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="r">


          <div>
            <span>分类:</span>
            <div>
              <el-select
                  v-model="allData[curId].categories"
                  multiple
                  filterable
                  remote
                  disabled
                  :remote-method="(query) => remoteMethod(query)"
                  :loading="categoryLoading"
                  style="width:300px;"
                  @change="changeCategory"
              >
                <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div>
            <span>标签:</span>
            <div>
              <el-select
                  v-model="allData[curId].labels"
                  multiple
                  filterable
                  remote
                  disabled
                  :remote-method="(query) => remoteMethod(query)"
                  :loading="labelLoading"
                  style="width:300px;"
                  @change="changeLabel"
              >
                <el-option
                    v-for="item in labelList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="exam-cont">
        <div class="item">
          <div class="item-l">
            <p>*{{ $t('task_test_title') }}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input">
              <div class="item-r-input-cont">
                <div class="left" >
                  <Richtxt :isDisabled="true" :dataObj="allData[curId].title" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].title)"></Richtxt>
                </div>
                <div class="right">
                  <div class="tit">
                    评论区
                  </div>
                  <div class="comment1">
                    <Input v-model="allData[curId].title.comment" type="textarea" :placeholder="$t('project_create_info_careful_tip')" />
                  </div>

                </div>
              </div>

              <div class="btn">
                <!--              <Button size="small">预览</Button>-->
              </div>
            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
        <div class="item" v-for="(item,index) in allData[curId].optionList" :key="item.id + 'option'">
          <div class="item-l">
            <p>*{{ item.name }}</p>
            <p :class="item.isCorrect ? 'correct' : ''" @click="changeCorrect(item)">{{item.isCorrect ? $t('task_correct_option') : $t('task_set_correct_option')}}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input" :class="item.isCorrect ? 'correct' : ''">
              <div class="item-r-input-cont">
                <div class="left">
                  <Richtxt :isDisabled="true" :height="150" :dataObj="item" :curId="curId" @saveData="(data)=>saveRichData(data,item)"></Richtxt>
                </div>
                <div class="right">
                  <div class="tit">
                    评论区
                  </div>
                  <div class="comment">
                    <Input v-model="item.comment" type="textarea" :placeholder="$t('project_create_info_careful_tip')" />
                  </div>
                </div>
              </div>
              <div class="btn">
                <!--              <Button size="small">预览</Button>-->
                <Button size="small" type="error" style="margin-top: 10px" v-if="index > 3" @click="deleOption(index)">
                  {{ $t('task_delete') }}</Button>
              </div>
            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-l">
            <p>{{ $t('task_analysis') }}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input">
              <div class="item-r-input-cont">
                <div class="left">
                  <Richtxt :isDisabled="true" :dataObj="allData[curId].analysis" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].analysis)"></Richtxt>
                </div>
                <div class="right">
                  <div class="tit">
                    评论区
                  </div>
                  <div class="comment1">
                    <Input v-model="allData[curId].analysis.comment" type="textarea" :placeholder="$t('project_create_info_careful_tip')" />
                  </div>
                </div>
              </div>

              <div class="btn">
                <!--               <Button size="small">预览</Button>-->
              </div>
            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <Button type="primary" class="bot-btn" @click="pre">{{ $t('task_pre_question') }}</Button>
        <!--        <Button class="bot-btn" >提交</Button>-->
        <Button type="primary" @click="next">{{ $t('task_next_question') }}</Button>
      </div>
    </div>
    <Modal
        v-model="timeoutModal"
        :title="$t('task_task_timed_out')"
        :mask-closable="false"
        :closable="false"
    >
      <div class="timeout-text">{{ $t('task_re_claim') }}</div>
      <div slot="footer">
        <Button type="primary" @click="again">{{ $t('task_get_task_again') }}</Button>

        <Button type="primary" @click="backTask">{{ $t('task_return_to_task') }}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Richtxt from '../richtxt.vue';
import TaskProgress from '../progress.vue';
import TaskInfo from '../taskinfo.vue';

import EventBus from '@/utils/eventBus';


export default {
  name: "audit",
  props: {
    templateInfo: {
      type: Array,
      default: [],
    },
    taskList: {
      type: Array,
      required: true,
    },
    categoryInfo: {
      type: Object,
      required: true,
    },
    serverTime: {
      type: Number,
      required: true,
    },
    taskInfo: {
      type: Object,
      required: true,
    },
    taskTimeout: {
      type: Number,
      required: true,
    },
    taskStat: {
      type: Object,
      required: true,
    },
    stepInfo: {
      type: Object,
      required: true,
    },
    batchInfo: {
      type: Object,
      required: true,
    },
    projectInfo: {
      type: Object,
      required: true,
    },
  },
  data(){
    return{
      // knowledgePointValue:[],
      isShow:false, //默认收起tab项
      projectTabList:[
        {
          id:1,
          name:this.$t('task_attention'),
          key:'description'
        },
        {
          id:2,
          name:this.$t('task_attachment'),
          key:'attachments'
        },
        // {
        //   id:3,
        //   name:this.$t('task_feedback'),
        // },
      ],
      curProjectTab:{
        id:1,
        name:this.$t('task_attention'),
        key:'description'
      },
      curDataIndex:0,// 当前数据
      cont:'',
      nameList:[this.$t('task_option_e'),this.$t('task_option_f')],
      // taskTimeout: 600,
      currentTaskIndex: 0,
      loading: false,
      taskItemInfo: '',
      taskItemInfoMore: {},
      loadingText: this.$t('tool_loading'),
      userStat: {},
      textPlaceHolderReady: false,
      workedTaskId: {},
      knowledgePoint:[],  //选中的知识点
      knowledgePointList:[],  //知识点列表
      knowledgePointLoading:false,
      questionType:'',
      allData:{},
      curId:'', //当前数据id
      topStatus:true,
      timeoutModal:false,
      rejectLoading:false,  //驳回loading
      resetLoading:false, //重置
      exitLoading:false,  //退出loading
      categoryLoading:false,
      categoryList:[],
      labelLoading:false,
      labelList:[],
      suspendLoading:false,
      invalidLoading:false,
    }
  },
  components:{
    Richtxt,
    'task-progress': TaskProgress,
    'task-info': TaskInfo,
  },
  computed: {
    dataId () {
      return this.taskList[this.currentTaskIndex].data.id;
    },
  },
  watch:{
    taskList:{
      handler(newV){
        this.init();
      },
      immediate:true
    }
  },
  created(){
    this.$emit('componentsLoaded');

    // this.init();
  },
  methods:{
    init(){
      EventBus.$on('textFilePlaceholderReady', this.initTask);

      this.curDataIndex = 0;

      this.userStat = this.taskStat;
      this.workedTaskId = {};
      EventBus.$on('task-timeout', this.setTaskTimeout);

      //判断类型  单选多选 默认四个选项 判断题 默认两个选项  不可添加选项
      this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];
      this.curId = this.taskList[this.curDataIndex].data.id;

      //格式化数据
      this.formate();
    },
    formate(){
      this.allData = {};
      this.taskList.forEach((item)=>{
        let name = item.data.id;
        let options = [];
        let names = [this.$t('task_option_a'),this.$t('task_option_b'),this.$t('task_option_c'),this.$t('task_option_d'),this.$t('task_option_e'),this.$t('task_option_f')];

        let result = item.dataResult.result;

        if(result){
          result.info.options.forEach((sItem,index)=>{
            options.push({
              id:sItem.id,
              name:names[index],
              isCorrect:sItem.is_correct == '1',
              val:sItem.name,
              comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback[sItem.id] : '') : '',
              isCheck:false,
              isRich:false,
              placeholder:this.$t('task_option_placeholder')
            })
          })

          let title = {
            val:result.info.name,
            comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback.name : '') : '',
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_title_placeholder')
          };
          let analysis = {
            val:result.info.explain,
            comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback.explain : '') : '',
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder')
          };
          this.$set(this.allData,name,{});
          this.$set(this.allData[name],'nodes',result.info.nodes);
          this.$set(this.allData[name],'categories',result.info.categories);
          this.$set(this.allData[name],'labels',result.info.labels);

          this.$set(this.allData[name],'title',title);
          this.$set(this.allData[name],'analysis',analysis);
          this.$set(this.allData[name],'optionList',options);
          this.$set(this.allData[name],'id',result.info.id);
          this.$set(this.allData[name],'type',result.info.type);
          this.$set(this.allData[name],'verify',-1);  //-1 没状态 0 驳回 1通过 2重置
        }
      })
      let nodeIds = this.taskList[0].dataResult.result.info.nodes;
      this.getKnowledgePoint(nodeIds,'ids');

      let categoresIds = this.taskList[0].dataResult.result.info.categories;
      this.getCategory(categoresIds,'ids');

      let labelsIds = this.taskList[0].dataResult.result.info.labels;
      this.getLabel(labelsIds,'ids');

    },
    setTaskTimeout () {
      console.log('22222++++++')
      this.timeoutModal = true;
    },
    saveTaskResult (next = true, verify = 1) { //verify 0驳回,1通过2重置 3 挂起 4 无效作业
      if (this.loading) {
        return;
      }

      let result = {};
      if(verify == 1){  //通过
        result[this.curId] = {
          "verify":{
            "verify":1,
            "correct_work_id":this.taskList[this.curDataIndex].parentWorks[0].id,
          },"is_difficult":0
        };
      }else if(verify == 0){ //驳回
        let feedback = {};
        this.allData[this.curId].optionList.forEach((item)=>{
          if(item.comment){
            feedback[item.id] = item.comment;
          }
        });
        if(this.allData[this.curId].title.comment){
          feedback.name = this.allData[this.curId].title.comment;
        }
        if(this.allData[this.curId].analysis.comment){
          feedback.explain = this.allData[this.curId].analysis.comment;
        }
        let len = Object.keys(feedback);
        console.log('len',len)
        if(!len.length){
          this.$Message.warning(this.$t('task_enter_comment'));
          return;
        }
        result[this.curId] = {
          "verify":{
            "verify":0
          },
          "feedback":feedback,
          "is_difficult":0
        }
      }else if(verify == 2){  //重置
        result[this.curId] = {
          "verify":{
            "verify":2
          },"is_difficult":0
        }
      }else if(verify == 3){  //挂起
        result[this.curId] = {
          "is_difficult":1
        }
      }else if(verify == 4){  //无效作业
        result[this.curId] = {
          "is_invalid":1
        }
      }


      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'submit',
      };
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      if (!reqData.project_id || !reqData.task_id ) {
        return;
      }
      if(verify == 1){  //通过
        this.loading = true;
      }else if(verify == 0){  //驳回
        this.rejectLoading = true;
      }else if(verify == 2){  //重置
        this.resetLoading = true;
      }else if(verify == 3){  //挂起
        this.suspendLoading = true;
      }else if(verify == 4){  //无效作业
        this.invalidLoading = true;
      }

      this.api.task.taskExecute(reqData).then((res)=>{

        if(verify == 1){  //通过
          this.loading = false;
          this.allData[this.curId].verify = 1;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('tool_submit_success'),
            duration: 1,
          });
        }else if(verify == 0){  //驳回
          this.rejectLoading = false;
          this.allData[this.curId].verify = 0;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('task_reject_success'),
            duration: 1,
          });
        }else if(verify == 2){  //重置
          this.resetLoading = false;
          this.allData[this.curId].verify = 2;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('task_reset_success'),
            duration: 1,
          });
        }else if(verify == 3){  //挂起
          this.suspendLoading = false;
          this.allData[this.curId].verify = 3;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('挂起成功'),
            duration: 1,
          });
        }else if(verify == 4){  //无效作业
          this.invalidLoading = false;
          this.allData[this.curId].verify = 4;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('无效作业操作成功'),
            duration: 1,
          });
        }


        // EventBus.$emit('perform-fetchTask');
      }).catch((err)=>{
        if(verify == 1){  //通过
          this.loading = false;
        }else if(verify == 0){  //驳回
          this.rejectLoading = false;
        }else if(verify == 2){  //重置
          this.resetLoading = false;
        }else if(verify == 3){  //挂起
          this.suspendLoading = false;
        }else if(verify == 4){  //无效作业
          this.invalidLoading = false;
        }
      })
    },
    // setDifficult () {
    //   this.saveTaskResult(true, 1);
    // },
    submit () {
      this.saveTaskResult(true, 1);
    },
    reset () {
      this.saveTaskResult(true, 2);
    },
    reject () {
      this.saveTaskResult(true, 0);
    },
    suspend () {
      this.saveTaskResult(true, 3);
    },
    invalid(){
      this.saveTaskResult(true, 4);
    },
    changeProjectTab(data){
      this.curProjectTab = data;
    },
    changeData(index){
      console.log(this.allData,this.taskList,index);
      console.log(this.allData[this.taskList[index].data.id],'this.allData[this.taskList[index].data.id]1111')
      console.log(this.allData[this.taskList[index].data.id],this.allData[this.taskList[index].data.id].verify,'this.allData[this.taskList[index].data.id].verify')
      if(this.allData[this.taskList[index].data.id].verify != -1){
        this.$Message.warning(this.$t('task_disabled_operated'));
        return;
      }else{
        this.curDataIndex = index;
        this.curId = this.taskList[this.curDataIndex].data.id;
        // this.knowledgePointValue = this.allData[this.curId].nodes;
        let nodeIds = this.taskList[this.curDataIndex].dataResult.result.info.nodes;
        this.getKnowledgePoint(nodeIds,'ids');

        let categoresIds = this.taskList[this.curDataIndex].dataResult.result.info.categories;
        this.getCategory(categoresIds,'ids');

        let labelsIds = this.taskList[this.curDataIndex].dataResult.result.info.labels;
        this.getLabel(labelsIds,'ids');
      }
    },
    next(num){
      //计算剩余作业
      let allowDataIds = [];
      this.taskList.forEach((item, index)=>{
          if (this.allData[this.taskList[index].data.id].verify == -1){
            allowDataIds.push(this.taskList[index].data.id);
          }
      });
      console.log(allowDataIds, allowDataIds.length)

      //没有剩余作业, 自动领取
      if (allowDataIds.length < 1) {
        this.$emit('againGetTask')
        return;
      }

      // if(this.curDataIndex == this.taskList.length -1){
      //   this.$Message.warning(this.$t('task_last_data'));
      //   //if(num == 1){ //判断是否重新领取 如果为1是提交 判断是否都做完了  都做完了重新领取
      //     let len = 0;
      //     for(let name in this.allData){
      //       if(this.allData[name].verify == -1){
      //         len++;
      //       }
      //     }
      //     if(!len){ //全部做完了 重新领取
      //       //提交后重新领取任务 如果有任务继续做  没有返回上一级
      //       this.$emit('againGetTask')
      //     }
      //   //}
      // }else{

        if (this.curDataIndex >= this.taskList.length - 1) {
          console.log(this.curDataIndex,this.taskList.length,'next-to 0')
          this.curDataIndex = 0;
        } else {
          this.curDataIndex += 1
        }

        if(this.allData[this.taskList[this.curDataIndex].data.id].verify != -1){
          this.curId = this.taskList[this.curDataIndex].data.id;
          this.next();
        }else{
          this.curId = this.taskList[this.curDataIndex].data.id;

          let nodeIds = this.taskList[this.curDataIndex].dataResult.result.info.nodes;
          this.getKnowledgePoint(nodeIds,'ids');

          let categoresIds = this.taskList[this.curDataIndex].dataResult.result.info.categories;
          this.getCategory(categoresIds,'ids');

          let labelsIds = this.taskList[this.curDataIndex].dataResult.result.info.labels;
          this.getLabel(labelsIds,'ids');
        }
      // }
    },
    pre(){
      console.log("pre start")
      //计算剩余作业
      let allowDataIds = [];
      this.taskList.forEach((item, index)=>{
          if (this.allData[this.taskList[index].data.id].verify == -1){
            allowDataIds.push(this.taskList[index].data.id);
          }
      });
      console.log(allowDataIds)

      //没有剩余作业, 自动领取
      if (allowDataIds.length < 1) {
        console.log("againGetTask")
        this.$emit('againGetTask')
        return;
      }

      if (this.curDataIndex == 0) {
        console.log(this.curDataIndex,this.taskList.length,'next-to end')
        this.curDataIndex = this.taskList.length - 1;
      } else {
        this.curDataIndex -= 1;
        console.log(this.curDataIndex)
      }

      if(this.allData[this.taskList[this.curDataIndex].data.id].verify != -1){
        this.curId = this.taskList[this.curDataIndex].data.id;
        // this.knowledgePointValue = this.allData[this.curId].nodes;
        this.pre();
      }else{
        this.curId = this.taskList[this.curDataIndex].data.id;
        // this.knowledgePointValue = this.allData[this.curId].nodes;
        let nodeIds = this.taskList[this.curDataIndex].dataResult.result.info.nodes;
        console.log(this.allData,'+++++++++++++++++++++')
        this.getKnowledgePoint(nodeIds,'ids');

        let categoresIds = this.taskList[this.curDataIndex].dataResult.result.info.categories;
        this.getCategory(categoresIds,'ids');

        let labelsIds = this.taskList[this.curDataIndex].dataResult.result.info.labels;
        this.getLabel(labelsIds,'ids');
      }

      console.log("pre end")
    },
    remoteMethod(query){ //模糊搜索
      if (query !== '') {
        this.getKnowledgePoint(query);
      } else {
        this.knowledgePointList = [];
      }
    },
    getKnowledgePoint(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };
      if(type){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.knowledgePointLoading = true;
      this.api.dataset.datasetNodeList(pramas).then((res)=>{
        this.knowledgePointLoading = false;

        this.knowledgePointList = res.list;
      }).catch((err)=>{
        this.knowledgePointLoading = false;
      })
    },
    showTitleRich(){
      this.closeRich();
      this.title.isRich = true;
    },
    showAnalysisRich(){
      this.closeRich();
      this.analysis.isRich = true;
    },
    changeCorrect(data){
      if(this.taskList[this.curDataIndex].dataResult.data.type == 8 || this.taskList[this.curDataIndex].dataResult.data.type == 9){
        return;
      }
      if(!data.isCorrect){
        let correctLen = this.allData[this.curId].optionList.filter((item)=>{
          return item.isCorrect;
        }).length;
        //如果是单选 或者 判断题 只能有一个正确选项  多选不能全选
        if(this.questionType == '单选' || this.questionType == '判断题'){
          if(correctLen >= 1){
            this.$Message.warning(this.$t('task_selected_one'));
            return;
          }
        }else{  //多选
          if(correctLen >= this.allData[this.curId].optionList.length - 1){
            this.$Message.warning(this.$t('task_disabled_all'));
            return;
          }
        }
      }
      data.isCorrect = !data.isCorrect;
    },
    saveRichData(curData,data){ //同步富文本数据
      data.val = curData;
    },
    changeNodes(data){
      this.allData[this.curId].nodes = data;
    },
    changeCategory(data){
      this.allData[this.curId].categories = data;
    },
    changeLabel(data){
      this.allData[this.curId].labels = data;
    },

    exit () {
      let len = 0;
      for(let name in this.allData){
        if(this.allData[name].verify == -1){
          len++;
        }
      }
      this.$Modal.confirm({
        title: this.$t('tool_exit_confirmation'),
        content: this.$t('tool_exit_description', {num: len}),
        loading: true,
        onOk: () => {
          this.clearTask();
        }
      });
    },
    clearTask () {
      EventBus.$emit('needConfirmLeave', false);

      let params = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        op: 'clear',
      }
      if(this.$route.query.data_id){
        params.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        params.user_id = this.$route.query.user_id;
      }

      this.exitLoading = true;
      this.api.task.taskExecute(params).then((res)=>{
        this.exitLoading = false;
        this.$Modal.remove();
        this.$store.commit('removeTag', 'perform-task');


        this.$router.push({
          path: '/production/task',
        });
      }).catch((err)=>{
        this.exitLoading = false;
      })
    },
    again(){
      this.$emit('againGetTask')
    },
    backTask(){
      this.$router.push({
        path: '/production/task',
      });
    },
    getCategory(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.categoryLoading = true;
      this.api.dataset.categoryList(pramas).then((res)=>{
        this.categoryLoading = false;

        this.categoryList = res.list;
      }).catch((err)=>{
        this.categoryLoading = false;
      })
    },
    getLabel(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.labelLoading = true;
      this.api.dataset.labelList(pramas).then((res)=>{
        this.labelLoading = false;

        this.labelList = res.list;
      }).catch((err)=>{
        this.labelLoading = false;
      })
    },
  }
}
</script>

<style scoped lang="scss">

.tabInfo-enter-active {
  animation: testanimation 1s;
}
.tabInfo-leave-active {
  animation: testanimation 1s reverse;
}
@keyframes testanimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
.exam-main{
  margin: 20px;
  .top{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-r{
      display: flex;
      align-items: center;
    }
  }
  .tip{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .tip-tit{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >p{
        width: 100px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color:#2d8cf0;
        border:1px solid #2d8cf0;
        cursor: pointer;
      }
      >p:not(:last-child){
        border-right:none;
      }
      >p:first-child{
        border-radius: 4px 0 0 4px;
      }
      .last{
        margin-right: 10px;
        border-radius: 0 4px 4px 0;
        border-right:1px solid #2d8cf0 !important;
      }
      .active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
    .tip-container{
      transition: height .5s linear;
      overflow: hidden;
    }
    .tip-cont{
      margin-top: 10px;
      padding: 20px;
      border: 1px solid #dcdee2;
      min-height: 80px;
      border-radius: 4px;
    }
  }
  .cont{
    margin-top: 20px;
    text-align: left;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .cont-tit{
      font-weight: bold;
      font-size: 16px;
    }
    .cont-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .cont-num{
      margin-top: 10px;

      >span{
        display: inline-block;
        font-size: 16px;
        color:#ffffff;
        border-radius: 20px;
        margin: 5px 15px 5px 0;
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: #808695;
        cursor: pointer;
      }
      .active{
        background: #2d8cf0;
      }
      .success{
        background: #19be6b;
      }
      .reject{
        background: #ed4014;
      }
      .reset{
         background: #ff9900;
       }
      .suspend{
        background: #c5c8ce;
      }
      .invalid{
        background:red;
      }
    }
    .exam-num{
      margin: 20px 0;
      font-size: 22px;
      font-weight: bold;
      color:#000000;
      >span{
        color: rgb(30, 130, 242);
      }
    }
    .exam-des{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >div{
        flex: 1;
        >div{
          display: flex;
          justify-content: flex-start;
          margin-bottom: 10px;

          >span:nth-child(1){
            padding-right: 10px;
            width: 80px;
            text-align: right;
          }
        }
      }

      .knowledge-point{
        width: 300px;
        border:1px solid #dcdee2;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .no-bor{
          flex: 1;
          border: none;
          min-width: 20px;

          input{
            border:none !important;
            background-color: transparent !important;
          }
        }
        >input:active,>input:hover{
          border:none;
        }
        >input[type=text]:focus{
          outline:none;
        }
      }
    }
    .exam-cont{
      margin-top: 20px;
      .item{
        display: flex;
        justify-content: flex-start;

        .item-l{
          width: 100px;
          text-align: right;

          >p:nth-child(2){
            margin-top: 5px;
            display: inline-block;
            padding: 5px 10px;
            border:1px solid #dcdee2;
            border-radius: 4px;
            cursor: pointer;
          }
          >p.correct{
            border:1px solid #19be6b;
            background-color: #19be6b;
            color:#FFFFFF;
          }
        }
        .item-r{
          margin-left: 20px;
          flex: 1;

          .item-r-input{
            min-height: 80px;

            //border:1px solid #dcdee2;
            border-radius: 4px;
            position: relative;

            .item-r-input-cont{
              display: flex;
              justify-content: flex-start;
              min-height: 80px;
              .left{
                width: 600px;
                //padding: 10px;
              }
              .right{
                flex: 1;
                padding: 10px;
              }

              .right{
                //border-left:1px solid #dcdee2;
                border:1px solid #dcdee2;
                border-left: none;
                .tit{
                  padding-left: 20px;
                  height: 43px;
                  line-height: 43px;
                  font-size: 14px;
                  border-bottom: 1px solid #f2f6fc;
                }
              }
            }
            .btn{
              display: flex;
              flex-flow: column;
              position: absolute;
              right: 10px;
              top: 10px;
            }


          }
          .item-r-input.correct{
            border:2px solid #2d8cf0;
          }
          .item-r-comment{
            margin-top: 10px;
            margin-bottom: 10px;
            color:red;
            line-height: 22px;
          }

        }

      }
      .add-btn{
        margin-bottom: 20px;
      }
    }
    .bot{
      margin-top: 40px;
      margin-bottom: 40px;
      padding-top: 20px;
      border-top: 1px solid #dcdee2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .bot-btn{
        margin-right: 20px;
      }
    }
  }

}
.mr{
  margin-right: 10px;
}
.timeout-text{
  font-size: 14px;
}
</style>
<style lang="scss">
.no-bor{
  flex: 1;
  border: none;
  min-width: 20px;

  input,
  input:focus,
  input:active,
  input:hover{
    border:none !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}
.comment1{
  .ivu-input{
    height: 235px !important;
    width: 100% !important;
    border:none !important;
  }
}
.comment{
  .ivu-input{
    height: 85px !important;
    width: 100% !important;
    border:none !important;
  }
}
</style>
