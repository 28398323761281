<template>
  <div>
    <div style="padding: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="task-view">
      <!-- 根据分类和工序匹配组卷 -->
      <component :is="TaskViewNow"
                 :templateInfo = "templateInfo"
                 :taskList = "taskList"
                 :userList = "userList"
                 :categoryInfo = "categoryInfo"
                 :serverTime = "serverTime"
                 :taskTimeout = "taskTimeout"
                 :taskStat = "taskStat"
                 :taskInfo = "taskInfo"
                 :qualityData="qualityData"
                 :stepInfo = "stepInfo"
                 :batchInfo= "batchInfo"
                 :projectInfo = "projectInfo"
                 @componentsLoaded = "componentsLoaded"
                 @againGetTask="fetchTask"
      ></component>
      <!--    <Spin fix v-if="loading"></Spin>-->
    </div>
  </div>

</template>

<script>
// import '@/libs/tooltips/bootstrap.min.css'; // 只包含 tooltips 和 poppover
// import '@/libs/tooltips/bootstrap.min.js'; // 只包含 tooltips 和 poppover
import util from "@/utils/tools";
import CategoryQuestionProduce from '@/components/production/category_question/produce';
import CategoryQuestionAudit from '@/components/production/category_question/audit';

import CategoryQuestionVerifyProduce from '@/components/production/category_question_verify/produce';
import CategoryQuestionVerifyAudit from '@/components/production/category_question_verify/audit';

import CategoryQuestionEnProduce from '@/components/production/category_question_en/produce';
import CategoryQuestionEnAudit from '@/components/production/category_question_en/audit';
import EventBus from '@/utils/eventBus';
const taskListOrder = {
  random: 1,
  sorted: 0,
}
export default {
  name: 'task-view',
  data () {
    return {
      TaskViews: {
        'category_question' : {
            '0': 'CategoryQuestionProduce',  //执行
            '1': 'CategoryQuestionAudit',   //审核
            '2': 'CategoryQuestionAudit',   //质检
            '3': 'CategoryQuestionAudit', //验收
        },
        'category_question_verify' : {
          '0': 'CategoryQuestionVerifyProduce',  //执行
          '1': 'CategoryQuestionVerifyAudit',   //审核
          '2': 'CategoryQuestionVerifyAudit',   //质检
          '3': 'CategoryQuestionVerifyAudit', //验收
        },
        'category_question_translate' : {
          '0': 'CategoryQuestionEnProduce',  //执行
          '1': 'CategoryQuestionEnAudit',   //审核
          '2': 'CategoryQuestionEnAudit',   //质检
          '3': 'CategoryQuestionEnAudit', //验收
        },
      },
      TaskViewNow:'',
      data_sort: taskListOrder.sorted,
      stepInfo: {},
      templateInfo: [],
      taskList: [],
      userList: [],
      categoryInfo: {},
      serverTime: 0,
      taskTimeout: 0,
      currentUserId: '',
      // needConfirmLeave: false,
      taskStat: {},
      loading: true,
      taskInfo: {},
      qualityData: {},
      batchInfo: {},
      projectInfo:{},
      data_id:'',
    };
  },
  components:{
    CategoryQuestionProduce,
    CategoryQuestionAudit,
    CategoryQuestionVerifyProduce,
    CategoryQuestionVerifyAudit,
    CategoryQuestionEnProduce,
    CategoryQuestionEnAudit
  },
  // beforeRouteLeave (to, from, next) { // 离开前提示
  //   if (!this.$store.state.user.userInfo.id) {
  //     this.needConfirmLeave = false;
  //   }
  //   if (this.needConfirmLeave) {
  //     const answer = window.confirm(this.$t('tool_leave_content_lost'));
  //     if (answer) {
  //       next();
  //     } else {
  //       util.openNewPage(this, this.$route.name, this.$route.params, this.$route.query);
  //       next(false);
  //     }
  //   } else {
  //     next();
  //   }
  // },
  mounted () {
    console.log('12121212')
    this.projectId = this.$route.query.project_id;
    this.taskId = this.$route.query.task_id;
    this.data_id = this.$route.query.data_id;

    this.fetchTask();
    // this.$store.state.app.userInfoRequest.then(() => {
    //   this.fetchTask();
    // });
    // EventBus.$on('perform-fetchTask', this.fetchTask);
    // // 监听此事件的地方 还包括 每个分类的审核
    // EventBus.$on('clear-fetchTask', this.clearTask);
    // EventBus.$on('needConfirmLeave', this.setneedConfirmLeave);
    // this.bindKeyDownEvent();

  },
  methods: {
    componentsLoaded () {
      this.loading = false;
    },
    // 领取任务
    fetchTask () {

      console.log('fetchTask ++++++++++++++++++++++++')

      let reqData = {
        project_id: this.projectId,
        task_id: this.taskId,
        user_id: '',
        // data_sort: this.data_sort,
        // data_id: this.data_id,
        op: 'fetch'
      };

      if (!reqData.project_id || !reqData.task_id) {
        console.log('no project_id or task_id')
        this.$router.go(-1);
        return;
      }
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }
      this.api.task.taskExecute(reqData).then((res)=>{

        // 判断是否有剩余作业
        if (res.list.length === 0) {
          this.$Message.destroy();
          this.$Message.info({
            content: this.$t('tool_no_job'),
            duration: 2
          });
          this.$store.commit('removeTag', 'perform-task');
          console.log('++++++++++++++++++++++++')
          this.$router.go(-1);
          return;
        }
        this.categoryInfo = res.category;
        this.stepInfo = res.step;
        this.batchInfo = res.batch;

        this.taskList = JSON.parse(JSON.stringify(res.list));
        console.log(this.taskList,'this.taskListthis.taskListthis.taskList')
        this.userList = res.parentWorkUsers;
        this.taskInfo = res.task;

        //根据分类和工序匹配组卷
        this.TaskViewNow = this.TaskViews[this.categoryInfo.key][this.stepInfo.type]

        // 避免重复赋值 导致组件渲染异常
        // if (this.templateInfo.length === 0) {
        //   this.templateInfo = (res.template && res.template.config) || [];
        // }
        this.serverTime = res.time;
        this.taskTimeout = res.timeout;
        //任务时间倒计时
        EventBus.$emit('start-counter-time');
        this.taskStat = res.stat? {
          label_count: res.stat.label_count,
          point_count: res.stat.point_count,
          work_count: res.stat.work_count
        } : {
          label_count: 0,
          point_count: 0,
          work_count: 0
        };
        this.qualityData = {
          pass_rate: res.pass_rate,
          audit_rate: res.audit_rate,
        };
        //项目详情
        this.projectInfo = res.project;
        for(let name in this.projectInfo.attr){
          if(name == 'attachments' && this.projectInfo.attr[name]){
            this.projectInfo.attr[name] = JSON.parse(this.projectInfo.attr[name]);
            this.projectInfo.attr[name] = this.projectInfo.attr[name].map((item)=>{
              let len = item.split('/').length;
              let name = item.split('/')[len - 1];

              return {
                name:name,
                url:item
              };
            });
          }
        }

        this.$Message.destroy();
        this.$Message.info({
          content: this.$t('tool_fetch_succ'),
          duration: 2
        });
      }).catch((err)=>{
        console.log('taskExecute fail')
        console.log(err)
        this.$router.go(-1);
      })
      // $.ajax({
      //   url: api.task.execute,
      //   type: 'post',
      //   data: reqData,
      //   success: (res) => {
      //     if (res.error) {
      //       this.needConfirmLeave = false;
      //       this.$Message.destroy();
      //       this.$Message.info({
      //         content: res.message,
      //         duration: 3
      //       });
      //       this.$store.commit('removeTag', 'perform-task');
      //       let preRouter = !this.$store.state.app.prevPageUrl.name ? {path: '/my-task/list'} : this.$store.state.app.prevPageUrl;
      //       if (preRouter) {
      //         this.$router.push({
      //           path: preRouter.path,
      //           params: preRouter.params,
      //           query: preRouter.query,
      //         });
      //       }
      //     } else {
      //       this.stepInfo = res.data.step;
      //       if (~['0', '2'].indexOf(this.stepInfo.type) && res.data.list.length === 0) { // 执行步骤判断是否有剩余作业
      //         this.needConfirmLeave = false;
      //         this.$Message.destroy();
      //         this.$Message.info({
      //           content: this.$t('tool_no_job'),
      //           duration: 2
      //         });
      //         this.$store.commit('removeTag', 'perform-task');
      //         let preRouter = !this.$store.state.app.prevPageUrl.name ? {path: '/my-task/list'} : this.$store.state.app.prevPageUrl;
      //         if (preRouter) {
      //           this.$router.push({
      //             path: preRouter.path,
      //             params: preRouter.params,
      //             query: preRouter.query,
      //           });
      //         }
      //         return;
      //       }
      //       this.categoryInfo = res.data.category;
      //       this.taskList = res.data.list;
      //       this.userList = res.data.parentWorkUsers;
      //       this.taskInfo = res.data.task;
      //       // 避免重复赋值 导致组件渲染异常
      //       if (this.templateInfo.length === 0) {
      //         this.templateInfo = (res.data.template && res.data.template.config) || [];
      //       }
      //       this.serverTime = res.data.time;
      //       this.timeout = res.data.timeout;
      //       EventBus.$emit('start-counter-time');
      //       this.taskStat = res.data.stat? {
      //         label_count: res.data.stat.label_count,
      //         point_count: res.data.stat.point_count,
      //         work_count: res.data.stat.work_count
      //       } : {
      //         label_count: 0,
      //         point_count: 0,
      //         work_count: 0
      //       };
      //       this.qualityData = {
      //         pass_rate: res.data.pass_rate,
      //         audit_rate: res.data.audit_rate,
      //       };
      //     }
      //   },
      //   error: (res) => {
      //     this.needConfirmLeave = false;
      //     // 错误处理
      //   }
      // });
    },
    // clearTask (e) {
    //   let oldUserId;
    //   if (e.type === 'workerChange') {
    //     this.currentUserId = e.data.cur;
    //     oldUserId = e.data.pre;
    //   } else {
    //     this.data_sort = e.data.cur;
    //   }
    //   this.loading = true;
    //   $.ajax({
    //     url: api.task.execute,
    //     type: 'post',
    //     data: {
    //       access_token: this.$store.state.user.userInfo.accessToken,
    //       project_id: this.projectId,
    //       task_id: this.taskId,
    //       user_id: oldUserId,
    //       op: 'clear'
    //     },
    //     success: (res) => {
    //       this.loading = false;
    //       if (res.error) {
    //         this.$Message.destroy();
    //         this.$Message.info({
    //           content: res.message,
    //           duration: 3
    //         });
    //       } else {
    //         this.fetchTask();
    //       }
    //     },
    //     error: (res) => {
    //       this.loading = false;
    //       // this.needConfirmLeave = false;
    //       // 错误处理
    //     }
    //   });
    // }
  },
  destroyed () {
    // EventBus.$off('perform-fetchTask', this.fetchTask);
    // EventBus.$off('needConfirmLeave', this.setneedConfirmLeave);
    // EventBus.$off('clear-fetchTask', this.clearTask);
    // this.unbindKeyDownEvent();
  }
};
</script>
<style lang="scss">
.task-view {
  //margin: 20px;
  //padding:20px;
  //background: #FFFFFF;
  //box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  //border-radius: 4px;
  min-height: 100%;
  //background: #fff;
  //padding: 8px;
}
.task-btn-group {
  button {
    margin-left: 10px;
  }
}
.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:0 0 5px;
  .task-btn-group,
  .flex-space-between {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
