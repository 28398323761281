<template>

    <div class="task-info">
        <span class="task-items">{{$t('tool_project_id')}}: {{ projectInfo.id }}</span>
        <span class="task-items">{{$t('tool_project_name')}}: {{projectInfo.name}}</span>
        <span class="task-items">{{$t('tool_batch_id')}}: {{batchInfo.id}}</span>
        <span class="task-items">{{$t('tool_batch_name')}}: {{batchname}}</span>
        <span class="task-items">{{$t('admin_process')}}: {{stepInfo.name}}</span>
        <span class="task-items">{{$t('tool_task_id')}}: {{ taskInfo.id }}</span>
        <span class="task-items">{{$t('tool_job_id')}}: {{ dataId }}</span>
        <!-- <span class="task-items">{{$t('tool_operator')}}: {{ taskInfo.user.email }}</span> -->
    </div>

    <!--
    <div class="task-info-wrapper">
        <h4 class="task-info-header">{{$t('tool_job_information')}}:</h4>
        <div class="task-items">
            <span class="task-ids-item" v-if="taskInfo.stepName">{{$t('admin_process')}}: {{taskInfo.stepName}}</span>

            <span class="task-ids-item" >{{$t('tool_job_id')}}: {{ dataId }}</span>
            <span class="task-ids-item">{{$t('tool_item_id')}}: {{ taskInfo.project_id }}</span>
            <span class="task-ids-item">{{$t('tool_task_id')}}: {{ taskInfo.id }}</span>
            <span class="task-ids-item" v-if="taskInfo.user && taskInfo.user.email ">{{$t('tool_operator')}}: {{ taskInfo.user.email }}</span>
        </div>
        <div class="task-items">
            {{$t('tool_task_name')}} ： {{ taskInfo.name }}
        </div>
        <div class="task-items" v-if="taskInfo.dataName">
            {{$t('tool_job_file_name')}} ：<ellipsis-text :text="taskInfo.dataName">{{taskInfo.dataName}}</ellipsis-text>
        </div>
    </div>-->
</template>
<script>
import util from "@/utils/tools";
export default {
    name: 'task-execute-info',
    props: {
        projectInfo: {
            type: Object,
            required: true,
        },
        batchInfo: {
            type: Object,
            required: true,
        },
        stepInfo: {
            type: Object,
            required: true,
        },
        taskInfo: {
            type: Object,
            required: true,
        },
        dataId: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            batchname:'',
        }
    },
    created(){
        this.batchname = util.base64decode(this.batchInfo.name);
    },
};
</script>
<style lang="scss" scoped>
.task-info-wrapper {
    border-radius: 8px 8px 0 0;
    text-align: left;
    .task-info-header {
        border-radius: 8px 8px 0 0;
        background: #ccc;
        padding: 4px 5px;
    }
    .task-items {
        margin: 5px 8px;
    }
    .task-ids-item {
        display: block;
    }
}

.task-items {
        //color: #2d8cf0;
        font-size: 1em;
        padding: 4px;
    }

</style>


