<template>
  <div class="exam-main">
    <div class="task-header" id="task-header">
<!--      <task-info-->
<!--        :projectInfo = "projectInfo"-->
<!--        :batchInfo = "batchInfo"-->
<!--        :stepInfo = "stepInfo"-->
<!--        :taskInfo = "taskInfo"-->
<!--        :dataId="curId" />-->
<!--      <div class="task-btn-group">-->
<!--        <task-progress-->
<!--            :total="taskList.length"-->
<!--            :current="currentTaskIndex"-->
<!--            :timeout="taskTimeout" />-->
<!--        &lt;!&ndash; 挂起 &ndash;&gt;-->
<!--&lt;!&ndash;        <Button type="warning" size="small" @click.native="setDifficult" >&ndash;&gt;-->
<!--&lt;!&ndash;          {{$t('tool_submit_difficult_job')}}&ndash;&gt;-->
<!--&lt;!&ndash;        </Button>&ndash;&gt;-->

<!--        <Button type="primary" size="small" @click.native="reject" :loading="rejectLoading" v-if=" taskInfo.refuse_limit != 1 || (allData[curId].is_refused != '1' && taskInfo.refuse_limit == 1)">{{ $t('task_reject') }}</Button>-->
<!--        <Button type="primary" size="small" @click.native="submit" :loading="loading">{{ $t('task_pass') }}</Button>-->
<!--        &lt;!&ndash; <Button type="warning" size="small" @click.native="reset" :loading="resetLoading">{{ $t('task_reset') }}</Button> &ndash;&gt;-->
<!--        <Button type="warning" size="small" @click.native="exit" :loading="exitLoading">{{$t('tool_quit')}}</Button>-->
<!--      </div>-->

      <div class="task-header-l">
        <div class="task-header-l-tit">{{projectInfo.name}}(ID: {{ projectInfo.id }})</div>
        <div class="task-header-l-info">
          <p>批次ID: {{batchInfo.id}}</p>
          <p>批次名称: {{batchname}}</p>
          <p>执行任务ID: {{ taskInfo.id }}</p>
          <p>作业ID: {{ curId }}</p>
        </div>
        <div class="task-header-l-tip">
          <div>
            <p class="task-header-l-tip-tit">注意事项：</p>
            <Richtxt :isInline="true" :isDisabled="true" :dataObj="{val:projectInfo.attr['description']}"></Richtxt>
          </div>
          <div>
            <p class="task-header-l-tip-tit">任务附件：</p>
            <Button type="primary" v-for="item in projectInfo.attr['attachments']" :key="item.url" @click="checkEnclosure(item.url)">下载</Button>
          </div>
        </div>
      </div>
      <div class="task-header-r">
        <div  class="mb20">
          <Button type="warning" @click.native="exit" class="mr">退回至待完成公共池</Button>
        </div>

        <div>
          <div style="font-size: 16px;font-weight: bold">
            <task-progress
                :total="taskList.length"
                :current="currentTaskIndex"
                :timeout="taskTimeout" />
          </div>
<!--          <div>每分钟自动存储所有作业结果，可手动<p class="task-header-r-storage" @click="temporaryStorage">暂存</p></div>-->
        </div>
      </div>


    </div>
    <div class="cont">
      <div class="cont-box" :style="{position:isFixed? 'fixed' : 'absolute', top:isFixed ? '48px' : '0'}">
        <div class="cont-num">
          <div :class="{active:curDataIndex == index,success: allData[item.data.id] && allData[item.data.id].verify == 1,reject:allData[item.data.id] && allData[item.data.id].verify == 0,reset: allData[item.data.id] && allData[item.data.id].verify == 2,suspend:allData[item.data.id] && allData[item.data.id].verify == 3,invalid:allData[item.data.id] && allData[item.data.id].verify == 4}" v-for="(item,index) in taskList" :key="item.data.id" @click="changeData(index)">第<span>{{index + 1}}</span>题</div>
        </div>
<!--        <div>-->
<!--          <Button style="margin-right: 10px" type="primary" @click="suspend" :loading="suspendLoading" v-if="allData[curId].is_refused != '1'">挂起</Button>-->
<!--&lt;!&ndash;          <Button type="error" @click="invalid" :loading="invalidLoading">无效作业</Button>&ndash;&gt;-->
<!--        </div>-->
      </div>

      <div class="cont-r">
        <div class="exam-des">
          <div class="exam-des-info">
            <div class="exam-des-top">
              <div>
                <span>{{ $t('task_work_tasks') }}:</span>
                <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['工作任务'] }}</span>
              </div>
              <div>
                <span>{{ $t('task_skill_set') }}:</span>
                <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['技能集'] }}</span>
              </div>
              <div>
                <span>{{ $t('task_first_level') }}:</span>
                <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['技能点（一级知识点）'] }}</span>
              </div>
              <div>
                <span>{{ $t('task_question_type') }}:</span>
                <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['题型'] }}</span>
              </div>
              <div>
                <span>{{ $t('task_difficulty_level') }}:</span>
                <span>{{ taskList[curDataIndex].dataResult.data && taskList[curDataIndex].dataResult.data['难度等级'] }}</span>
              </div>
            </div>
            <div class="exam-des-bot">

              <div>
                <span>{{ $t('task_knowledge_points') }}:</span>
                <div>
                  <el-select
                      v-model="allData[curId].nodes"
                      filterable
                      remote
                      disabled
                      :remote-method="(query) => remoteMethod(query)"
                      :loading="knowledgePointLoading"
                      style="width:300px;"
                      @change="changeNodes"
                  >
                    <el-option
                        v-for="item in knowledgePointList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">

                    </el-option>
                  </el-select>
                </div>
              </div>
              <div>
                <span>分类:</span>
                <div>
                  <el-select
                      v-model="allData[curId].categories"
                      multiple
                      filterable
                      remote
                      disabled
                      :remote-method="(query) => remoteMethod(query)"
                      :loading="categoryLoading"
                      style="width:300px;"
                      @change="changeCategory"
                  >
                    <el-option
                        v-for="item in categoryList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div>
                <span>标签:</span>
                <div>
                  <el-select
                      v-model="allData[curId].labels"
                      multiple
                      filterable
                      remote
                      disabled
                      :remote-method="(query) => remoteMethod(query)"
                      :loading="labelLoading"
                      style="width:300px;"
                      @change="changeLabel"
                  >
                    <el-option
                        v-for="item in labelList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 200px;">
            <Button type="primary" class="mr20" @click.native="reject" :loading="rejectLoading" v-if=" taskInfo.refuse_limit != 1 || (allData[curId].is_refused != '1' && taskInfo.refuse_limit == 1)">{{ $t('task_reject') }}</Button>
            <Button type="primary" @click.native="submit" :loading="loading">{{ $t('task_pass') }}</Button>
          </div>
         </div>
        <div class="exam-cont">
          <div class="item">
            <div class="item-l">
              <p>*{{ $t('task_test_title') }}</p>
            </div>
            <div class="item-r">
              <div class="item-r-input">
                <div class="item-r-input-cont">
                  <div class="left" >
                    <Richtxt :dataObj="allData[curId].title" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].title)"></Richtxt>
                  </div>
                  <div class="comment-question">
                    <Input v-model="allData[curId].title.newComment" border type="textarea" placeholder="请在此输入你的看法..." />
                  </div>
                  <div class="previous-comment">
                    <div class="previous-comment-tit">以往评论：</div>
                    <div class="previous-comment-cont">
                      <Timeline>
                        <TimelineItem v-for="item in allData[curId].title.comment" :key="item.time">
                          <div class="info">
                            <p>{{ item.name }}</p>
                            <p>{{ item.time }}</p>
                            <Tag color="primary" style="margin-top: -4px">{{ item.batch }}</Tag>
                          </div>
                          <p class="content color-b">{{ item.comment }}</p>
                        </TimelineItem>
                      </Timeline>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
          <div class="item" v-for="(item,index) in allData[curId].optionList" :key="item.id + 'option'">
            <div class="item-l">
              <p>*{{ item.name }}</p>
<!--              <p :class="item.isCorrect ? 'correct' : ''" @click="changeCorrect(item)">{{item.isCorrect ? $t('task_correct_option') : $t('task_set_correct_option')}}</p>-->
            </div>
            <div class="item-r">
              <div class="item-r-input">
                <div class="item-r-input-cont">
                  <div class="left">
                    <div class="left-top">
                      <div class="data-correct" :class="item.isCorrect ? 'active' : ''" @click="changeCorrect(item)">{{item.isCorrect ? $t('task_correct_option') : $t('task_set_correct_option')}}</div>
                      <div class="data-dele" v-if="index > 3" @click="deleOption(index)">
                        <img src="../../../assets/images/new_icon/delete_icon.png" width="24" height="24">
                      </div>
                    </div>
                    <Richtxt :dataObj="item" :curId="curId" @saveData="(data)=>saveRichData(data,item)"></Richtxt>
                  </div>
                  <div class="comment-question mt44">
                    <Input v-model="item.newComment" border type="textarea" placeholder="请在此输入你的看法..." />
                  </div>
                  <div class="previous-comment mt44">
                    <div class="previous-comment-tit">以往评论：</div>
                    <div class="previous-comment-cont">
                      <Timeline>
                        <TimelineItem v-for="sItem in item.comment" :key="sItem.time">
                          <div class="info">
                            <p>{{ sItem.name }}</p>
                            <p>{{ sItem.time }}</p>
                            <Tag color="primary" style="margin-top: -4px">{{ sItem.batch }}</Tag>
                          </div>
                          <p class="content color-b">{{ sItem.comment }}</p>
                        </TimelineItem>
                      </Timeline>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Button type="primary" class="add-btn" @click="addOption" v-if="questionType.indexOf('判断') == -1">{{ $t('task_add_options') }}</Button>

          <div class="item">
            <div class="item-l">
              <p>{{ $t('task_analysis') }}</p>
            </div>
            <div class="item-r">
              <div class="item-r-input">
                <div class="item-r-input-cont">
                  <div class="left">
                    <Richtxt :dataObj="allData[curId].analysis" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].analysis)"></Richtxt>
                  </div>
                  <div class="comment-question">
                    <Input v-model="allData[curId].analysis.newComment" border type="textarea" placeholder="请在此输入你的看法..." />
                  </div>
                  <div class="previous-comment" >
                    <div class="previous-comment-tit">以往评论：</div>
                    <div class="previous-comment-cont">
                      <Timeline>
                        <TimelineItem v-for="item in allData[curId].analysis.comment" :key="item.time">
                          <div class="info">
                            <p>{{ item.name }}</p>
                            <p>{{ item.time }}</p>
                            <Tag color="primary" style="margin-top: -4px">{{ item.batch }}</Tag>
                          </div>
                          <p class="content color-b">{{ item.comment }}</p>
                        </TimelineItem>
                      </Timeline>
                    </div>
                  </div>

                </div>
              </div>
              <div class="item-r-comment">
              </div>
            </div>
          </div>
        </div>
        <div class="bot">
          <Button type="primary" class="bot-btn" @click="pre">{{ $t('task_pre_question') }}</Button>
          <!--        <Button class="bot-btn" >提交</Button>-->
          <Button type="primary" @click="next">{{ $t('task_next_question') }}</Button>
        </div>
      </div>

    </div>
    <Modal
        v-model="timeoutModal"
        :title="$t('task_task_timed_out')"
        :mask-closable="false"
        :closable="false"
    >
      <div class="timeout-text">{{ $t('task_re_claim') }}</div>
      <div slot="footer">
        <Button type="primary" @click="again">{{ $t('task_get_task_again') }}</Button>

        <Button type="primary" @click="backTask">{{ $t('task_return_to_task') }}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Richtxt from '../richtxt.vue';
import TaskProgress from '../progress.vue';
import TaskInfo from '../taskinfo.vue';

import EventBus from '@/utils/eventBus';
import util from "@/utils/tools";


export default {
  name: "audit",
  props: {
    templateInfo: {
      type: Array,
      default: [],
    },
    taskList: {
      type: Array,
      required: true,
    },
    categoryInfo: {
      type: Object,
      required: true,
    },
    serverTime: {
      type: Number,
      required: true,
    },
    taskInfo: {
      type: Object,
      required: true,
    },
    taskTimeout: {
      type: Number,
      required: true,
    },
    taskStat: {
      type: Object,
      required: true,
    },
    stepInfo: {
      type: Object,
      required: true,
    },
    batchInfo: {
      type: Object,
      required: true,
    },
    projectInfo: {
      type: Object,
      required: true,
    },
  },
  data(){
    return{
      // knowledgePointValue:[],
      isShow:false, //默认收起tab项
      projectTabList:[
        {
          id:1,
          name:this.$t('task_attention'),
          key:'description'
        },
        {
          id:2,
          name:this.$t('task_attachment'),
          key:'attachments'
        },
        // {
        //   id:3,
        //   name:this.$t('task_feedback'),
        // },
      ],
      curProjectTab:{
        id:1,
        name:this.$t('task_attention'),
        key:'description'
      },
      curDataIndex:0,// 当前数据
      cont:'',
      nameList:[this.$t('task_option_e'),this.$t('task_option_f')],
      // taskTimeout: 600,
      currentTaskIndex: 0,
      loading: false,
      taskItemInfo: '',
      taskItemInfoMore: {},
      loadingText: this.$t('tool_loading'),
      userStat: {},
      textPlaceHolderReady: false,
      workedTaskId: {},
      knowledgePoint:[],  //选中的知识点
      knowledgePointList:[],  //知识点列表
      knowledgePointLoading:false,
      questionType:'',
      allData:{},
      curId:'', //当前数据id
      topStatus:true,
      timeoutModal:false,
      rejectLoading:false,  //驳回loading
      resetLoading:false, //重置
      exitLoading:false,  //退出loading
      categoryLoading:false,
      categoryList:[],
      labelLoading:false,
      labelList:[],
      suspendLoading:false,
      invalidLoading:false,
      catchData:{},
      batchname:'',
      isFixed:false,
    }
  },
  components:{
    Richtxt,
    'task-progress': TaskProgress,
    'task-info': TaskInfo,
  },
  computed: {
    dataId () {
      return this.taskList[this.currentTaskIndex].data.id;
    },

  },
  watch:{
    taskList:{
      handler(newV){
        this.init();
      },
      immediate:true
    },
    batchInfo:{
      handler(newV){
        this.batchname = util.base64decode(newV.name);
      },
      immediate:true
    }
  },
  created(){
    this.$emit('componentsLoaded');
    // this.init();
  },
  mounted(){
    document.getElementById('cont').addEventListener('scroll',this.getIsFixed)
  },
  beforeDestroy(){
    document.getElementById('cont').removeEventListener('scroll',this.getIsFixed)
  },
  methods:{
    getIsFixed(){
      let scrollTop = document.getElementById('cont').scrollTop;
      let divH = document.getElementById('task-header').offsetHeight + 80;
      this.isFixed = scrollTop - divH > 0;
    },
    init(){
      EventBus.$on('textFilePlaceholderReady', this.initTask);

      this.curDataIndex = 0;

      this.userStat = this.taskStat;
      this.workedTaskId = {};
      EventBus.$on('task-timeout', this.setTaskTimeout);

      //判断类型  单选多选 默认四个选项 判断题 默认两个选项  不可添加选项
      this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];
      this.curId = this.taskList[this.curDataIndex].data.id;

      //格式化数据
      this.formate();
    },
    formate(){
      this.allData = {};
      this.taskList.forEach((item)=>{
        let name = item.data.id;
        let options = [];
        let names = [this.$t('task_option_a'),this.$t('task_option_b'),this.$t('task_option_c'),this.$t('task_option_d'),this.$t('task_option_e'),this.$t('task_option_f')];

        let result = item.dataResult.result;

        if(result){
          result.info.options.forEach((sItem,index)=>{
            options.push({
              id:sItem.id,
              name:names[index],
              isCorrect:sItem.is_correct,
              val:sItem.name,
              comment:result.feedback ? result.feedback[sItem.id] : [],
              isCheck:false,
              isRich:false,
              placeholder:this.$t('task_option_placeholder'),
              newComment:''
            })
          })

          let title = {
            val:result.info.name,
            comment:result.feedback ? result.feedback.name : [],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_title_placeholder'),
            newComment:''
          };
          let analysis = {
            val:result.info.explain,
            comment:result.feedback ? result.feedback.explain : [],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder'),
            newComment:''
          };
          this.$set(this.allData,name,{});
          this.$set(this.allData[name],'nodes',result.info.nodes);
          this.$set(this.allData[name],'categories',result.info.categories);
          this.$set(this.allData[name],'labels',result.info.labels);

          this.$set(this.allData[name],'title',title);
          this.$set(this.allData[name],'analysis',analysis);
          this.$set(this.allData[name],'optionList',options);
          this.$set(this.allData[name],'id',result.info.id);
          this.$set(this.allData[name],'type',result.info.type);
          this.$set(this.allData[name],'verify',-1);  //-1 没状态 0 驳回 1通过 2重置
        }
        if(item.work){
          this.$set(this.allData[name],'is_refused',item.work.is_refused);  //1 被驳回的
        }

      })

      this.catchData = JSON.parse(JSON.stringify(this.allData));

      let nodeIds = this.taskList[0].dataResult.result.info.nodes;
      this.getKnowledgePoint(nodeIds,'ids');

      let categoresIds = this.taskList[0].dataResult.result.info.categories;
      this.getCategory(categoresIds,'ids');

      let labelsIds = this.taskList[0].dataResult.result.info.labels;
      this.getLabel(labelsIds,'ids');

    },
    setTaskTimeout () {
      console.log('22222++++++')
      this.timeoutModal = true;
    },
    saveTaskResult (next = true, verify = 1) { //verify 0驳回,1通过2重置  3 挂起 4 无效作业
      if (this.loading) {
        return;
      }

      let result = {};


      let feedback = {};
      this.allData[this.curId].optionList.forEach((item)=>{
        if(item.newComment){
          let commentData = item.comment && item.comment.length ? JSON.parse(JSON.stringify(item.comment)) : [];

          commentData.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:item.newComment,
          })
          feedback[item.id] = JSON.stringify(commentData);
        }else{
          if(item.comment && item.comment.length){
            feedback[item.id] = JSON.stringify(item.comment);
          }
        }
      });
      if(this.allData[this.curId].title.newComment){
        let commentData = this.allData[this.curId].title.comment && this.allData[this.curId].title.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].title.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].title.newComment,
        })
        feedback.name = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].title.comment && this.allData[this.curId].title.comment.length){
          feedback.name = JSON.stringify(this.allData[this.curId].title.comment);
        }
      }
      if(this.allData[this.curId].analysis.newComment){

        let commentData = this.allData[this.curId].analysis.comment && this.allData[this.curId].analysis.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].analysis.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].analysis.newComment,
        })
        feedback.explain = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].analysis.comment && this.allData[this.curId].analysis.comment.length){
          feedback.explain = JSON.stringify(this.allData[this.curId].analysis.comment);
        }
      }





      let len = this.allData[this.curId].optionList.filter((item)=>{
        return !item.val;
      }).length;
      let correctStatus = this.allData[this.curId].optionList.filter((item)=>{
        return item.isCorrect;
      }).length;
      if(!this.allData[this.curId].suspend && !this.allData[this.curId].invalid){
        if(len > 0 || !this.allData[this.curId].title.val || !correctStatus){
          this.$Message.warning('数据未填写完整');
          return;

        }
      }

      //驳回数据如果未更新内容不可以提交
      if(this.allData[this.curId].is_refused == '1'){ //驳回数据
        let isModify = false;
        console.log(this.allData[this.curId],this.catchData[this.curId],'=======')
        if(this.allData[this.curId].title.val != this.catchData[this.curId].title.val || this.allData[this.curId].analysis.val != this.catchData[this.curId].analysis.val){
          isModify = true;
        }
        if(this.allData[this.curId].optionList.length != this.catchData[this.curId].optionList.length){
          isModify = true;
        }else{
          this.allData[this.curId].optionList.forEach((item,index)=>{
            if(item.val != this.catchData[this.curId].optionList[index].val || item.isCorrect !=this.catchData[this.curId].optionList[index].isCorrect){
              isModify = true;
            }
          })
        }
        if(!isModify){
          this.$Message.warning('请修改后提交～');
          return;
        }
      }




      let options = this.allData[this.curId].optionList.map((item)=>{
        return {
          "id":item.id,
          "name":item.val,
          "is_correct":item.isCorrect
        }
      })


      if(verify == 1){  //通过




        result[this.curId] = {
          "verify":{
            "verify":1,
            "correct_work_id":this.taskList[this.curDataIndex].parentWorks[0].id,
          },"is_difficult":0,
          "feedback":feedback,
          "info":{
            "type":"question",
            "id":this.allData[this.curId].id,
            "name" : this.allData[this.curId].title.val,
            "options" : options,
            "explain":this.allData[this.curId].analysis.val,
            "nodes":this.allData[this.curId].nodes,
            "categories":this.allData[this.curId].categories,
            "labels":this.allData[this.curId].labels,
          }
        };
      }else if(verify == 0){ //驳回

        let len = Object.keys(feedback);
        console.log('len',len)
        if(!len.length){
          this.$Message.warning(this.$t('task_enter_comment'));
          return;
        }
        result[this.curId] = {
          "verify":{
            "verify":0
          },
          "feedback":feedback,
          "is_difficult":0,
          "info":{
            "type":"question",
            "id":this.allData[this.curId].id,
            "name" : this.allData[this.curId].title.val,
            "options" : options,
            "explain":this.allData[this.curId].analysis.val,
            "nodes":this.allData[this.curId].nodes,
            "categories":this.allData[this.curId].categories,
            "labels":this.allData[this.curId].labels,
          }
        }
      }else if(verify == 2){  //重置
        result[this.curId] = {
          "verify":{
            "verify":2
          },"is_difficult":0,
          "feedback":feedback,
        }
      }else if(verify == 3){  //挂起
        result[this.curId] = {
          "is_difficult":1,
          "feedback":feedback,
        }
      }else if(verify == 4){  //无效作业
        result[this.curId] = {
          "is_invalid":1,
          "feedback":feedback,
        }
      }


      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'submit',
      };
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      if (!reqData.project_id || !reqData.task_id ) {
        return;
      }
      if(verify == 1){  //通过
        this.loading = true;
      }else if(verify == 0){  //驳回
        this.rejectLoading = true;
      }else if(verify == 2){  //重置
        this.resetLoading = true;
      }else if(verify == 3){  //挂起
        this.suspendLoading = true;
      }else if(verify == 4){  //无效作业
        this.invalidLoading = true;
      }

      this.api.task.taskExecute(reqData).then((res)=>{

        if(verify == 1){  //通过
          this.loading = false;
          this.allData[this.curId].verify = 1;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('tool_submit_success'),
            duration: 1,
          });
        }else if(verify == 0){  //驳回
          this.rejectLoading = false;
          this.allData[this.curId].verify = 0;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('task_reject_success'),
            duration: 1,
          });
        }else if(verify == 2){  //重置
          this.resetLoading = false;
          this.allData[this.curId].verify = 2;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('task_reset_success'),
            duration: 1,
          });
        }else if(verify == 3){  //挂起
          this.suspendLoading = false;
          this.allData[this.curId].verify = 3;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('挂起成功'),
            duration: 1,
          });
        }else if(verify == 4){  //无效作业
          this.invalidLoading = false;
          this.allData[this.curId].verify = 4;
          this.next(1);
          this.$Message.destroy();
          this.$Message.success({
            content: this.$t('无效作业操作成功'),
            duration: 1,
          });
        }


        // EventBus.$emit('perform-fetchTask');
      }).catch((err)=>{
        if(verify == 1){  //通过
          this.loading = false;
        }else if(verify == 0){  //驳回
          this.rejectLoading = false;
        }else if(verify == 2){  //重置
          this.resetLoading = false;
        }else if(verify == 3){  //挂起
          this.suspendLoading = false;
        }else if(verify == 4){  //无效作业
          this.invalidLoading = false;
        }
      })
    },
    // setDifficult () {
    //   this.saveTaskResult(true, 1);
    // },
    submit () {
      this.saveTaskResult(true, 1);
    },
    reset () {
      this.saveTaskResult(true, 2);
    },
    reject () {
      this.saveTaskResult(true, 0);
    },
    suspend () {
      this.saveTaskResult(true, 3);
    },
    invalid(){
      this.saveTaskResult(true, 4);
    },
    changeProjectTab(data){
      this.curProjectTab = data;
    },
    changeData(index){
      console.log(this.allData,this.taskList,index);
      console.log(this.allData[this.taskList[index].data.id],'this.allData[this.taskList[index].data.id]1111')
      console.log(this.allData[this.taskList[index].data.id],this.allData[this.taskList[index].data.id].verify,'this.allData[this.taskList[index].data.id].verify')
      if(this.allData[this.taskList[index].data.id].verify != -1){
        this.$Message.warning(this.$t('task_disabled_operated'));
        return;
      }else{
        this.curDataIndex = index;
        this.curId = this.taskList[this.curDataIndex].data.id;
        // this.knowledgePointValue = this.allData[this.curId].nodes;
        let nodeIds = this.taskList[this.curDataIndex].dataResult.result.info.nodes;
        this.getKnowledgePoint(nodeIds,'ids');

        let categoresIds = this.taskList[this.curDataIndex].dataResult.result.info.categories;
        this.getCategory(categoresIds,'ids');

        let labelsIds = this.taskList[this.curDataIndex].dataResult.result.info.labels;
        this.getLabel(labelsIds,'ids');
      }
    },
    next(num){
      console.log("next start")
      console.log(this.allData)

      //计算剩余作业
      let allowDataIds = [];
      this.taskList.forEach((item, index)=>{
          console.log(index)
          if (this.allData[this.taskList[index].data.id].verify == -1){
            allowDataIds.push(this.taskList[index].data.id);
          }
      });
      console.log(allowDataIds, allowDataIds.length)

      //没有剩余作业, 自动领取
      if (allowDataIds.length < 1) {
        console.log("againGetTask")
        this.$emit('againGetTask')
        return;
      }

      // if(this.curDataIndex == this.taskList.length -1){
      //   this.$Message.warning(this.$t('task_last_data'));
      //   //if(num == 1){ //判断是否重新领取 如果为1是提交 判断是否都做完了  都做完了重新领取
      //     let len = 0;
      //     for(let name in this.allData){
      //       if(this.allData[name].verify == -1){
      //         len++;
      //       }
      //     }
      //     if(!len){ //全部做完了 重新领取
      //       //提交后重新领取任务 如果有任务继续做  没有返回上一级
      //       this.$emit('againGetTask')
      //     }
      //   //}
      // }else{

        if (this.curDataIndex >= this.taskList.length - 1) {
          console.log(this.curDataIndex,this.taskList.length,'next-to 0')
          this.curDataIndex = 0;
        } else {
          this.curDataIndex += 1
          console.log(this.curDataIndex)
        }

        if(this.allData[this.taskList[this.curDataIndex].data.id].verify != -1){
          this.curId = this.taskList[this.curDataIndex].data.id;
          // this.knowledgePointValue = this.allData[this.curId].nodes;
          this.next();
        }else{
          this.curId = this.taskList[this.curDataIndex].data.id;
          // this.knowledgePointValue = this.allData[this.curId].nodes;

          let nodeIds = this.taskList[this.curDataIndex].dataResult.result.info.nodes;
          console.log(this.allData,'+++++++++++++++++++++')
          this.getKnowledgePoint(nodeIds,'ids');

          let categoresIds = this.taskList[this.curDataIndex].dataResult.result.info.categories;
          this.getCategory(categoresIds,'ids');

          let labelsIds = this.taskList[this.curDataIndex].dataResult.result.info.labels;
          this.getLabel(labelsIds,'ids');
        }

      console.log("next end")
      // }
    },
    pre(){
      console.log("pre start")
      //计算剩余作业
      let allowDataIds = [];
      this.taskList.forEach((item, index)=>{
          if (this.allData[this.taskList[index].data.id].verify == -1){
            allowDataIds.push(this.taskList[index].data.id);
          }
      });
      console.log(allowDataIds)

      //没有剩余作业, 自动领取
      if (allowDataIds.length < 1) {
        console.log("againGetTask")
        this.$emit('againGetTask')
        return;
      }

      if (this.curDataIndex == 0) {
        console.log(this.curDataIndex,this.taskList.length,'next-to end')
        this.curDataIndex = this.taskList.length - 1;
      } else {
        this.curDataIndex -= 1;
        console.log(this.curDataIndex)
      }

      if(this.allData[this.taskList[this.curDataIndex].data.id].verify != -1){
        this.curId = this.taskList[this.curDataIndex].data.id;
        // this.knowledgePointValue = this.allData[this.curId].nodes;
        this.pre();
      }else{
        this.curId = this.taskList[this.curDataIndex].data.id;
        // this.knowledgePointValue = this.allData[this.curId].nodes;
        let nodeIds = this.taskList[this.curDataIndex].dataResult.result.info.nodes;
        console.log(this.allData,'+++++++++++++++++++++')
        this.getKnowledgePoint(nodeIds,'ids');

        let categoresIds = this.taskList[this.curDataIndex].dataResult.result.info.categories;
        this.getCategory(categoresIds,'ids');

        let labelsIds = this.taskList[this.curDataIndex].dataResult.result.info.labels;
        this.getLabel(labelsIds,'ids');
      }

      console.log("pre end")
    },
    remoteMethod(query){ //模糊搜索
      if (query !== '') {
        this.getKnowledgePoint(query);
      } else {
        this.knowledgePointList = [];
      }
    },
    getKnowledgePoint(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };
      if(type){
        pramas[type] = data;
      }else{
        pramas.keyword = data;
      }

      this.knowledgePointLoading = true;
      this.api.dataset.datasetNodeList(pramas).then((res)=>{
        this.knowledgePointLoading = false;

        this.knowledgePointList = res.list;
      }).catch((err)=>{
        this.knowledgePointLoading = false;
      })
    },
    showTitleRich(){
      this.closeRich();
      this.title.isRich = true;
    },
    showAnalysisRich(){
      this.closeRich();
      this.analysis.isRich = true;
    },
    changeCorrect(data){
      // if(this.allData[this.curId].is_refused != '1') return;
      if(!data.isCorrect){
        let correctLen = this.allData[this.curId].optionList.filter((item)=>{
          return item.isCorrect;
        }).length;
        //如果是单选 或者 判断题 只能有一个正确选项  多选不能全选
        if(this.questionType.indexOf('单选') != -1 || this.questionType.indexOf('判断') != -1){
          if(correctLen >= 1){
            this.$Message.warning(this.$t('task_selected_one'));
            return;
          }
        }else{  //多选
          if(correctLen >= this.allData[this.curId].optionList.length - 1){
            this.$Message.warning(this.$t('task_disabled_all'));
            return;
          }
        }
      }
      data.isCorrect = !data.isCorrect;
    },
    saveRichData(curData,data){ //同步富文本数据
      data.val = curData;
    },
    changeNodes(data){
      this.allData[this.curId].nodes = data;
    },
    changeCategory(data){
      this.allData[this.curId].categories = data;
    },
    changeLabel(data){
      this.allData[this.curId].labels = data;
    },

    exit () {
      let len = 0;
      for(let name in this.allData){
        if(this.allData[name].verify == -1){
          len++;
        }
      }
      this.$Modal.confirm({
        title: this.$t('tool_exit_confirmation'),
        content: this.$t('tool_exit_description', {num: len}),
        loading: true,
        onOk: () => {
          this.clearTask();
        }
      });
    },
    clearTask () {
      EventBus.$emit('needConfirmLeave', false);

      let params = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        op: 'clear',
      }
      if(this.$route.query.data_id){
        params.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        params.user_id = this.$route.query.user_id;
      }

      this.exitLoading = true;
      this.api.task.taskExecute(params).then((res)=>{
        this.exitLoading = false;
        this.$Modal.remove();
        this.$store.commit('removeTag', 'perform-task');


        this.$router.go(-1);
      }).catch((err)=>{
        this.exitLoading = false;
      })
    },
    again(){
      this.$emit('againGetTask')
    },
    backTask(){
      this.$router.go(-1)
    },
    getCategory(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.categoryLoading = true;
      this.api.dataset.categoryList(pramas).then((res)=>{
        this.categoryLoading = false;

        this.categoryList = res.list;
      }).catch((err)=>{
        this.categoryLoading = false;
      })
    },
    getLabel(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.labelLoading = true;
      this.api.dataset.labelList(pramas).then((res)=>{
        this.labelLoading = false;

        this.labelList = res.list;
      }).catch((err)=>{
        this.labelLoading = false;
      })
    },
    checkEnclosure(data){
      // let suffixArr = data.split('.');
      // let suffix = suffixArr[suffixArr.length -1];
      // if(suffix == 'pdf'){
      //   window.open(data,'_blank')
      // }else{
      //   let fileUrl=encodeURIComponent(data)
      //   //使用Office Web查看器
      //   let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src='+fileUrl
      //   window.open(officeUrl,'_target');
      // }
      util.downloadFile(this,data);
    },
    addOption(){  //添加选项
      if(this.allData[this.curId].optionList.length < 6){
        this.allData[this.curId].optionList.push({
          id:this.$uuid.v1(),
          name:this.nameList[this.allData[this.curId].optionList.length - 4],
          isCorrect:false,
          val:'',
          placeholder:''
        })
      }else{
        this.$Message.warning(this.$t('task_add_max'));
      }
    },
    deleOption(index){ //删除选项
      this.allData[this.curId].optionList.splice(index,1);
    },
  }
}
</script>

<style scoped lang="scss">
.exam-main{
  margin: 20px;
  .task-header{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    .task-header-l{
      flex: 1;
      width: 0;
      .task-header-l-tit{
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: bold;
      }
      .task-header-l-info{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        >p{
          margin-right: 20px;
        }
      }
      .task-header-l-tip{
        //padding: 20px;
        //background-color: #f8f8f9;
        display: flex;
        justify-content: flex-start;
        >div{
          padding: 20px;
          background-color: #f8f8f9;
        }
        >div:first-child{
          margin-right: 10px;
          width: 70%;
        }
        .task-header-l-tip-tit{
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
    .task-header-r{
      width: 300px;
      text-align: right;
      .task-header-r-storage{
        display: inline-block;
        color:#2db7f5;
        cursor: pointer;
      }
    }
  }
  .cont{
    margin-top: 20px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    position: relative;

    .cont-tit{
      font-weight: bold;
      font-size: 16px;
    }
    .cont-box{
      margin-right: 20px;
      width: 110px;
      //position:sticky;

    }
    .cont-num{
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 4px;

      >div{
        margin: 8px 0;
        font-size: 16px;
        color:#333333;
        cursor: pointer;
        >span{
          display: inline-block;
          width: 36px;
          text-align: center;
        }
      }
      .active{
        color: #2d8cf0;
        font-weight: bold;
      }
      .success{
        color: #19be6b;
      }
      .reject{
        color: #ed4014;
      }
      .reset{
        color: #ff9900;
       }
      .suspend{
        color: #c5c8ce;
      }
      .invalid{
        color:red;
      }
    }
    .cont-r{
      margin-left:130px ;
      flex:1;
      width: 0;
      padding: 20px;
      background: #FFFFFF;
      box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 4px;
      font-size: 14px;
    }
    //.exam-num{
    //  margin: 20px 0;
    //  font-size: 22px;
    //  font-weight: bold;
    //  color:#000000;
    //  >span{
    //    color: rgb(30, 130, 242);
    //  }
    //}
    .exam-des{
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      .exam-des-info{
        flex: 1;
        width: 0;
      }

      .exam-des-top{
        margin-bottom: 20px;
        padding: 20px 20px 0 20px;
        background: #f8f8f9;
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 80%;
        >div{
          width: 30%;
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
      .exam-des-bot{
        >div{
          margin-bottom: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          >span{
            margin-right: 20px;
            font-weight: bold;
            width: 100px;
            text-align: right;
          }
        }
      }

      .knowledge-point{
        width: 300px;
        border:1px solid #dcdee2;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .no-bor{
          flex: 1;
          border: none;
          min-width: 20px;

          input{
            border:none !important;
            background-color: transparent !important;
          }
        }
        >input:active,>input:hover{
          border:none;
        }
        >input[type=text]:focus{
          outline:none;
        }
      }
    }
    .exam-cont{
      margin-top: 20px;
      .item{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;

        .item-l{
          width: 100px;
          text-align: right;
          font-weight: bold;

        }
        .left-top{
          height: 34px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .data-correct{
            width: 95px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #999999;
            border-radius: 4px;
            font-weight: bold;
            text-align: center;
            line-height: 34px;
            box-sizing: border-box;
            cursor: pointer;
          }
          .active{
            background: #54C99F;
            color: #FFFFFF !important;
            border: none;
          }
          .data-dele{
            cursor: pointer;
          }
        }
        .item-r{
          margin-left: 20px;
          flex: 1;

          .item-r-input{
            min-height: 80px;

            //border:1px solid #dcdee2;
            border-radius: 4px;
            position: relative;

            .item-r-input-cont{
              display: flex;
              justify-content: flex-start;
              min-height: 80px;
              .left{
                flex: 1;
                //padding: 10px;
              }
            }
            .btn{
              display: flex;
              flex-flow: column;
              position: absolute;
              right: 10px;
              top: 10px;
            }


          }
          .item-r-input.correct{
            border:2px solid #2d8cf0;
          }
          .item-r-comment{
            margin-top: 10px;
            margin-bottom: 10px;
            color:red;
            line-height: 22px;
          }

        }

      }
      .add-btn{
        margin-bottom: 20px;
      }
      .comment-question{
        margin: 0 20px;
      }
      .previous-comment{
        padding:20px 0;
        width: 360px;
        height: 300px;
        background: #f8f8f9;
        font-size: 14px;

        .previous-comment-tit{
          padding:0 20px;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .previous-comment-cont{
          padding-top: 10px;
          padding-left: 20px;
          height: 240px;
          overflow-y: auto;
          .info{
            //margin-top: -5px;
            display: flex;
            justify-content: flex-start;
            //align-items: center;
            >p:nth-child(2){
              margin: 0 20px;
            }
          }
          .color-b{
            color:#2d8cf0;
          }
        }
      }
    }
    .bot{
      margin-top: 40px;
      margin-bottom: 40px;
      padding-top: 20px;
      border-top: 1px solid #dcdee2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .bot-btn{
        margin-right: 20px;
      }
    }
  }

}
.mr{
  margin-right: 10px;
}
.timeout-text{
  font-size: 14px;
}
.mt44{
  margin-top: 44px!important;
}
</style>
<style lang="scss">
.no-bor{
  flex: 1;
  border: none;
  min-width: 20px;

  input,
  input:focus,
  input:active,
  input:hover{
    border:none !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}
.comment{
  .ivu-input{
    height: 235px !important;
    width: 100% !important;
    border:none !important;
  }
}
</style>
