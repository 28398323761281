<template>
<div class="translate">
  <div class="translate-top" id="task-header1">
    <task-progress
        :total="taskList.length"
        :current="curDataIndex"
        :timeout="taskTimeout" />
<!--    <div>-->
<!--      <p>匹配度颜色标记：</p>-->
<!--      <div class="green">50% - 70%</div>-->
<!--      <div class="purple">70% - 90%</div>-->
<!--      <div class="yellow">90% - 100%</div>-->
<!--    </div>-->

  </div>
  <div class="translate-cont">
    <div class="translate-cont-num" :style="{position:isFixed? 'fixed' : 'absolute', top:isFixed ? '48px' : '0'}">
      <p :class="{active : curDataIndex == index}" v-for="(item,index) in taskList" :key="item.data.id" @click="changeData(index)"><span>{{index + 1}}</span></p>

<!--      <p class="active">1</p>-->
<!--      <p>2</p>-->
<!--      <p>3</p>-->
    </div>
    <div v-if="!dictLoading" class="translate-cont-nr">
      <div class="translate-cont-translate" v-if="allData[curId] && allData[curId].translate">
        <div>
          <p class="tit">原文</p>
          <div>
            <div class="sub-tit">试题标题</div>
            <div class="translate-cont-nr-item">
              <div class="translate-cont-nr-item-china" ref="original_name">
                {{allData[curId].original.name}}
              </div>
              <div style="opacity: 0;white-space: pre-wrap;" >
                {{allData[curId].translate.title.val}}
              </div>
            </div>
          </div>
          <div v-for="(item,index) in allData[curId].original.options" :key="item.id" >
            <div class="sub-tit">选项{{ originalNameList[index] }}<span v-if="item.is_correct">(正确选项)</span></div>
            <div class="translate-cont-nr-item">
              <div class="translate-cont-nr-item-china" :id="'original_options' + index">
                {{item.name}}
              </div>
              <div style="opacity: 0;white-space: pre-wrap;" >
                {{allData[curId].translate.optionList[index].val}}
              </div>
            </div>
          </div>
          <div v-if="allData[curId].original.explain">
            <div class="sub-tit">解析</div>
            <div class="translate-cont-nr-item">
              <div class="translate-cont-nr-item-china" ref="original_explain">
                {{allData[curId].original.explain}}
              </div>
              <div style="opacity: 0;white-space: pre-wrap;" >
                {{allData[curId].translate.analysis.val}}
              </div>
            </div>
          </div>
          <div v-if="allData[curId].original.learning_outcomes">
            <div class="sub-tit">知识点</div>
            <div class="translate-cont-nr-item">
              <div class="translate-cont-nr-item-china" ref="original_learning_outcomes">
                {{allData[curId].original.learning_outcomes}}
              </div>
              <div style="opacity: 0;white-space: pre-wrap;" >
                {{allData[curId].translate.learning_outcomes.val}}
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="tit">译文</p>
          <div>
            <div class="sub-tit">Test title</div>
            <div class="translate-cont-nr-item" :class="allData[curId].translate.title.comment.length ? 'borderR' : ''">

              <Input class="translate-cont-nr-item-textarea" v-model="allData[curId].translate.title.val" type="textarea" :autosize="{minRows:1}" :border="false" placeholder="请输入" @on-blur="formateDict('name')" />
              <div class="translate-cont-nr-item-en" ref="translate_name">
                {{allData[curId].translate.title.val}}
              </div>
              <div class="check-comment" v-if="allData[curId].translate.title.comment && allData[curId].translate.title.comment.length">
<!--              <div class="check-comment" >-->
              <Poptip word-wrap width="240" v-model="allData[curId].translate.title.commentModal" placement="right-start">
                  <p class="check-comment-btn">查看评论{{allData[curId].translate.title.commentModal}}</p>
                  <div slot="content" class="comment-list">
                    <div class="comment-close"  @click="allData[curId].translate.title.commentModal = false">
                      <img src="../../../assets/images/new_icon/guanbi.png" width="15" height="15" />
                    </div>
                    <div style="max-height: 300px;overflow-y: auto">
                      <div class="comment-list-item" v-for="sItem in allData[curId].translate.title.comment" :key="sItem.time">
                        <div class="comment-list-item-tit">
                          <p>{{ sItem.name }}</p>
                          <p>{{ sItem.batch }}</p>
                        </div>
                        <div class="comment-list-item-time">{{ sItem.time }}</div>
                        <div class="comment-list-item-desc">{{ sItem.comment }}</div>
                      </div>
                    </div>

                    <div class="comment-input">
                      <Input v-model="allData[curId].translate.title.newComment" type="textarea" :border="false" placeholder="请输入评论..." />
                      <Button type="primary" size="small" @click="temporaryStorage('title')">确定</Button>
                    </div>
                  </div>
                </Poptip>
              </div>
            </div>
          </div>
          <div v-for="(item,index) in allData[curId].translate.optionList" :key="item.id">
            <div class="sub-tit">{{ item.name }}<span v-if="item.isCorrect">(正确选项)</span></div>
            <div class="translate-cont-nr-item" :class="item.comment.length ? 'borderR' : ''">

              <Input class="translate-cont-nr-item-textarea" v-model="item.val" type="textarea" :autosize="{minRows:1}" :border="false" placeholder="请输入" @on-blur="formateDict('option',index)" />
              <div class="translate-cont-nr-item-en" :id="'translate_options' + index">
                {{item.val}}
              </div>
              <div class="check-comment" v-if="item.comment && item.comment.length">
                <Poptip word-wrap width="240" v-model="item.commentModal" placement="right-start">
                  <p class="check-comment-btn">查看评论</p>
                  <div slot="content" class="comment-list">
                    <div class="comment-close"  @click="item.commentModal = false">
                      <img src="../../../assets/images/new_icon/guanbi.png" width="15" height="15" />
                    </div>
                    <div style="max-height: 300px;overflow-y: auto">
                      <div class="comment-list-item" v-for="sItem in item.comment" :key="sItem.time">
                        <div class="comment-list-item-tit">
                          <p>{{ sItem.name }}</p>
                          <p>{{ sItem.batch }}</p>
                        </div>
                        <div class="comment-list-item-time">{{ sItem.time }}</div>
                        <div class="comment-list-item-desc">{{ sItem.comment }}</div>
                      </div>
                    </div>

                    <div class="comment-input">
                      <Input v-model="item.newComment" type="textarea" :border="false" placeholder="请输入评论..." />
                      <Button type="primary" size="small" @click="temporaryStorage('option',index)">确定</Button>
                    </div>
                  </div>
                </Poptip>
              </div>
            </div>
          </div>
          <div v-if="allData[curId].original.analysis">
            <div class="sub-tit">Ans</div>
            <div class="translate-cont-nr-item">

              <Input class="translate-cont-nr-item-textarea" v-model="allData[curId].translate.analysis.val" type="textarea" :autosize="{minRows:1}" :border="false" placeholder="请输入" @on-blur="formateDict('explain')" />
              <div class="translate-cont-nr-item-en" ref="translate_explain">
                {{allData[curId].translate.analysis.val}}
              </div>
              <div class="check-comment" v-if="allData[curId].translate.analysis.comment && allData[curId].translate.analysis.comment.length">
                <Poptip word-wrap width="240" v-model="allData[curId].translate.analysis.commentModal" placement="right-start">
                  <p class="check-comment-btn">查看评论</p>
                  <div slot="content" class="comment-list">
                    <div class="comment-close"  @click="allData[curId].translate.analysis.commentModal = false">
                      <img src="../../../assets/images/new_icon/guanbi.png" width="15" height="15" />
                    </div>
                    <div style="max-height: 300px;overflow-y: auto">
                      <div class="comment-list-item" v-for="sItem in allData[curId].translate.analysis.comment" :key="sItem.time">
                        <div class="comment-list-item-tit">
                          <p>{{ sItem.name }}</p>
                          <p>{{ sItem.batch }}</p>
                        </div>
                        <div class="comment-list-item-time">{{ sItem.time }}</div>
                        <div class="comment-list-item-desc">{{ sItem.comment }}</div>
                      </div>
                    </div>
                    <div class="comment-input">
                      <Input v-model="allData[curId].translate.analysis.newComment" type="textarea" :border="false" placeholder="请输入评论..." />
                      <Button type="primary" size="small" @click="temporaryStorage('explain')">确定</Button>
                    </div>
                  </div>
                </Poptip>
              </div>
            </div>
          </div>
          <div v-if="allData[curId].original.learning_outcomes">
            <div class="sub-tit">Learning Outcomes</div>
            <div class="translate-cont-nr-item" :class="allData[curId].translate.learning_outcomes.comment.length ? 'borderR' : ''">
              <Input class="translate-cont-nr-item-textarea" v-model="allData[curId].translate.learning_outcomes.val" type="textarea" :autosize="{minRows:1}" :border="false" placeholder="请输入" @on-blur="formateDict('learning_outcomes')" />
              <div class="translate-cont-nr-item-en" ref="translate_learning_outcomes">
                {{allData[curId].translate.learning_outcomes.val}}
              </div>
              <div class="check-comment" v-if="allData[curId].translate.learning_outcomes.comment && allData[curId].translate.learning_outcomes.comment.length">
                <Poptip word-wrap width="240" v-model="allData[curId].translate.learning_outcomes.commentModal" placement="right-start">
                  <p class="check-comment-btn">查看评论</p>
                  <div slot="content" class="comment-list">
                    <div class="comment-close"  @click="allData[curId].translate.learning_outcomes.commentModal = false">
                      <img src="../../../assets/images/new_icon/guanbi.png" width="15" height="15" />
                    </div>
                    <div style="max-height: 300px;overflow-y: auto">
                      <div class="comment-list-item" v-for="sItem in allData[curId].translate.learning_outcomes.comment" :key="sItem.time">
                        <div class="comment-list-item-tit">
                          <p>{{ sItem.name }}</p>
                          <p>{{ sItem.batch }}</p>
                        </div>
                        <div class="comment-list-item-time">{{ sItem.time }}</div>
                        <div class="comment-list-item-desc">{{ sItem.comment }}</div>
                      </div>
                    </div>
                    <div class="comment-input">
                      <Input v-model="allData[curId].translate.learning_outcomes.newComment" type="textarea" :border="false" placeholder="请输入评论..." />
                      <Button type="primary" size="small" @click="temporaryStorage('learning_outcomes')">确定</Button>
                    </div>
                  </div>
                </Poptip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="translate-cont-nr-btn">
        <Button type="primary" @click="pre">上一题</Button>
        <Button type="primary" @click="next">下一题</Button>
<!--        <Button type="error">Primary</Button>-->

        <Button v-if="allData[curId] && allData[curId].submit">已提交</Button>
        <Button v-else type="success" @click="submit">确定</Button>
      </div>
    </div>
    <div v-show="dictLoading" class="translate-cont-nr"  style="position: relative;min-height: 600px">
      <Spin fix></Spin>
    </div>
    <div class="translate-cont-corpus" :style="{position:isFixed? 'fixed' : 'absolute', top:isFixed ? '48px' : '0',right:isFixed ? '20px' : '0', height: isFixed ? 'calc(100% - 50px)' : '100%'}">
      <p class="tit">平台语料库</p>
      <div class="translate-cont-corpus-search">
        <Input v-model="keyword" placeholder="请输入" class="translate-cont-corpus-search-inp" @on-enter="getDictList"/>
        <img src="../../../assets/images/new_icon/sousuo.png" width="18" height="18" @click="getDictList" />
      </div>
      <div v-for="item in dictList" :key="item.id">
        <div class="translate-cont-corpus-name">{{ item.name }}</div>
        <div class="translate-cont-corpus-en">{{item.value}}</div>
        <div class="translate-cont-corpus-desc" v-if="item.remarks">{{item.remarks}}</div>
        <div class="translate-cont-corpus-ratio purple" @click="copy(item.value)">复制</div>
        <div class="fgx"></div>
      </div>
    </div>
  </div>
  <Modal
      v-model="timeoutModal"
      :title="$t('task_task_timed_out')"
      :mask-closable="false"
      :closable="false"
  >
    <div class="timeout-text">{{ $t('task_re_claim') }}</div>
    <div slot="footer">
      <Button type="primary" @click="again">{{ $t('task_get_task_again') }}</Button>

      <Button type="primary" @click="backTask">{{ $t('task_return_to_task') }}</Button>
    </div>
  </Modal>
</div>
</template>

<script>
import TaskProgress from '../progress.vue';
import EventBus from '@/utils/eventBus';
import util from "@/utils/tools";
export default {
  name: "produce",
  data(){
    return {

      curDataIndex:0,// 当前数据
      cont:'',
      // taskTimeout: 600,
      loading: false,
      taskItemInfo: '',
      taskItemInfoMore: {},
      loadingText: this.$t('tool_loading'),
      nameList:[this.$t('task_option_e'),this.$t('task_option_f')],
      originalNameList:['A','B','C','D','E','F'],
      userStat: {},
      textPlaceHolderReady: false,
      // workedTaskId: {},
      questionType:'',
      allData:{},
      curId:'', //当前数据id
      exitLoading:false,
      timeoutModal:false,
      sec:60,
      timer:null,
      storageLoading:false,
      verificationModal:false,  //校验数据弹窗
      incompleteData:[],
      suspendLoading:false,
      invalidLoading:false,
      richTxtLoading:false,
      catchData:{},
      batchname:'',
      isFixed:false,
      keyword:'',
      dictList:[],
      dictLoading:false,
    }
  },
  props: {
    templateInfo: {
      type: Array,
      default: [],
    },
    taskList: {
      type: Array,
      required: true,
    },
    categoryInfo: {
      type: Object,
      required: true,
    },
    serverTime: {
      type: Number,
      required: true,
    },
    taskInfo: {
      type: Object,
      required: true,
    },
    taskTimeout: {
      type: Number,
      required: true,
    },
    taskStat: {
      type: Object,
      required: true,
    },
    stepInfo: {
      type: Object,
      required: true,
    },
    batchInfo: {
      type: Object,
      required: true,
    },
    projectInfo: {
      type: Object,
      required: true,
    },
  },
  watch:{
    taskList:{
      handler(newV){
        console.log(newV,'newVnewVnewV-----------------')
        this.init();
      },
      immediate:true
    },
    batchInfo:{
      handler(newV){
        // this.batchname = util.base64decode(newV.name);
      },
      immediate:true
    }
  },
  components:{
    TaskProgress
  },
  mounted(){
    //suspend 挂起   invalid 无效
    document.getElementById('cont').addEventListener('scroll',this.getIsFixed);


    let _this = this;

    //语料库搜索
    window.search = function (data) {
      console.log(data,'dddddddd')
      _this.keyword = data;
      _this.getDictList();
    }

    window.mouseEnter = function (data){
      let oDom = document.getElementById(data);
      if(oDom){
        oDom.className='article-dict translateH';
      }
    }
    window.mouseLeave = function (data){
      let oDom = document.getElementById(data);
      if(oDom){
        document.getElementById(data).className ='article-dict';
      }
    }
  },
  beforeDestroy(){
    document.getElementById('cont').removeEventListener('scroll',this.getIsFixed)
  },
  methods:{
    getDictList(){
      let params = {
        keyword:this.keyword,
        page:1,
        pageSize:20
      }
      this.api.dict.dictItemList(params).then((res)=>{
        this.dictList = res.list
      })
    },
    copy(data){
      this.$copyText(data).then( (e)=>{
        this.$Message.success('复制成功');
      }, (e)=> {
        this.$Message.success('复制失败');
      })
    },
    getIsFixed(){
      let scrollTop = document.getElementById('cont').scrollTop;
      let divH = document.getElementById('task-header1').offsetHeight + 80;

      this.isFixed = scrollTop - divH > 0;
    },
    init(){
      this.curDataIndex = 0;
      this.userStat = this.taskStat;
      // this.workedTaskId = {};

      //超时执行的操作
      EventBus.$on('task-timeout', this.setTaskTimeout);
      //判断类型  单选多选 默认四个选项 判断题 默认两个选项  不可添加选项
      this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];
      this.curId = this.taskList[this.curDataIndex].data.id;

      //格式化数据
      this.formate();
    },
    setTaskTimeout () {
      this.timeoutModal = true;
    },
    formate(){
      this.allData = {};
      this.taskList.forEach((item)=>{
        let result = item.dataResult.result;
        let name = item.data.id;
        let options = [];
        let names = ['Option A','Option B','Option C','Option D','Option E','Option F'];

        if(result){
          if(result.info.options.length){
            result.info.options.forEach((sItem,index)=>{
              options.push({
                id:sItem.id || this.$uuid.v1(),
                name:names[index],
                isCorrect:sItem.is_correct,
                val:sItem.name,
                comment:result.feedback ? (result.feedback[sItem.id] || []) : [],
                isCheck:false,
                isRich:false,
                placeholder:this.$t('task_option_placeholder'),
                newComment:'',
                commentModal:false,
              })
            })
          }

          let title = {
            val:result.info.name,
            comment:result.feedback ? (result.feedback.name || []) : [],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_title_placeholder'),
            newComment:'',
            commentModal:false,
          };


          let analysis = {
            val:result.info.explain,
            comment:result.feedback ? result.feedback.explain || [] : [],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder'),
            newComment:'',
            commentModal:false,
          };


          let learning_outcomes = {
            val:result.info.learning_outcomes,
            comment:result.feedback ? result.feedback.learning_outcomes || [] : [],
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder'),
            newComment:'',
            commentModal:false,
          };

          this.$set(this.allData,name,{original:{},translate:{}});

          this.$set(this.allData[name].translate,'title',title);
          this.$set(this.allData[name].translate,'analysis',analysis);
          this.$set(this.allData[name].translate,'learning_outcomes',learning_outcomes);
          this.$set(this.allData[name].translate,'optionList',options);
          this.$set(this.allData[name].translate,'id',result.info.id);
          this.$set(this.allData[name].translate,'verify',-1);  //-1 没状态 0 驳回 1通过 2重置


          this.allData[name].original = JSON.parse(JSON.stringify(item.dataResult.data.info));
          console.log(this.allData[name])
        }
        if(item.work){
          this.$set(this.allData[name].translate,'is_refused',item.work.is_refused);  //1 被驳回的
        }

      })

      this.catchData = JSON.parse(JSON.stringify(this.allData));

      this.getDict();


    },
    getDict(){
      let params = {
        project_id:this.$route.query.project_id,
        data_id:this.curId,
        task_id: this.$route.query.task_id,
      }

      this.api.task.taskFetchDict(params).then((res)=>{
        this.dictLoading = false;
        if(res.name){
          this.stringAddHtml(
              this.allData[this.curId].original.name,
              this.allData[this.curId].translate.title.val,
              res.name,
              'original_name',
              'translate_name',
              'name'
          )
          this.$set(this.allData[this.curId].translate,'namedictList',res.name);
        }
        if(res.options){
          this.allData[this.curId].original.options.forEach((item,index)=>{
            this.stringAddHtml(
                this.allData[this.curId].original.options[index].name,
                this.allData[this.curId].translate.optionList[index].val,
                res.options[index] || [],
                'original_options' + index,
                'translate_options' + index,
                'option'+index
            )
            this.$set(this.allData[this.curId].translate,'options' + index + 'dictList',res.options[index]);
          })
        }
        if(this.allData[this.curId].original.explain){
          this.stringAddHtml(
              this.allData[this.curId].original.explain,
              this.allData[this.curId].translate.analysis.val,
              res.explain,
              'original_explain',
              'translate_explain',
              'explain'
          )
          this.$set(this.allData[this.curId].translate,'explaindictList',res.explain);
        }
        if(this.allData[this.curId].original.learning_outcomes){
          this.stringAddHtml(
              this.allData[this.curId].original.learning_outcomes,
              this.allData[this.curId].translate.learning_outcomes.val,
              res.learning_outcomes,
              'original_learning_outcomes',
              'translate_learning_outcomes',
              'learning_outcomes'
          )
          this.$set(this.allData[this.curId].translate,'learningOutcomesdictList',res.learning_outcomes);
        }

      }).catch((err)=>{
        this.dictLoading = false;
      })
    },
    stringAddHtml(data,translateData,list,original_ref,translate_ref,type){
      let newStr = data;
      let translateStr = translateData;
      for(let i = list.length - 1;i >= 0;i--){
        this.$set(list[i],'my_id',i);
        newStr = newStr.substring(0, list[i].origin_position_end) + "</span>" + newStr.substring( list[i].origin_position_end);
        newStr = newStr.substring(0, list[i].origin_position_start) + '<span class="article-dict" onmouseenter=\'mouseEnter('+JSON.stringify(type +  + list[i].my_id) +')\' onmouseleave=\'mouseLeave('+JSON.stringify(type  + list[i].my_id) +')\' onclick=\'search('+ JSON.stringify(list[i].origin_txt) + ')\'>' + newStr.substring(list[i].origin_position_start);
      }
      let arr = list.sort((n,n2)=>{
        return n2.translate_position_start - n.translate_position_start ;
      })

      arr.forEach((item)=>{
        if(item.translate_position_end > 0 && item.translate_position_start >= 0){
          translateStr = translateStr.substring(0, item.translate_position_end) + "</span>" + translateStr.substring( item.translate_position_end);
          translateStr = translateStr.substring(0, item.translate_position_start) + '<span class="article-dict" id="'+type+item.my_id+'">' + translateStr.substring(item.translate_position_start);
        }
      })
      this.$nextTick(()=>{
        if(type.indexOf('option') > -1){
          document.getElementById(original_ref).innerHTML = newStr;
          document.getElementById(translate_ref).innerHTML = translateStr;
        }else{
          this.$refs[original_ref].innerHTML = newStr;
          this.$refs[translate_ref].innerHTML = translateStr;
        }
      })
    },
    // stringAddHtmlTranslate(data,translateData,list,original_ref,translate_ref,type){
    //   // let newStr = data;
    //   let translateStr = translateData;
    //
    //   let arr = list.sort((n,n2)=>{
    //     return n2.translate_position_start - n.translate_position_start ;
    //   })
    //
    //   arr.forEach((item)=>{
    //     if(item.translate_position_end > 0 && item.translate_position_start >= 0){
    //       translateStr = translateStr.substring(0, item.translate_position_end) + "</span>" + translateStr.substring( item.translate_position_end);
    //       translateStr = translateStr.substring(0, item.translate_position_start) + '<span class="article-dict" id="'+type+item.my_id+'">' + translateStr.substring(item.translate_position_start);
    //     }
    //   })
    //   if(type.indexOf('option') > -1){
    //     // document.getElementById(original_ref).innerHTML = newStr;
    //     document.getElementById(translate_ref).innerHTML = translateStr;
    //   }else{
    //     // this.$refs[original_ref].innerHTML = newStr;
    //     this.$refs[translate_ref].innerHTML = translateStr;
    //   }
    // },
    formateDict(type,index){
      this.temporaryStorage('blur');

      // if(type == 'name'){
      //   this.stringAddHtmlTranslate(
      //       this.allData[this.curId].original.name,
      //       this.allData[this.curId].translate.title.val,
      //       this.allData[this.curId].translate.namedictList,
      //       'original_name',
      //       'translate_name',
      //       'name'
      //   )
      // }else if(type == 'option'){
      //   this.stringAddHtmlTranslate(
      //       this.allData[this.curId].original.options[index].name,
      //       this.allData[this.curId].translate.optionList[index].val,
      //       this.allData[this.curId].translate['options' + index + 'dictList'],
      //       'original_options' + index,
      //       'translate_options' + index,
      //       'option'+index
      //   )
      // }else if(type == 'explain'){
      //   this.stringAddHtmlTranslate(
      //       this.allData[this.curId].original.explain,
      //       this.allData[this.curId].translate.analysis.val,
      //       this.allData[this.curId].translate.explaindictList,
      //       'original_explain',
      //       'translate_explain',
      //       'explain'
      //   )
      // }
    },
    changeData(index){
      //暂存
      this.temporaryStorage();

      this.curDataIndex = index;
      this.curId = this.taskList[this.curDataIndex].data.id;

      console.log(this.curId,'this.curIdthis.curId')
      console.log(this.allData[this.curId],'=------------------')

      this.getDict();

    },
    temporaryStorage(type,index){ //暂存
      if(this.allData[this.curId].submit == 1){
        return;
      }
      if(type == 'title' && !this.allData[this.curId].translate.title.newComment){
        this.$Message.warning({
          content: '请输入评论',
          duration: 1,
        });
        return;

      }else if(type == 'option' && !this.allData[this.curId].translate.optionList[index].newComment){
        this.$Message.warning({
          content: '请输入评论',
          duration: 1,
        });
        return;
      }else if(type == 'explain' && !this.allData[this.curId].translate.analysis.newComment){
        this.$Message.warning({
          content: '请输入评论',
          duration: 1,
        });
        return;
      }else if(type == 'learning_outcomes' && !this.allData[this.curId].translate.learning_outcomes.newComment){
        this.$Message.warning({
          content: '请输入评论',
          duration: 1,
        });
        return;
      }

      clearInterval(this.timer)
      let result = {};
      let status = false; //判断是否有没填的数据

      let options = this.allData[this.curId].translate.optionList.map((item)=>{
        return {
          "id":item.id,
          "name":item.val,
          "is_correct":item.isCorrect
        }
      })

      let feedback = {};
      this.allData[this.curId].translate.optionList.forEach((item)=>{
        if(item.newComment){
          let commentData = item.comment && item.comment.length ? JSON.parse(JSON.stringify(item.comment)) : [];

          commentData.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:item.newComment,
          })
          feedback[item.id] = JSON.stringify(commentData);
        }else{
          if(item.comment && item.comment.length){
            feedback[item.id] = JSON.stringify(item.comment);
          }
        }
      });
      if(this.allData[this.curId].translate.title.newComment){
        let commentData = this.allData[this.curId].translate.title.comment && this.allData[this.curId].translate.title.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].translate.title.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].translate.title.newComment,
        })
        feedback.name = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].translate.title.comment && this.allData[this.curId].translate.title.comment.length){
          feedback.name = JSON.stringify(this.allData[this.curId].translate.title.comment);
        }
      }
      if(this.allData[this.curId].translate.analysis.newComment){

        let commentData = this.allData[this.curId].translate.analysis.comment && this.allData[this.curId].translate.analysis.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].translate.analysis.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].translate.analysis.newComment,
        })
        feedback.explain = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].translate.analysis.comment && this.allData[this.curId].translate.analysis.comment.length){
          feedback.explain = JSON.stringify(this.allData[this.curId].translate.analysis.comment);
        }
      }
      if(this.allData[this.curId].translate.learning_outcomes.newComment){

        let commentData = this.allData[this.curId].translate.learning_outcomes.comment && this.allData[this.curId].translate.learning_outcomes.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].translate.learning_outcomes.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].translate.learning_outcomes.newComment,
        })
        feedback.learning_outcomes = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].translate.learning_outcomes.comment && this.allData[this.curId].translate.learning_outcomes.comment.length){
          feedback.learning_outcomes = JSON.stringify(this.allData[this.curId].translate.learning_outcomes.comment);
        }
      }

      if(!this.allData[this.curId].translate.suspend && !this.allData[this.curId].translate.invalid){
        result[this.curId] = {
          info:{
            "type":"question",
            "id":this.$uuid.v1(),
            "name" : this.allData[this.curId].translate.title.val,
            "options" : options,
            "explain":this.allData[this.curId].translate.analysis.val,
            "learning_outcomes":this.allData[this.curId].translate.learning_outcomes.val,
          },
          "feedback":feedback,
        }
      }
      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'temporary_storage',
      };

      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      this.storageLoading = true;
      if(type == 'change'){
        this.dictLoading = true;
      }
      this.api.task.taskExecute(reqData).then((res)=>{
        this.storageLoading = false;
        this.$Message.destroy();
        this.$Message.success({
          content: '暂存成功',
          duration: 1,
        });

        if(type == 'title'){

          this.allData[this.curId].translate.title.comment.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[this.curId].translate.title.newComment,
          })

          this.allData[this.curId].translate.title.newComment = '';
          this.getDict();
        }else if(type == 'option'){

          this.allData[this.curId].translate.optionList[index].comment.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[this.curId].translate.optionList[index].newComment,
          })
          this.allData[this.curId].translate.optionList[index].newComment = '';
          this.getDict();
        }else if(type == 'explain'){

          this.allData[this.curId].translate.analysis.comment.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[this.curId].translate.analysis.newComment,
          })
          this.allData[this.curId].translate.analysis.newComment = '';
          this.getDict();
        }else if(type == 'learning_outcomes'){

          this.allData[this.curId].translate.learning_outcomes.comment.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:this.allData[this.curId].translate.learning_outcomes.newComment,
          })
          this.allData[this.curId].translate.learning_outcomes.newComment = '';
          this.getDict();
        }

        if(type == 'blur'){
          this.getDict();
        }


      }).catch((err)=>{
        this.storageLoading = false;
        this.dictLoading = false
      })

    },
    countDown(){  //倒计时
      this.sec = 60;
      this.timer = setInterval(()=>{
        this.sec--;
        if(this.sec == 0){
          this.temporaryStorage();
        }
      },1000)
    },
    saveTaskResult (next = true, verify = 1) { //verify 0驳回,1通过
      if (this.loading) {
        return;
      }

      let result = {};


      let feedback = {};
      this.allData[this.curId].translate.optionList.forEach((item)=>{
        if(item.newComment){
          let commentData = item.comment && item.comment.length ? JSON.parse(JSON.stringify(item.comment)) : [];

          commentData.unshift({
            name:this.$store.state.user.userInfo.nickname,
            time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
            batch:this.stepInfo.name,
            comment:item.newComment,
          })
          feedback[item.id] = JSON.stringify(commentData);
        }else{
          if(item.comment && item.comment.length){
            feedback[item.id] = JSON.stringify(item.comment);
          }
        }
      });
      if(this.allData[this.curId].translate.title.newComment){
        let commentData = this.allData[this.curId].translate.title.comment && this.allData[this.curId].translate.title.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].translate.title.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].translate.title.newComment,
        })
        feedback.name = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].translate.title.comment && this.allData[this.curId].translate.title.comment.length){
          feedback.name = JSON.stringify(this.allData[this.curId].translate.title.comment);
        }
      }
      if(this.allData[this.curId].translate.analysis.newComment){

        let commentData = this.allData[this.curId].translate.analysis.comment && this.allData[this.curId].translate.analysis.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].translate.analysis.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].translate.analysis.newComment,
        })
        feedback.explain = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].translate.analysis.comment && this.allData[this.curId].translate.analysis.comment.length){
          feedback.explain = JSON.stringify(this.allData[this.curId].translate.analysis.comment);
        }
      }
      if(this.allData[this.curId].translate.learning_outcomes.newComment){

        let commentData = this.allData[this.curId].translate.learning_outcomes.comment && this.allData[this.curId].translate.learning_outcomes.comment.length ? JSON.parse(JSON.stringify(this.allData[this.curId].translate.learning_outcomes.comment)) : [];
        commentData.unshift({
          name:this.$store.state.user.userInfo.nickname,
          time:util.timeFormatter(new Date(),'yyyy-MM-dd hh:mm:ss'),
          batch:this.stepInfo.name,
          comment:this.allData[this.curId].translate.learning_outcomes.newComment,
        })
        feedback.learning_outcomes = JSON.stringify(commentData);
      }else{
        if(this.allData[this.curId].translate.learning_outcomes.comment && this.allData[this.curId].translate.learning_outcomes.comment.length){
          feedback.learning_outcomes = JSON.stringify(this.allData[this.curId].translate.learning_outcomes.comment);
        }
      }





      let len = this.allData[this.curId].translate.optionList.filter((item)=>{
        return !item.val;
      }).length;

      if(!this.allData[this.curId].translate.suspend && !this.allData[this.curId].translate.invalid){
        if(len > 0 || !this.allData[this.curId].translate.title.val ){
          this.$Message.warning('数据未填写完整');
          return;

        }
      }

      //驳回数据如果未更新内容不可以提交
      // if(this.allData[this.curId].translate.is_refused == '1'){ //驳回数据
      //   let isModify = false;
      //   console.log(this.allData[this.curId],this.catchData[this.curId],'=======')
      //   if(this.allData[this.curId].translate.title.val != this.catchData[this.curId].translate.title.val || this.allData[this.curId].translate.analysis.val != this.catchData[this.curId].translate.analysis.val){
      //     isModify = true;
      //   }
      //   if(this.allData[this.curId].translate.optionList.length != this.catchData[this.curId].translate.optionList.length){
      //     isModify = true;
      //   }else{
      //     this.allData[this.curId].translate.optionList.forEach((item,index)=>{
      //       if(item.val != this.catchData[this.curId].translate.optionList[index].val || item.isCorrect !=this.catchData[this.curId].translate.optionList[index].isCorrect){
      //         isModify = true;
      //       }
      //     })
      //   }
      //   if(!isModify){
      //     this.$Message.warning('请修改后提交～');
      //     return;
      //   }
      // }




      let options = this.allData[this.curId].translate.optionList.map((item)=>{
        return {
          "id":item.id,
          "name":item.val,
          "is_correct":item.isCorrect
        }
      })


      if(!this.allData[this.curId].translate.suspend && !this.allData[this.curId].translate.invalid){
        result[this.curId] = {
          info:{
            "type":"question",
            "id":this.$uuid.v1(),
            "name" : this.allData[this.curId].translate.title.val,
            "options" : options,
            "explain":this.allData[this.curId].translate.analysis.val,
            "learning_outcomes":this.allData[this.curId].translate.learning_outcomes.val,
          },
          "feedback":feedback,
        }
      }


      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'submit',
      };
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      if (!reqData.project_id || !reqData.task_id ) {
        return;
      }


      this.api.task.taskExecute(reqData).then((res)=>{

        this.loading = false;
        this.allData[this.curId].submit = 1;
        this.next(1);
        this.$Message.destroy();
        this.$Message.success({
          content: this.$t('tool_submit_success'),
          duration: 1,
        });

        //判断是否全部提交了 如果全部提交自动领取
        let submitAllStatus = true;
        for(let name in this.allData){
          if(this.allData[name].submit != 1){
            submitAllStatus = false;
          }
        }
        console.log(submitAllStatus,'submitAllStatus')
        if(submitAllStatus){
          //提交后重新领取任务 如果有任务继续做  没有返回上一级
          this.$emit('againGetTask')
        }


      }).catch((err)=>{
        if(verify == 1){  //通过
          this.loading = false;
        }else if(verify == 0){  //驳回
          this.rejectLoading = false;
        }
      })
    },
    // setDifficult () {
    //   this.saveTaskResult(true, 1);
    // },
    submit () {
      this.saveTaskResult(true, 1);
    },
    next(type){
      if(this.curDataIndex == this.taskList.length -1){
        this.$Message.warning(this.$t('task_last_data'));
      }else{
        if(this.allData[this.taskList[this.curDataIndex+1].data.id].translate.suspend == 1 || this.allData[this.taskList[this.curDataIndex+1].data.id].translate.invalid == 1){

          //找一下后面有没有可以跳转的
          let arr = [];
          for(let i=this.curDataIndex+1;i<this.taskList.length;i++){
            if(!this.allData[this.taskList[i].data.id].translate.suspend || !this.allData[this.taskList[i].data.id].translate.invalid){
              arr.push(this.allData[i]);
            }
          }
          if(arr.length){
            this.curDataIndex += 1;
            this.curId = this.taskList[this.curDataIndex].translate.data.id;
            this.next();
          }else{
            this.$Message.warning(this.$t('task_disabled_operated'));
          }


          return;
        }
        if(!type){
          //暂存
          this.temporaryStorage();
        }

        this.curDataIndex += 1;
        this.curId = this.taskList[this.curDataIndex].data.id;

        this.sec = 60;
        this.countDown();
        this.getDict();

      }
    },
    pre(){
      if(this.curDataIndex == 0){
        this.$Message.warning(this.$t('task_first_data'));
      }else{
        if(this.allData[this.taskList[this.curDataIndex-1].data.id].translate.suspend == 1 || this.allData[this.taskList[this.curDataIndex-1].data.id].translate.invalid == 1){

          console.log('2323232323232')
          //找一下前面有没有可以跳转的
          let arr = [];
          for(let i=0;i<this.curDataIndex-1;i++){
            if(!this.allData[this.taskList[i].data.id].translate.suspend && !this.allData[this.taskList[i].data.id].translate.invalid){
              arr.push(this.allData[this.taskList[i].data.id]);
            }
          }
          if(arr.length){
            this.curDataIndex -= 1;
            this.curId = this.taskList[this.curDataIndex].data.id;
            this.pre();
          }else{
            this.$Message.warning(this.$t('task_disabled_operated'));
          }

          return;
        }

        //暂存
        this.temporaryStorage();

        this.curDataIndex -= 1;
        this.curId = this.taskList[this.curDataIndex].data.id;

        this.sec = 60;
        this.countDown();
        this.getDict();
      }
    },
    again(){
      this.$emit('againGetTask')
    },
    backTask(){
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped lang="scss">
.translate{
  font-size: 14px;
  .translate-top{
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #666666;
    >div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        padding: 0 10px;
        height: 20px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .green{
    margin-right: 10px;
    background: #E7FFEA;
    border: 1px solid #00BF31;
    color:#00BF31;
  }
  .purple{
    margin-right: 10px;
    background: #F0EDFF;
    border: 1px solid #757BF2;
    color:#757BF2;
  }
  .yellow{
    background: #FAEFD0;
    border: 1px solid #FE9200;
    color:#FE9200;
  }
  .translate-cont{
    margin: 20px;
    display: flex;
    justify-content: flex-start;
    position: relative;

    .translate-cont-num{
     margin-top: 10px;
     width: 36px;

     >p{
       height: 40px;
       display: flex;
       justify-content: center;
       align-items: center;
       font-size: 16px;
       font-weight: bold;
       color: #333333;
       background: #F6F7FA;
       cursor: pointer;
     }
     .active{
       margin-left: -4px;
       width: 40px;
       background: #4A79FF;
       border-radius: 4px 0px 0px 4px;
       color: #FFFFFF;
     }
   }
    .translate-cont-nr{
      margin-left: 36px;
      margin-right: 240px;
      flex: 1;
      color:#666666;

      .translate-cont-translate{
        display: flex;
        justify-content: flex-start;
        >div{
          padding: 20px 20px 60px 20px;
          flex: 1;
          margin-right: 10px;
          background-color: #FFFFFF;
          font-size: 14px;
          line-height: 1.5;
        }
      }

      .translate-cont-nr-btn{
        margin: 20px 0;
        >button{
          margin-right: 20px;
        }
      }

      .sub-tit{
        margin: 20px 0 10px 0;
        font-weight: bold;
        color: #333333;
      }
      .translate-cont-nr-item{
        padding: 20px;
        position: relative;
        border: 1px solid #E3E3E3;
        border-radius: 4px;

        >div{
          font-size: 14px;
          line-height: 1.5;
          z-index: 2;
          white-space:normal;
          word-break: break-word;
        }
        .translate-cont-nr-item-china{
          position: absolute;
          top: 20px;
          width: calc(100% - 40px);
        }

        .translate-cont-nr-item-en{
          position: absolute;
          top: 20px;
          z-index: 3;
          width: calc(100% - 40px);
          color: #FFFFFF;
        }
        .translate-cont-nr-item-textarea{
          position: relative;
          z-index: 4;

          >textarea{
            padding: 0;

          }
        }
        .check-comment{
          position: absolute;
          right: 0;
          top: -24px;
          .check-comment-btn{
            width: 76px;
            height: 24px;
            background: #C65252;
            border-radius: 4px;
            color: #FFFFFF;
            text-align: center;
            line-height: 24px;
            cursor: pointer;
          }
        }
      }
      .borderR{
        border: 1px solid #C65252;
      }
    }
    .translate-cont-corpus{
      padding: 20px;
      width: 240px;
      //height: calc(100% - 100px);
      overflow-y: auto;
      background: #FFFFFF;
      right: 0;

      .translate-cont-corpus-search{
        margin: 20px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px 0 0;
        height: 40px;
        background: #F8F8F9;
        border-radius: 4px;
        .translate-cont-corpus-search-inp{
          flex: 1;
          margin-left: 10px;
          margin-right: 5px;
          border: none;
        }
      }

      .translate-cont-corpus-name{
        margin: 10px 0 6px 0;
        font-weight: bold;
        color: #333333;
      }
      .translate-cont-corpus-des{
        margin-top: 6px;
        font-size: 12px;
        color: #999999;
      }
      .translate-cont-corpus-ratio{
        margin-top: 10px;
        display: inline-block;
        padding: 0 10px;
        cursor: pointer;
      }
      .fgx{
        margin-top: 10px;
        border-bottom: 2px solid #EEEEEE;
      }
    }
    .tit{
      padding-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
      color: #4A79FF;
      border-bottom: 2px solid #EEEEEE;
    }
  }
  .comment-list{
    .comment-close{
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
    }
    .comment-list-item{
      margin-top: 10px;
      padding-bottom: 10px;
      border-bottom: 2px solid #EEEEEE;

      .comment-list-item-tit{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p:first-child{
          margin-right: 4px;
          font-weight: bold;
          font-size: 14px;
        }
        >p:last-child{
          width: 54px;
          height: 20px;
          background: #F0EDFF;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          color: #757BF2;
          text-align: center;
          line-height: 20px;
        }
      }
      .comment-list-item-time{
        font-size: 12px;
        color: #999999;
      }
      .comment-list-item-desc{
        color: #666666;
      }
    }
    .comment-list-item:last-child{
      border-bottom: none;
    }
    .comment-input{
      padding: 10px;
      background: #F8F8F9;

    }
  }

}
</style>
<style>
.translate .ivu-input{
  border: none;
  background-color: transparent;
  padding: 0;
  resize: none;
}
.translate .article-dict{
  background-color: #FAEFD0;
  cursor: pointer;
}
.translate .article-dict:hover{
  border-radius: 4px;
  background-color: #ffe362;
}
.translate .translateH{
  background-color: #ffe362;
}
</style>
