<template>
  <div class="exam-main">
    <div class="task-header" >
      <task-info
        :projectInfo = "projectInfo"
        :batchInfo = "batchInfo"
        :stepInfo = "stepInfo"
        :taskInfo = "taskInfo"
        :dataId="curId" />
      <div class="task-btn-group">
        <task-progress
            :total="taskList.length"
            :current="curDataIndex"
            :timeout="taskTimeout" />
        <Button type="primary" size="small" @click.native="temporaryStorage">{{sec}}s后暂存</Button>
        <Tooltip max-width="170" content="暂存：每分钟自动存储所有作业结果，可手动暂存">
          <Icon type="ios-help-circle-outline" :size="16" />
        </Tooltip>
        <!-- 挂起 -->
<!--        <Button type="warning" size="small" @click.native="setDifficult" >-->
<!--          {{$t('tool_submit_difficult_job')}}-->
<!--        </Button>-->
        <Button type="warning" size="small" @click.native="exit">{{$t('tool_quit')}}</Button>
<!--        <Button type="primary" size="small" @click.native="submitAndExit">{{$t('tool_submit_exit')}}</Button>-->
        <Button type="primary" size="small" @click.native="submit" :loading="loading">{{$t('tool_submit')}}
        </Button>
      </div>
    </div>
    <div class="tip">
      <div class="tip-tit">
        <p :class="{last : index == (projectTabList.length - 1),active:curProjectTab.id == item.id }" v-for="(item,index) in projectTabList" :key="item.id" @click="changeProjectTab(item)">{{ item.name }}</p>
        <span style="display: inline-block" @click="isShow = false;" v-if="isShow"><Icon type="ios-arrow-up" size="28" /></span>
        <span style="display: inline-block" @click="isShow = true;" v-else><Icon type="ios-arrow-down" size="28" /></span>

      </div>
      <div :style="{height:isShow ? 'auto' : '0px'}" class="tip-container">
        <div class="tip-cont" v-if="curProjectTab.id == 1">
<!--          {{projectInfo.attr[curProjectTab.key]}}-->
          <Richtxt :isDisabled="true" :dataObj="{val:projectInfo.attr[curProjectTab.key]}"></Richtxt>
        </div>
        <div class="tip-cont" v-if="curProjectTab.id == 2">
          <p v-for="item in projectInfo.attr[curProjectTab.key]" :key="item.url">
            {{item.name}}
          </p>
        </div>
        <div class="tip-cont" v-if="curProjectTab.id == 3">
          {{projectInfo.attr[curProjectTab.key]}}
        </div>
      </div>
    </div>
    <div class="cont">
      <p class="cont-tit">{{ $t('task_overview') }}</p>
      <div class="cont-box">
        <div class="cont-num">
          <span :class="{active : curDataIndex == index,success: allData[item.data.id].suspend == 1,invalid: allData[item.data.id].invalid == 1}" v-for="(item,index) in taskList" :key="item.data.id" @click="changeData(index)">{{index + 1}}</span>
        </div>
        <div>
          <Button style="margin-right: 10px" type="primary" @click="singleOp(1)" :loading="suspendLoading">挂起</Button>
          <Button type="error" @click="singleOp(2)" :loading="invalidLoading">无效作业</Button>
        </div>
      </div>

      <div class="exam-num">
        {{ $t('task_section')}} <span>{{curDataIndex + 1}}</span> {{$t('task_topic')}}
      </div>
      <div class="exam-des">
        <div class="l">
<!--          <div>-->
<!--            <span>{{$t('task_work_tasks')}}:</span>-->
<!--            <span>{{ taskList[curDataIndex].dataResult.data['工作任务'] }}</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>{{$t('task_skill_set')}}:</span>-->
<!--            <span>{{ taskList[curDataIndex].dataResult.data['技能集'] }}</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>{{ $t('task_first_level') }}:</span>-->
<!--            <span>{{ taskList[curDataIndex].dataResult.data['技能点（一级知识点）'] }}</span>-->
<!--          </div>-->
          <div>
            <span>{{ $t('task_question_type') }}:</span>
            <span>{{ taskList[curDataIndex].dataResult.data.type_name || taskList[curDataIndex].dataResult.data.type }}</span>
          </div>
          <div>
            <span>{{$t('task_difficulty_level')}}:</span>
            <span>{{ taskList[curDataIndex].dataResult.data.level_name || taskList[curDataIndex].dataResult.data.level }}</span>
          </div>
          <div>
            <span>*{{ $t('task_knowledge_points') }}</span>
            <div>
              <el-select
                  v-model="allData[curId].nodes"
                  multiple
                  filterable
                  remote
                  allow-create
                  default-first-option
                  :remote-method="(query) => remoteMethod(query,'node')"
                  :loading="knowledgePointLoading"
                  style="width:300px;"
                  @change="changeNodes"
              >
                <el-option
                    v-for="item in knowledgePointList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
<!--              <Button type="primary" @click="addNodes" style="margin-left: 10px;">添加</Button>-->
            </div>
          </div>
        </div>
        <div class="r">

          <div>
            <span>*分类:</span>
            <div>
              <el-select
                  v-model="allData[curId].categories"
                  multiple
                  filterable
                  remote
                  disabled
                  :remote-method="(query) => remoteMethod(query,'category')"
                  :loading="categoryLoading"
                  style="width:300px;"
                  @change="changeCategory"
              >
                <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div>
            <span>标签:</span>
            <div>
              <el-select
                  v-model="allData[curId].labels"
                  multiple
                  filterable
                  remote
                  allow-create
                  default-first-option
                  :remote-method="(query) => remoteMethod(query,'label')"
                  :loading="labelLoading"
                  style="width:300px;"
                  @change="changeLabel"
              >
                <el-option
                    v-for="item in labelList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
<!--              <Button type="primary" @click="addLabel" style="margin-left: 10px;">添加</Button>-->
            </div>
          </div>
        </div>
      </div>
      <div class="exam-cont">
        <div class="item">
          <div class="item-l">
            <p>*{{ $t('task_test_title') }}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input">
              <div class="item-r-input-cont">
                <div class="left" :style="{width:allData[curId].title.comment ? '70%' : '100%'}">
                  <Richtxt v-if="!richTxtLoading" :dataObj="allData[curId].title" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].title)"></Richtxt>

                </div>
                <div class="right" v-if="allData[curId].title.comment">
                  <div class="tit">
                    评论区
                  </div>
                  <div class="comment">
                    {{allData[curId].title.comment}}
                  </div>
                </div>
              </div>

              <div class="btn">
                <!--              <Button size="small">预览</Button>-->
              </div>
            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
        <div class="item" v-for="(item,index) in allData[curId].optionList" :key="item.id + 'option'">
          <div class="item-l">
            <p>*{{ item.name }}</p>
            <p :class="item.isCorrect ? 'correct' : ''" @click="changeCorrect(item)">{{item.isCorrect ? $t('task_correct_option') : $t('task_set_correct_option')}}</p>
            <div>
              <Button size="small" type="error" style="margin-top: 10px" v-if="index > 3" @click="deleOption(index)">
                {{ $t('task_delete') }}</Button>
            </div>
          </div>
          <div class="item-r">
            <div class="item-r-input" :class="item.isCorrect ? 'correct' : ''">
              <div class="item-r-input-cont">
                <div class="left" :style="{width:item.comment ? '70%' : '100%'}">
                  <Richtxt v-if="!richTxtLoading" :height="150" :dataObj="item" :curId="curId" @saveData="(data)=>saveRichData(data,item)"></Richtxt>
                </div>
                <div class="right" v-if="item.comment">
                  <div class="tit">
                    评论区
                  </div>
                  <div class="comment">
                    {{item.comment}}
                  </div>
                </div>
              </div>

            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
        <Button type="primary" class="add-btn" @click="addOption" v-if="questionType != '判断题' && taskList[curDataIndex].dataResult.data.type != 8 && taskList[curDataIndex].dataResult.data.type != 9" >{{ $t('task_add_options') }}</Button>
        <div class="item">
          <div class="item-l">
            <p>{{ $t('task_analysis') }}</p>
          </div>
          <div class="item-r">
            <div class="item-r-input">
              <div class="item-r-input-cont">
                <div class="left" :style="{width:allData[curId].analysis.comment ? '70%' : '100%'}">
                  <Richtxt v-if="!richTxtLoading"  :dataObj="allData[curId].analysis" :curId="curId" @saveData="(data)=>saveRichData(data,allData[curId].analysis)"></Richtxt>
                </div>
                <div class="right" v-if="allData[curId].analysis.comment">
                  <div class="tit">
                    评论区
                  </div>
                  <div class="comment">
                    {{allData[curId].analysis.comment}}
                  </div>
                </div>
              </div>

              <div class="btn">
                <!--               <Button size="small">预览</Button>-->
              </div>
            </div>
            <div class="item-r-comment">
            </div>
          </div>
        </div>
      </div>
      <div class="bot">
        <Button type="primary" class="bot-btn" @click="pre">{{ $t('task_pre_question') }}</Button>
        <Button type="primary" @click="next">{{ $t('task_next_question') }}</Button>
      </div>
    </div>
    <Modal
        v-model="timeoutModal"
        :title="$t('task_task_timed_out')"
        :mask-closable="false"
        :closable="false"
    >
      <div class="timeout-text">{{ $t('task_re_claim') }}</div>
      <div slot="footer">
        <Button type="primary" @click="again">{{ $t('task_get_task_again') }}</Button>

        <Button type="primary" @click="backTask">{{ $t('task_return_to_task') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="verificationModal"
        title="数据未填完整"
        @on-ok="ok"
        @on-cancel="cancel"
    >
      <p v-for="item in incompleteData" :key="item">第 <strong style="">{{item + 1}}</strong> 题数据未填完整</p>
    </Modal>
    <Modal
        v-model="addNodesModal"
        title="添加知识点"
    >
      <Input v-model="nodeVal" placeholder="请输入知识点" />
      <div slot="footer">
        <Button @click="cancelAddNodes">取消</Button>
        <Button type="primary" :loading="addNodesLoading" @click="confirmAddNodes">确定</Button>
      </div>
    </Modal>
    <Modal
        v-model="addLabelModal"
        title="添加标签"
    >
      <Input v-model="labelVal" placeholder="请输入标签" />
      <div slot="footer">
        <Button @click="cancelAddLabel">取消</Button>
        <Button type="primary" :loading="addLabelLoading" @click="confirmAddLabel">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Richtxt from '../richtxt.vue';
import TaskProgress from '../progress.vue';
import TaskInfo from '../taskinfo.vue';

import EventBus from '@/utils/eventBus';


export default {
  name: "produce",
  props: {
    templateInfo: {
      type: Array,
      default: [],
    },
    taskList: {
      type: Array,
      required: true,
    },
    categoryInfo: {
      type: Object,
      required: true,
    },
    serverTime: {
      type: Number,
      required: true,
    },
    taskInfo: {
      type: Object,
      required: true,
    },
    taskTimeout: {
      type: Number,
      required: true,
    },
    taskStat: {
      type: Object,
      required: true,
    },
    stepInfo: {
      type: Object,
      required: true,
    },
    batchInfo: {
      type: Object,
      required: true,
    },
    projectInfo: {
      type: Object,
      required: true,
    },
  },

  data(){
    return{
      knowledgePointValue:[],
      isShow:false, //默认收起tab项
      projectTabList:[
        {
          id:1,
          name:this.$t('task_attention'),
          key:'description'
        },
        {
          id:2,
          name:this.$t('task_attachment'),
          key:'attachments'
        },
        // {
        //   id:3,
        //   name:this.$t('task_feedback'),
        // },
      ],
      curProjectTab:{
        id:1,
        name:this.$t('task_attention'),
        key:'description'
      },
      curDataIndex:0,// 当前数据
      cont:'',
      nameList:[this.$t('task_option_e'),this.$t('task_option_f')],
      // taskTimeout: 600,
      loading: false,
      taskItemInfo: '',
      taskItemInfoMore: {},
      loadingText: this.$t('tool_loading'),
      userStat: {},
      textPlaceHolderReady: false,
      // workedTaskId: {},
      knowledgePoint:[],  //选中的知识点
      knowledgePointList:[],  //知识点列表
      knowledgePointLoading:false,
      questionType:'',
      allData:{},
      curId:'', //当前数据id
      exitLoading:false,
      timeoutModal:false,
      sec:60,
      timer:null,
      storageLoading:false,
      verificationModal:false,  //校验数据弹窗
      incompleteData:[],
      categoryLoading:false,
      categoryList:[],
      labelLoading:false,
      labelList:[],
      addNodesModal:false,  //添加知识点弹窗
      nodeVal:'',
      addNodesLoading:false,
      addLabelModal:false,
      labelVal:'',
      addLabelLoading:false,
      suspendLoading:false,
      invalidLoading:false,
      richTxtLoading:false,
    }
  },
  components:{
    Richtxt,
    'task-progress': TaskProgress,
    'task-info': TaskInfo,
  },
  computed: {
    dataId () {
      return this.taskList[this.curDataIndex].data.id;
    },
  },
  watch:{
    taskList:{
      handler(newV){
        this.init();
      },
      immediate:true
    }
  },
  mounted(){
    this.$emit('componentsLoaded');
    this.countDown();
  },
  methods:{
    countDown(){  //倒计时
      this.sec = 60;
      this.timer = setInterval(()=>{
        this.sec--;
        if(this.sec == 0){
          this.temporaryStorage();
        }
      },1000)
    },
    temporaryStorage(){ //暂存
      clearInterval(this.timer)
      let result = {};
      let status = false; //判断是否有没填的数据

      for(let name in this.allData){
        let options = this.allData[name].optionList.map((item)=>{
          return {
            "id":item.id,
            "name":item.val,
            "is_correct":item.isCorrect
          }
        })
        if(!this.allData[name].suspend && !this.allData[name].invalid){
          result[name] = {
            info:{
              "type":"question",
              "id":this.$uuid.v1(),
              "name" : this.allData[name].title.val,
              "options" : options,
              "explain":this.allData[name].analysis.val,
              "nodes":this.allData[name].nodes,
              "categories":this.allData[name].categories,
              "labels":this.allData[name].labels,
            }
          }
        }

      }
      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'temporary_storage',
      };

      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      this.storageLoading = true;
      this.api.task.taskExecute(reqData).then((res)=>{
        this.storageLoading = false;
        this.$Message.destroy();
        this.$Message.success({
          content: '暂存成功',
          duration: 1,
        });

        this.sec = 60;
        this.countDown();

      }).catch((err)=>{
        this.storageLoading = false;
      })

    },
    init(){
      this.curDataIndex = 0;
      this.userStat = this.taskStat;
      // this.workedTaskId = {};

      //超时执行的操作
      EventBus.$on('task-timeout', this.setTaskTimeout);
      //判断类型  单选多选 默认四个选项 判断题 默认两个选项  不可添加选项
      this.questionType = this.taskList[this.curDataIndex].dataResult.data['题型'];
      this.curId = this.taskList[this.curDataIndex].data.id;
      console.log(this.questionType,'this.questionTypethis.questionTypethis.questionType')
      //格式化数据
      this.formate();
    },
    setTaskTimeout () {
      this.timeoutModal = true;
    },
    formate(){
      this.allData = {};
      this.taskList.forEach((item)=>{
        let result = item.dataResult.result;
        let name = item.data.id;
        let options = [];
        let names = [this.$t('task_option_a'),this.$t('task_option_b'),this.$t('task_option_c'),this.$t('task_option_d'),this.$t('task_option_e'),this.$t('task_option_f')];
        if(result){
          if(result.info.options.length){
            result.info.options.forEach((sItem,index)=>{
              options.push({
                id:sItem.id,
                name:names[index],
                isCorrect:sItem.is_correct == '1',
                val:sItem.name,
                comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback[sItem.id] : '') : '',
                isCheck:false,
                isRich:false,
                placeholder:this.$t('task_option_placeholder')
              })
            })
          }else{
            options.push({
              // id:sItem.id,
              name:names[0],
              isCorrect:true,
              val:'',
              comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback[sItem.id] : '') : '',
              isCheck:false,
              isRich:false,
              placeholder:this.$t('task_option_placeholder')
            })
          }


          let title = {
            val:result.info.name,
            comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback.name : '') : '',
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_title_placeholder')
          };
          let analysis = {
            val:result.info.explain,
            comment:item.work.is_refused != '0' ? (result.feedback ? result.feedback.explain : '') : '',
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder')
          };
          this.$set(this.allData,name,{});
          this.$set(this.allData[name],'nodes',result.info.nodes);
          this.$set(this.allData[name],'categories',result.info.categories);
          this.$set(this.allData[name],'labels',result.info.labels);

          this.$set(this.allData[name],'title',title);

          this.$set(this.allData[name],'analysis',analysis);
          this.$set(this.allData[name],'optionList',options);
          this.$set(this.allData[name],'id',result.info.id);
          this.$set(this.allData[name],'type',result.info.type);
          this.$set(this.allData[name],'verify',-1);  //-1 没状态 0 驳回 1通过 2重置
        }else{
          let len = 0;
          console.log(this.questionType,'this.questionType')
          if(this.questionType == '判断题'){
            len = 2;
          }else{
            len = 4;
          }
          for(let i = 0;i<len;i++){
            options.push({
              id:this.$uuid.v1(),
              name:names[i],
              isCorrect:false,
              val:'',
              comment:'',
              isCheck:false,
              isRich:false,
              placeholder:this.$t('task_option_placeholder')
            })
          }

          let title = {
            val:'',
            comment:'',
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_title_placeholder')
          };
          let analysis = {
            val:'',
            comment:'',
            isCheck:false,
            isRich:false,
            placeholder:this.$t('task_analysis_placeholder')
          };
          this.$set(this.allData,name,{});
          this.$set(this.allData[name],'nodes',[]);
          this.$set(this.allData[name],'categories',[]);
          this.$set(this.allData[name],'labels',[]);
          this.$set(this.allData[name],'title',title);
          this.$set(this.allData[name],'analysis',analysis);
          this.$set(this.allData[name],'optionList',options);
        }
      })
      let nodeIds = this.taskList[0].dataResult.result.info.nodes;
      this.getKnowledgePoint(nodeIds,'ids');

      let categoryIds = this.taskList[0].dataResult.result.info.categories;
      console.log(categoryIds,'categoryIds')
      this.getCategory(categoryIds,'ids');

      let labels = this.taskList[0].dataResult.result.info.labels;
      this.getLabel(labels,'ids');
    },
    addOption(){  //添加选项
      if(this.allData[this.curId].optionList.length < 6){
        this.allData[this.curId].optionList.push({
          id:this.$uuid.v1(),
          name:this.nameList[this.allData[this.curId].optionList.length - 4],
          isCorrect:false,
          val:'',
          placeholder:''
        })
      }else{
        this.$Message.warning(this.$t('task_add_max'));
      }
    },
    deleOption(index){ //删除选项
      this.allData[this.curId].optionList.splice(index,1);
    },
    saveTaskResult (next = true, isDifficult = 0) { //isDifficult 0 提交  1挂起
      // if (this.workedTaskId[this.dataId]) {
      //   return;
      // }
      if (this.loading) {
        return;
      }
      let result = {};
      let status = false; //判断是否有没填的数据
      this.incompleteData = [];

      for(let name in this.allData){
        let len = this.allData[name].optionList.filter((item)=>{
          return !item.val;
        }).length;
        let correctStatus = this.allData[name].optionList.filter((item)=>{
          return item.isCorrect;
        }).length;
        if(!this.allData[name].suspend && !this.allData[name].invalid){
          if(len > 0 || !this.allData[name].title.val || !correctStatus || !this.allData[name].nodes.length || !this.allData[name].categories.length){
            status = true;
            let i = Object.keys(this.allData).indexOf(name);
            this.incompleteData.push(i);
          }
        }
      }
      if(this.incompleteData.length){
        this.verificationModal = true;
        return;
      }
      for(let name in this.allData){
        let len = this.allData[name].optionList.filter((item)=>{
          return !item.val;
        }).length;
        let correctStatus = this.allData[name].optionList.filter((item)=>{
          return item.isCorrect;
        }).length;
        if(!this.allData[name].suspend && !this.allData[name].invalid){
          if(len > 0 || !this.allData[name].title.val || !correctStatus || !this.allData[name].nodes.length || !this.allData[name].categories.length){
            status = true;
            break;
          }
        }
        let options = this.allData[name].optionList.map((item)=>{
          return {
            "id":item.id,
            "name":item.val,
            "is_correct":item.isCorrect
          }
        })
        console.log(this.allData[name].suspend,this.allData[name].invalid)
        if(!this.allData[name].suspend && !this.allData[name].invalid){
          result[name] = {
            info:{
              "type":"question",
              "id":this.$uuid.v1(),
              "name" : this.allData[name].title.val,
              "options" : options,
              "explain":this.allData[name].analysis.val,
              "nodes":this.allData[name].nodes,
              "categories":this.allData[name].categories,
              "labels":this.allData[name].labels,
            }
          }
        }
      }

      // if(status) {
      //   this.$Message.warning(this.$t('task_complete_data'));
      //   return;
      // }
      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'submit',
      };
      if (!reqData.project_id || !reqData.task_id ) {
        return;
      }
      this.loading = true;
      // this.workedTaskId[this.dataId] = true;
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      console.log(this.allData,reqData)

      this.api.task.taskExecute(reqData).then((res)=>{
        this.loading = false;
        this.$Message.destroy();
        this.$Message.success({
          content: this.$t('tool_submit_success'),
          duration: 1,
        });

        //提交后重新领取任务 如果有任务继续做  没有返回上一级
        this.$emit('againGetTask')
      }).catch((err)=>{
        this.loading = false;
      })
    },
    submit () {
      this.saveTaskResult(true, 0);
    },
    exit () {
      this.$Modal.confirm({
        title: this.$t('tool_exit_confirmation'),
        content: this.$t('tool_exit_description', {num: this.taskList.length - this.curDataIndex}),
        loading: true,
        onOk: () => {
          this.clearTask();
        }
      });
    },
    clearTask () {
      EventBus.$emit('needConfirmLeave', false);

      let params = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        data_id:this.$route.query.data_id,
        op: 'clear',
      }
      this.exitLoading = true;
      this.api.task.taskExecute(params).then((res)=>{
        this.exitLoading = false;
        this.$Modal.remove();
        this.$store.commit('removeTag', 'perform-task');


        this.$router.push({
          path: '/production/task',
        });
      }).catch((err)=>{
        this.exitLoading = false;
      })
    },
    changeProjectTab(data){
      this.curProjectTab = data;
    },
    changeData(index){
      if(this.allData[this.taskList[index].data.id].suspend == 1 || this.allData[this.taskList[index].data.id].invalid == 1){
        this.$Message.warning(this.$t('task_disabled_operated'));
        return;
      }
      this.richTxtLoading = true;
      this.$nextTick(()=>{
        this.richTxtLoading = false;
      })
      this.curDataIndex = index;
      this.curId = this.taskList[this.curDataIndex].data.id;


      let nodeIds = this.allData[this.curId].nodes;
      this.getKnowledgePoint(nodeIds,'ids');

      let categoryIds = this.allData[this.curId].categories;
      this.getCategory(categoryIds,'ids');

      let labels = this.allData[this.curId].labels;
      this.getLabel(labels,'ids');
      //暂存
      this.temporaryStorage();
    },
    next(type){

      if(this.curDataIndex == this.taskList.length -1){
        this.$Message.warning(this.$t('task_last_data'));
      }else{
        if(this.allData[this.taskList[this.curDataIndex+1].data.id].suspend == 1 || this.allData[this.taskList[this.curDataIndex+1].data.id].invalid == 1){
          //找一下后面有没有可以跳转的
          let arr = [];
          for(let i=this.curDataIndex+1;i<this.taskList.length;i++){
            if(!this.allData[this.taskList[i].data.id].suspend && !this.allData[this.taskList[i].data.id].invalid){
              arr.push(this.allData[i]);
            }
          }
          console.log(arr,'ddddddd')
          if(arr.length){
            this.curDataIndex += 1;
            this.curId = this.taskList[this.curDataIndex].data.id;
            this.next();
          }else{
            this.$Message.warning(this.$t('task_disabled_operated'));
          }
          return;
        }
        this.richTxtLoading = true;
        this.$nextTick(()=>{
          this.richTxtLoading = false;
        })
        this.curDataIndex += 1;
        this.curId = this.taskList[this.curDataIndex].data.id;

        let nodeIds = this.allData[this.curId].nodes;
        this.getKnowledgePoint(nodeIds,'ids');

        let categoryIds = this.allData[this.curId].categories;
        this.getCategory(categoryIds,'ids');

        let labels = this.allData[this.curId].labels;
        this.getLabel(labels,'ids');
        if(!type){
          //暂存
          this.temporaryStorage();
        }

      }
    },
    pre(){

      if(this.curDataIndex == 0){
        this.$Message.warning(this.$t('task_first_data'));
      }else{
        if(this.allData[this.taskList[this.curDataIndex-1].data.id].suspend == 1 || this.allData[this.taskList[this.curDataIndex-1].data.id].invalid == 1){
          //找一下前面有没有可以跳转的
          let arr = [];
          for(let i=0;i<this.curDataIndex-1;i++){
            if(!this.allData[this.taskList[i].data.id].suspend && !this.allData[this.taskList[i].data.id].invalid){
              arr.push(this.allData[this.taskList[i].data.id]);
            }
          }
          if(arr.length){
            this.curDataIndex -= 1;
            this.curId = this.taskList[this.curDataIndex].data.id;
            this.pre();
          }else{
            this.$Message.warning(this.$t('task_disabled_operated'));
          }
          return;
        }
        this.richTxtLoading = true;
        this.$nextTick(()=>{
          this.richTxtLoading = false;
        })
        this.curDataIndex -= 1;
        this.curId = this.taskList[this.curDataIndex].data.id;

        let nodeIds = this.allData[this.curId].nodes;
        this.getKnowledgePoint(nodeIds,'ids');

        let categoryIds = this.allData[this.curId].categories;
        this.getCategory(categoryIds,'ids');

        let labels = this.allData[this.curId].labels;
        this.getLabel(labels,'ids');
        //暂存
        this.temporaryStorage();
      }
    },
    remoteMethod(query,type){ //模糊搜索
      if(type == 'node'){
        if (query !== '') {
          this.getKnowledgePoint(query);
        } else {
          this.knowledgePointList = [];
        }
      }else if(type == 'category'){
        if (query !== '') {
          this.getCategory(query);
        } else {
          this.categorytList = [];
        }
      }else if(type == 'label'){
        if (query !== '') {
          this.getLabel(query);
        } else {
          this.labelList = [];
        }
      }

    },
    getKnowledgePoint(data,type,fn){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };
      if(type){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.knowledgePointLoading = true;
      this.api.dataset.datasetNodeList(pramas).then((res)=>{
        this.knowledgePointLoading = false;

        this.knowledgePointList = res.list;
        this.$nextTick(()=>{
          fn && fn();
        })

      }).catch((err)=>{
        this.knowledgePointLoading = false;
      })
    },
    getCategory(data,type){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.categoryLoading = true;
      this.api.dataset.categoryList(pramas).then((res)=>{
        this.categoryLoading = false;

        this.categoryList = res.list;
      }).catch((err)=>{
        this.categoryLoading = false;
      })
    },
    getLabel(data,type,fn){
      let pramas = {
        page:this.page,
        pageSize:this.pageSize,
      };

      if(type && data){
        pramas[type] = data.join(',');
      }else{
        pramas.keyword = data;
      }

      this.labelLoading = true;
      this.api.dataset.labelList(pramas).then((res)=>{
        this.labelLoading = false;

        this.labelList = res.list;

        this.$nextTick(()=>{
          fn && fn();
        })
      }).catch((err)=>{
        this.labelLoading = false;
      })
    },
    showTitleRich(){
      this.closeRich();
      this.title.isRich = true;
    },
    showAnalysisRich(){
      this.closeRich();
      this.analysis.isRich = true;
    },
    changeCorrect(data){
      if(this.taskList[this.curDataIndex].dataResult.data.type == 8 || this.taskList[this.curDataIndex].dataResult.data.type == 9){
        return;
      }
      let correctLen = this.allData[this.curId].optionList.filter((item)=>{
        return item.isCorrect;
      }).length;
      if(!data.isCorrect){
        //如果是单选 或者 判断题 只能有一个正确选项  多选不能全选
        if(this.taskList[this.curDataIndex].dataResult.data.type_name == '单选' || this.taskList[this.curDataIndex].dataResult.data.type_name == '判断'){
          if(correctLen >= 1){
            this.$Message.warning(this.$t('task_selected_one'));
            return;
          }
        }
      }
      data.isCorrect = !data.isCorrect;
    },
    saveRichData(curData,data){ //同步富文本数据
      data.val = curData;
    },
    changeNodes(data){
      if(data.length){
        if(!this.knowledgePointList.length){
          //添加
          this.confirmAddNodes(data[data.length - 1]);
        }else{
          //判断是否有id
          let len = this.knowledgePointList.filter((item)=>{
            return item.id == data[data.length - 1];
          })
          if(!len.length){ //添加
            this.confirmAddNodes(data[data.length - 1]);
          }else{
            this.allData[this.curId].nodes = data;
          }
        }
      }else{
        this.allData[this.curId].nodes = data;
      }
    },
    again(){
      this.$emit('againGetTask')
    },
    backTask(){
      this.$router.push({
        path: '/production/task',
      });
    },
    ok(){
      this.curDataIndex = this.incompleteData[0];
      this.curId = this.taskList[this.curDataIndex].data.id;
      this.incompleteData = [];
      this.verificationModal = false;
    },
    cancel(){
      this.incompleteData = [];
      this.verificationModal = false;
    },
    changeCategory(data){ //修改分类
      this.allData[this.curId].categories = data;
    },
    changeLabel(data){ //修改标签
      if(data.length){
        if(!this.labelList.length){
          //添加
          this.confirmAddLabel(data[data.length - 1]);
        }else{
          //判断是否有id
          let len = this.labelList.filter((item)=>{
            return item.id == data[data.length - 1];
          })
          if(!len.length){ //添加
            this.confirmAddLabel(data[data.length - 1]);
          }else{
            this.allData[this.curId].labels = data;
          }
        }
      }else{
        this.allData[this.curId].labels = data;
      }
      this.allData[this.curId].labels = data;
    },
    addNodes(){
      this.addNodesModal = true;
      this.nodeVal = '';
    },
    cancelAddNodes(){ //取消
      this.addNodesModal = false;
    },
    confirmAddNodes(name){  //确定
      let params = {
        name:name
      };
      this.addNodesLoading = true;
      this.api.dataset.nodeCreate(params).then((res)=>{
        this.addNodesLoading = false;


        let len = this.allData[this.curId].nodes.filter((item)=>{
          return item == res.id;
        })
        let nodes;
        if(!len.length){
          nodes = this.allData[this.curId].nodes.concat(res.id + '');
        }
        this.getKnowledgePoint(nodes,'ids',()=>{
          this.allData[this.curId].nodes.splice(this.allData[this.curId].nodes.length - 1, 1, res.id + '');
        });

        this.addNodesModal = false;
      }).catch((err)=>{
        this.addNodesLoading = false;
      })
    },
    addLabel(){
      this.addLabelModal = true;
      this.labelVal = '';
    },
    cancelAddLabel(){ //取消
      this.addLabelModal = false;
    },
    confirmAddLabel(name){  //确定
      let params = {
        name:name
      };
      this.addLabelLoading = true;
      this.api.dataset.labelCreate(params).then((res)=>{
        this.addLabelLoading = false;

        let len = this.allData[this.curId].labels.filter((item)=>{
          return item == res.id;
        });
        let labels;
        if(!len.length){
          labels = this.allData[this.curId].labels.concat(res.id + '');
        }
        this.getLabel(labels,'ids',()=>{
          this.allData[this.curId].labels.splice(this.allData[this.curId].labels.length - 1, 1, res.id + '');
        });

        // this.getLabel(this.allData[this.curId].labels,'ids');

        this.addLabelModal = false;
      }).catch((err)=>{
        this.addLabelLoading = false;
      })
    },
    singleOp(type){  //1挂起 2无效
      console.log(type,'typeeeeeeeee')
      if(type == 1){
        if (this.suspendLoading) {
          return;
        }
      }else{
        if (this.invalidLoading) {
          return;
        }
      }


      let result = {};
      // let status = false; //判断是否有没填的数据
      this.incompleteData = [];
      //
      // let len = this.allData[this.curId].optionList.filter((item)=>{
      //   return !item.val;
      // }).length;
      // let correctStatus = this.allData[this.curId].optionList.filter((item)=>{
      //   return item.isCorrect;
      // }).length;
      // if(len > 0 || !this.allData[this.curId].title.val || !this.allData[this.curId].analysis.val || !correctStatus || !this.allData[this.curId].nodes.length || !this.allData[this.curId].categories.length){
      //   status = true;
      //   let i = Object.keys(this.allData).indexOf(this.curId);
      //   this.incompleteData.push(i);
      // }
      //
      // if(this.incompleteData.length){
      //   this.verificationModal = true;
      //   return;
      // }

      let options = this.allData[this.curId].optionList.map((item)=>{
        return {
          "id":item.id,
          "name":item.val,
          "is_correct":item.isCorrect
        }
      })
      result[this.curId] = {
        info:{
          "type":"question",
          "id":this.$uuid.v1(),
          "name" : this.allData[this.curId].title.val,
          "options" : options,
          "explain":this.allData[this.curId].analysis.val,
          "nodes":this.allData[this.curId].nodes,
          "categories":this.allData[this.curId].categories,
          "labels":this.allData[this.curId].labels,
        }
      }
      if(type == 1){
        result[this.curId].is_difficult = 1;
      }else{
        result[this.curId].is_invalid = 1;
      }
      // if(status) {
      //   this.$Message.warning(this.$t('task_complete_data'));
      //   return;
      // }
      let reqData = {
        project_id: this.$route.query.project_id,
        task_id: this.$route.query.task_id,
        result: JSON.stringify(result),
        op: 'submit',
      };
      if (!reqData.project_id || !reqData.task_id ) {
        return;
      }
      if(type == 1){
        this.suspendLoading = true;
      }else{
        this.invalidLoading = true;
      }

      // this.workedTaskId[this.dataId] = true;
      if(this.$route.query.data_id){
        reqData.data_id = this.$route.query.data_id;
      }

      if(this.$route.query.user_id){
        reqData.user_id = this.$route.query.user_id;
      }

      this.api.task.taskExecute(reqData).then((res)=>{
        let message = '';
        if(type == 1){
          this.suspendLoading = false;
          message = '挂起成功';
          this.$set(this.allData[this.curId],'suspend',1);
          this.next('suspend'); //执行下一步时不需要暂存
        }else{
          this.invalidLoading = false;
          message = '无效作业操作成功';
          this.$set(this.allData[this.curId],'invalid',1);
          this.next('invalid'); //执行下一步时不需要暂存
        }
        this.$Message.destroy();
        this.$Message.success({
          content: this.$t(message),
          duration: 1,
        });

      }).catch((err)=>{
        if(type == 1){
          this.suspendLoading = false;
        }else{
          this.invalidLoading = false;
        }
      })
    }
  },
  destroyed(){
    clearInterval(this.timer);
  }
}
</script>

<style scoped lang="scss">

.tabInfo-enter-active {
  animation: testanimation 1s;
}
.tabInfo-leave-active {
  animation: testanimation 1s reverse;
}
@keyframes testanimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
.exam-main{
  margin: 20px;
  .top{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-r{
      display: flex;
      align-items: center;
    }
  }
  .tip{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .tip-tit{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >p{
        width: 100px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color:#2d8cf0;
        border:1px solid #2d8cf0;
        cursor: pointer;
      }
      >p:not(:last-child){
        border-right:none;
      }
      >p:first-child{
        border-radius: 4px 0 0 4px;
      }
      .last{
        margin-right: 10px;
        border-radius: 0 4px 4px 0;
        border-right:1px solid #2d8cf0 !important;
      }
      .active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
    .tip-container{
      transition: height .5s linear;
      overflow: hidden;
    }
    .tip-cont{
      margin-top: 10px;
      padding: 20px;
      border: 1px solid #dcdee2;
      min-height: 80px;
      border-radius: 4px;
    }
  }
  .cont{
    margin-top: 20px;
    text-align: left;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;

    .cont-tit{
      font-weight: bold;
      font-size: 16px;
    }
    .cont-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cont-num{
      margin-top: 10px;

      >span{
        display: inline-block;
        font-size: 16px;
        color:#ffffff;
        border-radius: 20px;
        margin: 5px 15px 5px 0;
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: #808695;
        cursor: pointer;
      }
      .active{
        background: #2d8cf0;
      }
      .success{
        background: #c5c8ce;
      }
      .invalid{
        background: #ed4014;
      }
    }
    .exam-num{
      margin: 20px 0;
      font-size: 22px;
      font-weight: bold;
      color:#000000;
      >span{
        color: rgb(30, 130, 242);
      }
    }
    .exam-des{
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >div{
        flex: 1;
        >div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;

          >span:nth-child(1){
            padding-right: 10px;
            width: 80px;
            text-align: right;
          }
        }
      }

      .knowledge-point{
        width: 300px;
        border:1px solid #dcdee2;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .no-bor{
          flex: 1;
          border: none;
          min-width: 20px;

          input{
            border:none !important;
            background-color: transparent !important;
          }
        }
        >input:active,>input:hover{
          border:none;
        }
        >input[type=text]:focus{
          outline:none;
        }
      }
    }
    .exam-cont{
      margin-top: 20px;
      .item{
        display: flex;
        justify-content: flex-start;

        .item-l{
          width: 100px;
          text-align: right;

          >p:nth-child(2){
            margin-top: 5px;
            display: inline-block;
            padding: 5px 10px;
            border:1px solid #dcdee2;
            border-radius: 4px;
            cursor: pointer;
          }
          >p.correct{
            border:1px solid #19be6b;
            background-color: #19be6b;
            color:#FFFFFF;
          }
        }
        .item-r{
          margin-left: 20px;
          flex: 1;

          .item-r-input{
            min-height: 80px;

            //border:1px solid #dcdee2;
            border-radius: 4px;
            position: relative;

            .item-r-input-cont{
              display: flex;
              justify-content: flex-start;
              min-height: 80px;

              .left{
                width: 70%;
                //padding: 10px;
              }
              .right{
                flex: 1;

              }
              .right{
                border:1px solid #dcdee2;
                border-left: none;

                .tit{
                  padding-left: 20px;
                  height: 39px;
                  line-height: 43px;
                  font-size: 14px;
                  border-bottom: 1px solid #dcdee2;
                }
                .comment{
                  padding: 10px;
                  height: 234px;
                  overflow-y: auto;
                }
              }
            }
            .btn{
              display: flex;
              flex-flow: column;
              position: absolute;
              right: 10px;
              top: 10px;
            }


          }
          .item-r-input.correct{
            border:2px solid #2d8cf0;
          }
          .item-r-comment{
            margin-top: 10px;
            margin-bottom: 10px;
            color:red;
            line-height: 22px;
          }

        }

      }
      .add-btn{
        margin-bottom: 20px;
      }
    }
    .bot{
      margin-top: 40px;
      margin-bottom: 40px;
      padding-top: 20px;
      border-top: 1px solid #dcdee2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .bot-btn{
        margin-right: 20px;
      }
    }
  }

}
.mr{
  margin-right: 10px;
}
</style>
<style lang="scss">
.no-bor{
  flex: 1;
  border: none;
  min-width: 20px;

  input,
  input:focus,
  input:active,
  input:hover{
    border:none !important;
    background-color: transparent !important;
    box-shadow: none;
  }
}
.comment{
  .ivu-input{
    height: 235px !important;
    width: 100% !important;
    border:none !important;
  }
}
</style>
